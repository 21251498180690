import React from "react";
import { useContext, useState, useEffect } from "react";
import { Box, Table, Thead, Tbody, Tr, Th, Td, TableContainer, Spacer, Flex, Button, ButtonGroup, Heading, Container, Center } from '@chakra-ui/react';
import { ChevronLeftIcon, HamburgerIcon } from "@chakra-ui/icons";
import { CheckSquareIcon } from "../../icons/checksquareicon";
import { useNavigate } from "react-router-dom";

import NavSuperior from "../../components/NavBar/navsuperior";
import DadosRemessa from "../../components/Remessa/dadosremessa";

import { AuthContext } from "../../contexts/auth";
import { ReurbContext } from "../../contexts/reurb";

import { api } from "../../../services/api";


const RequerentesPage = () => {
	const navigate = useNavigate();

	//Variáveis de Contexto
	const { logout } = useContext(AuthContext);
	const { rem_id, setUnid_id, setReq_id } = useContext(ReurbContext);

	//Variáveis
	const [qtd_requerentes, setQtd_requerentes] = useState(0);
	const [lista_requerentes, setLista_requerentes] = useState([{ req_id: 0, unid_id: 0, unid_cod: '', req_nome: '', conj_nome: '', req_estadocivil: '', req_tipo: '', reurb_situacao: '' }]);

	//Ler dados
	useEffect(() => {
		api.post('/requerente/LerListaRequerentes.php', JSON.stringify({ rem_id }))
			.then(function (rp) {
				setQtd_requerentes(rp.data.qtd_requerentes);
				setLista_requerentes(rp.data.lista_requerentes);
			})
			.catch(function (error) {
				if (error.response.data === "EXPIRED") {
					logout();
				} else {
					console.log(error);
				}
			});
	}, []);

	//Ir para remessa
	function goToRemessa() {
		navigate('/remessa');
	}

	//Ir para processos
	function goToProcessos() {
		navigate('/processos');
	}

	//Ir para núcleos
	function goToNucleos() {
		navigate('/nucleos');
	}

	//Ir para documental
	function goToDocumental() {
		navigate('/documental');
	}

	//Ir para Requerente
	function irParaRequerente(id_requerente, id_unidade) {
		setReq_id(id_requerente);
		setUnid_id(id_unidade);
		if (id_requerente !== 0 && id_unidade !== 0) {
			navigate('/requerente');
		}
	}

	return (
		<div id="oficios">
			<NavSuperior />
			<DadosRemessa titulo="Lista de Requerentes" />
			<Container maxW='95%' bg='white'>
				<Flex direction='row' w={'100%'} h={'60px'} bg={'gray.200'}>
					<Center w={'100px'} h={'100%'} color={'gray.600'} cursor='pointer' _hover={{ color: 'teal' }} onClick={e => goToRemessa()}>
						<ChevronLeftIcon boxSize={12} />
					</Center>
					<Center>
						<Heading size={'md'} color={'gray.700'} ml={3}>Requerentes Cadastrados ({qtd_requerentes})</Heading>
					</Center>
					<Spacer />
					<Center>
						<ButtonGroup gap='4' mr={4}>
							<Button colorScheme="teal" variant='outline' onClick={e => goToProcessos()}>Processos<HamburgerIcon ml={2} /></Button>
							<Button colorScheme="teal" variant='outline' onClick={e => goToNucleos()}>Núcleos<HamburgerIcon ml={2} /></Button>
							<Button colorScheme="teal" variant='outline' onClick={e => goToDocumental()}>Documental<CheckSquareIcon ml={2} /></Button>
						</ButtonGroup>
					</Center>
				</Flex>
				<Box overflowX="auto"
					whiteSpace="nowrap"
					sx={{
						'&::-webkit-scrollbar': {
							width: '16px',
							borderRadius: '8px',
							backgroundColor: `rgba(0, 0, 0, 0.05)`,
						},
						'&::-webkit-scrollbar-thumb': {
							backgroundColor: `rgba(0, 0, 0, 0.05)`,
						},
					}}
					w='100%'
					height={(window.innerHeight - 260)}>
					<TableContainer mt={5} mb={20}>
						<Table size='sm'>
							<Thead>
								<Tr>
									<Th textAlign={'center'}>CÓDIGO</Th>
									<Th textAlign={'center'}>REQUERENTE</Th>
									<Th textAlign={'center'}>CÔNJUGE</Th>
									<Th textAlign={'center'}>ESTADO CIVIL</Th>
									<Th textAlign={'center'}>TIPO</Th>
									<Th textAlign={'center'}>SITUAÇÃO</Th>
								</Tr>
							</Thead>
							<Tbody>
								{lista_requerentes.map((item, i) => {
									return (
										<Tr key={i} cursor='pointer' onClick={e => irParaRequerente(item.req_id, item.unid_id)} _hover={{ bg: 'gray.200' }} bg={item.reurb_situacao === 'Cancelado' ? 'red.100' : 'white'}>
											<Td textAlign={'center'}>{item.unid_cod}</Td>
											<Td whiteSpace={'pre-wrap'} maxW={'250px'}>{item.req_nome}</Td>
											<Td whiteSpace={'pre-wrap'} maxW={'250px'}>{item.conj_nome}</Td>
											<Td textAlign={'center'}>{item.req_estadocivil}</Td>
											<Td textAlign={'center'}>{item.req_tipo}</Td>
											<Td textAlign={'center'}>{item.reurb_situacao}</Td>
										</Tr>
									);
								})}
							</Tbody>
						</Table>
					</TableContainer>
				</Box>
			</Container>
		</div>
	);
}

export default RequerentesPage;