import { useContext, useState } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";

import LoginPage from "./frontend/pages/LoginPage";
import UsuariosPage from "./frontend/pages/UsuariosPage/usuarios";
import PerfilPage from "./frontend/pages/UsuariosPage/perfil";
import HomePage from "./frontend/pages/HomePage";
import MetasPage from "./frontend/pages/MetasPage/metas";
import OficiosPage from "./frontend/pages/OficiosPage/oficios";
import ContatoPage from "./frontend/pages/ContatoPage/contato";
import ReurbPage from "./frontend/pages/ReurbPage/reurb";
import RemessaPage from "./frontend/pages/RemessaPage/remessa";
import ConfiguracoesPage from "./frontend/pages/ConfiguracoesPage/configuracoes";
import ImportarPage from "./frontend/pages/ImportarPage/importar";
import DocumentalPage from "./frontend/pages/DocumentalPage/documental";
import TopografiaPage from "./frontend/pages/TopografiaPage/topografia";
import QualificacaoPage from "./frontend/pages/QualificacaoPage/qualificacao";
import FinanceiroPage from "./frontend/pages/FinanceiroPage/financeiro";
import ModalidadePage from "./frontend/pages/ModalidadePage/modalidade";
import GerarPDF from "./frontend/pages/GerarPdfPage/gerarpdf";
import NucleosPage from "./frontend/pages/NucleoPage/nucleos";
import NucleoPage from "./frontend/pages/NucleoPage/nucleo";
import ProcessosPage from "./frontend/pages/ProcessoPage/processos";
import ProcessoPage from "./frontend/pages/ProcessoPage/processo";
import RequerentesPage from "./frontend/pages/RequerentePage/requerentes";
import RequerentePage from "./frontend/pages/RequerentePage/requerente";
import OcupantePage from "./frontend/pages/OcupantePage/ocupante";
import PesquisarPage from "./frontend/pages/PesquisarPage/pesquisar";

import { AuthProvider, AuthContext } from "./frontend/contexts/auth";

const AppRoutes = () => {
    const Private = ({ children }) => {
        const { authenticated, loading } = useContext(AuthContext);

        if (loading) {
            return <div className="loading"> Carregando...</div>
        }

        if (!authenticated) {
            return <Navigate to="/login" />;
        }

        return children;
    }

    return (
        <Router>
            <AuthProvider>
                <Routes>
                    <Route exact path="/login" element={<LoginPage />} />
                    <Route exact path="/usuarios" element={<Private><UsuariosPage /></Private>} />
                    <Route exact path="/perfil" element={<Private><PerfilPage /></Private>} />
                    <Route exact path="/" element={<Private><HomePage /></Private>} />
                    <Route exact path="/metas" element={<Private><MetasPage /></Private>} />
                    <Route exact path="/reurb" element={<Private><ReurbPage /></Private>} />
                    <Route exact path="/oficios" element={<Private><OficiosPage /></Private>} />
                    <Route exact path="/contato" element={<Private><ContatoPage /></Private>} />
                    <Route exact path="/remessa" element={<Private><RemessaPage /></Private>} />
                    <Route exact path="/configuracoes" element={<Private><ConfiguracoesPage /></Private>} />
                    <Route exact path="/importar" element={<Private><ImportarPage /></Private>} />
                    <Route exact path="/documental" element={<Private><DocumentalPage /></Private>} />
                    <Route exact path="/topografia" element={<Private><TopografiaPage /></Private>} />
                    <Route exact path="/qualificacao" element={<Private><QualificacaoPage /></Private>} />
                    <Route exact path="/financeiro" element={<Private><FinanceiroPage /></Private>} />
                    <Route exact path="/modalidade" element={<Private><ModalidadePage /></Private>} />
                    <Route exact path="/gerarpdf" element={<Private><GerarPDF /></Private>} />
                    <Route exact path="/nucleos" element={<Private><NucleosPage /></Private>} />
                    <Route exact path="/nucleo" element={<Private><NucleoPage /></Private>} />
                    <Route exact path="/processos" element={<Private><ProcessosPage /></Private>} />
                    <Route exact path="/processo" element={<Private><ProcessoPage /></Private>} />
                    <Route exact path="/requerentes" element={<Private><RequerentesPage /></Private>} />
                    <Route exact path="/requerente" element={<Private><RequerentePage /></Private>} />
                    <Route exact path="/ocupante" element={<Private><OcupantePage /></Private>} />
                    <Route exact path="/pesquisar" element={<Private><PesquisarPage /></Private>} />
                </Routes>
            </AuthProvider>
        </Router>
    )
}

export default AppRoutes;