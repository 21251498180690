import React from "react";
import axios from "axios";
import { useContext, useState, useEffect } from "react";
import { Box, Table, Thead, Tbody, Tr, Th, Td, TableContainer, Tabs, TabList, Tab, Button, Flex, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Stack, Text, useDisclosure, Select, Container, Heading, Spacer, ButtonGroup, Center, Divider } from '@chakra-ui/react';
import { useNavigate } from "react-router-dom";
import { ChevronLeftIcon, PlusSquareIcon } from "@chakra-ui/icons";
import NavSuperior from "../../components/NavBar/navsuperior";

import { api } from "../../../services/api";
import { AuthContext } from "../../contexts/auth";
import { ReurbContext } from "../../contexts/reurb";

const ReurbPage = () => {
	const navigate = useNavigate();

	//Variáveis do Contexto
	const { logout } = useContext(AuthContext);
	const { rem_uf, setRem_uf, setRem_id, setRem_municipio, setRem_remessa, setUnid_id, setReq_id, setOcup_id, setNui_id } = useContext(ReurbContext);

	//Variáveis Chakra UI
	const { isOpen: isRemessaOpen, onOpen: onRemessaOpen, onClose: onRemessaClose } = useDisclosure();

	//Variáveis
	const [qtd_remessas, setQtd_remessas] = useState(0);

	const lista_unidades = ['AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MT', 'MS', 'MG', 'PA', 'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO'];

	const [uf, setUf] = useState(rem_uf === undefined ? "SC" : rem_uf);
	const [lista_ufs, setLista_ufs] = useState([{ index: 0, uf: '' }]);
	const [lista_municipios, setLista_municipios] = useState([]);
	const [indice, setIndice] = useState(0);
	const [lista_remessas, setLista_remessas] = useState([{ rem_id: 0, rem_municipio: '-', rem_remessa: '-', rem_titulo: '-', total: '-', ativos: '-', cancelados: '-', pendentes: '-', rem_fase: '-', rem_criado: '-' }]);

	const [uf_selecionado, setUf_selecionado] = useState(uf);
	const [municipio_selecionado, setMunicipio_selecionado] = useState('');

	const [controle, setControle] = useState(false);

	//Ler dados
	useEffect(() => {

		//Limpar dados
		setRem_id(0);
		setRem_municipio("");
		setRem_remessa(0);
		setUnid_id(0);
		setReq_id(0);
		setOcup_id(0);
		setNui_id(0);

		//Lista de UFs
		api.post('/reurb/LerListaUfs.php', JSON.stringify({ uf }))
			.then(function (rp) {

				setLista_ufs(rp.data.lista_ufs);
				setIndice(rp.data.indice);

			})
			.catch(function (error) {
				if (error.response.data === "EXPIRED") {
					logout();
				}
			});

		//Lista de Remessas
		api.post('/reurb/LerListaRemessas.php', JSON.stringify({ uf }))
			.then(function (rp) {

				setQtd_remessas(rp.data.qtd_remessas);
				setLista_remessas(rp.data.lista_remessas);

			})
			.catch(function (error) {
				if (error.response.data === "EXPIRED") {
					logout();
				}
			});
	}, [controle]);

	//Carregar nova lista UF com remessa existente
	function carregarListaUF(novo_uf) {
		//Lista de Remessas
		api.post('/reurb/LerListaRemessas.php', JSON.stringify({ uf: novo_uf }))
			.then(function (rp) {

				setLista_remessas(rp.data.lista_remessas);

			})
			.catch(function (error) {
				if (error.response.data === "EXPIRED") {
					logout();
				}
			});
	}

	//Ir para remessa
	function irParaRemessa(id, nome_municipio, numero_remesso) {
		setRem_id(id);
		setRem_municipio(nome_municipio);
		setRem_remessa(numero_remesso);
		if (id !== 0) {
			navigate('/remessa');
		}
	}

	//Ir para home
	function goToHome() {
		navigate('/');
	}

	//Adicionar nova remessa
	async function adicionarRemessa(uf, municipio) {
		if (uf !== "" && municipio !== "") {
			await api.post('/reurb/NovaRemessa.php', JSON.stringify({ uf, municipio }))
				.catch(function (error) {
					if (error.response.data === "EXPIRED") {
						logout();
					}
				});

			//Recarregar lista de matrículas
			setControle(!controle);

			//Fechar modal
			onRemessaClose();
		} else {
			if (uf !== "" && municipio !== "") {
				alert("UF e Município não definidos!")
			} else if (uf !== "") {
				alert("UF não definido!")
			} else {
				alert("Município não definido!")
			}
		}
	}

	//Pegar nome dos municípios
	async function municipiosIBGE(valor_uf) {
		let url = 'https://servicodados.ibge.gov.br/api/v1/localidades/estados/' + valor_uf + '/municipios';
		const lista = [];
		await axios.get(url).then(rp => {
			rp.data.map((item) => {
				lista.push(item.nome);
			});
		});
		setLista_municipios(lista);
	}

	return (
		<div id="reurb">
			<NavSuperior />
			<Divider borderColor={'gray.400'} orientation='horizontal' mt={2} mb={2} />
			<Heading as='h1' size='lg' color={'gray.600'} textAlign={'center'}>
				REMESSAS
			</Heading>
			<Divider borderColor={'gray.400'} orientation='horizontal' mt={2} mb={2} />
			<Container maxW={'95%'} bg={'white'}>
				<Flex direction='row' w={'100%'} h={'60px'} bg={'gray.200'}>
					<Center w={'100px'} h={'100%'} color={'gray.600'} cursor='pointer' _hover={{ color: 'teal' }} onClick={e => goToHome()}>
						<ChevronLeftIcon boxSize={12} />
					</Center>
					<Center>
						<Heading size={'md'} color={'gray.700'} ml={3}>Remessas Cadastradas ({qtd_remessas})</Heading>
					</Center>
					<Spacer />
					<Center>
						<ButtonGroup gap='4' mr={4}>
							<Button colorScheme="teal" variant='outline' onClick={e => { municipiosIBGE(uf_selecionado); onRemessaOpen(); }}>Nova Remessa<PlusSquareIcon ml={2} /></Button>
						</ButtonGroup>
					</Center>
				</Flex>

				<Box display={parseInt(qtd_remessas) === 0 ? 'none' : 'block'}>
					<Tabs colorScheme='teal' index={indice} align='center'>
						<TabList>
							{lista_ufs.map((item, i) => {
								return (
									<Tab w={"70px"} key={i} onClick={() => { setIndice(i); setRem_uf(item.uf); setUf(item.uf); carregarListaUF(item.uf); setUf_selecionado(item.uf); }}>{item.uf}</Tab>
								);
							})}
						</TabList>
					</Tabs>

					<Box overflowX="auto"
						whiteSpace="nowrap"
						sx={{
							'&::-webkit-scrollbar': {
								width: '16px',
								borderRadius: '8px',
								backgroundColor: `rgba(0, 0, 0, 0.05)`,
							},
							'&::-webkit-scrollbar-thumb': {
								backgroundColor: `rgba(0, 0, 0, 0.05)`,
							},
						}}
						w='100%'
						height={(window.innerHeight - 280)}>
						<TableContainer m={5}>
							<Table size='sm'>
								<Thead>
									<Tr>
										<Th textAlign={'center'} w={'5%'}>N.</Th>
										<Th textAlign={'center'} w={'15%'}>REMESSA</Th>
										<Th textAlign={'center'} w={'30%'}>TÍTULO</Th>
										<Th textAlign={'center'} w={'5%'}>TOTAL</Th>
										<Th textAlign={'center'} w={'5%'}>ATIVOS</Th>
										<Th textAlign={'center'} w={'5%'}>CANCELADOS</Th>
										<Th textAlign={'center'} w={'5%'}>PENDENTES</Th>
										<Th textAlign={'center'} w={'20%'}>FASE</Th>
										<Th textAlign={'center'} w={'10%'}>DATA DE CRIAÇÃO</Th>
									</Tr>
								</Thead>
								<Tbody>
									{lista_remessas.map((item, i) => {
										return (
											<Tr key={i} cursor='pointer' _hover={{ bg: 'gray.200' }} onClick={e => irParaRemessa(item.rem_id, item.rem_municipio, item.rem_remessa)} >
												<Td textAlign={'center'}>{i + 1}</Td>
												<Td>{item.rem_municipio} {item.rem_remessa}</Td>
												<Td>{item.rem_titulo}</Td>
												<Td textAlign={'center'}>{item.total}</Td>
												<Td textAlign={'center'}>{item.ativos}</Td>
												<Td textAlign={'center'}>{item.cancelados}</Td>
												<Td textAlign={'center'}>{item.pendentes}</Td>
												<Td textAlign={'center'}>{item.rem_fase}</Td>
												<Td textAlign={'center'}>{item.rem_criado}</Td>
											</Tr>
										);
									})}
								</Tbody>
							</Table>
						</TableContainer>
					</Box>
				</Box>
				<Center display={parseInt(qtd_remessas) === 0 ? 'block' : 'none'} mt={20}>
					<Heading size={'lg'} textAlign={'center'} color={'gray.400'}>Não há remessas cadastradas!</Heading>
				</Center>
			</Container>

			{/*MODAL NOVA REMESSA*/}
			<Modal isOpen={isRemessaOpen} onClose={onRemessaClose} isCentered size='2xl'>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader textAlign='center'>NOVA REMESSA</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Flex justifyContent='center'>
							<Stack direction='row' spacing={5}>
								<Text whiteSpace={'nowrap'}>UF</Text>
								<Select size='sm' minW='100px' value={uf_selecionado} onChange={e => { setUf_selecionado(e.target.value); municipiosIBGE(e.target.value); }}>
									{lista_unidades.map((item, i) => {
										return (<option key={i} value={item}>{item}</option>);
									})}
								</Select>
								<Text whiteSpace={'nowrap'}>Município</Text>
								<Select size='sm' minW='300px' value={municipio_selecionado} onChange={e => setMunicipio_selecionado(e.target.value)}>
									<option key={0} value={''}>{''}</option>
									{lista_municipios.map((item, i) => {
										return (<option key={i} value={item}>{item}</option>);
									})}
								</Select>
							</Stack>
						</Flex>
					</ModalBody>
					<ModalFooter>
						<Button colorScheme='gray' onClick={e => adicionarRemessa(uf_selecionado, municipio_selecionado)}>Concluir</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>

		</div>
	);
}

export default ReurbPage;