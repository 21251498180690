import React from "react";
import { useContext } from "react";
import { Divider, Heading, Grid, GridItem, Button } from '@chakra-ui/react';
import { useNavigate } from "react-router-dom";

import { ReurbContext } from "../../contexts/reurb";

const DadosRemessa = (props) => {
    const { rem_municipio, rem_remessa } = useContext(ReurbContext);
    const navigate = useNavigate();

    //Ir para lista de processos
    function goToRemessa() {
        navigate('/remessa');
    }

    //Texto remessa
    function textoRemessa() {
        if(rem_municipio!==""){
            return(rem_municipio.toUpperCase() + " " + rem_remessa);
        }else{
            return("REMESSAS");
        }
    }

    return (
        <div id="dados-remessa">
            <Divider borderColor={'gray.400'} orientation='horizontal' mt={2} mb={2} />
            <Grid templateColumns='repeat(2, 1fr)' gap={6}>
                <GridItem w='100%' textAlign={'right'}>
                    <Button colorScheme='none' onClick={e => goToRemessa()}>
                        <Heading size='lg' color={'gray.600'}>
                            {textoRemessa()}
                        </Heading>
                    </Button>
                </GridItem>
                <GridItem w='100%' textAlign={'left'}>
                    <Heading size='lg' color={'gray.500'}>
                        {props.titulo}
                    </Heading>
                </GridItem>
            </Grid>
            <Divider borderColor={'gray.400'} orientation='horizontal' mt={2} mb={2} />
        </div>
    );
}

export default DadosRemessa;