import React from "react";
import { useContext, useState, useEffect } from "react";
import { Container, Box, TableContainer, Table, Thead, Tr, Th, Td, Tbody, Button, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, Text, ModalFooter, Flex, Heading, Spacer, Select, Center } from '@chakra-ui/react';
import { useNavigate } from "react-router-dom";
import { DownloadIcon, ChevronLeftIcon } from "@chakra-ui/icons";

import NavSuperior from "../../components/NavBar/navsuperior";
import DadosRemessa from "../../components/Remessa/dadosremessa";

import { AuthContext } from "../../contexts/auth";
import { ReurbContext } from "../../contexts/reurb";

import ContratoPDF from "../ProcessoPage/contratopdf";
import ProcuracaoPDF from "../ProcessoPage/procuracaopdf";
import RequerimentoPDF from "../ProcessoPage/requerimentopdf";

import { api } from "../../../services/api";

const GerarPDF = () => {

	//Variáveis de Contexto
	const { logout } = useContext(AuthContext);
	const { rem_id, setUnid_id } = useContext(ReurbContext);

	//Navegação
	const navigate = useNavigate();

	//Variáveis
	const [lista_processos, setLista_processos] = useState([{ unid_id: 0, unid_cod: '-', beneficiarios: '', reurb_situacao: '' }]);
	const [lista_nucleo, setLista_nucleo] = useState([{ nui_id: 0, nui_cod: '-', nui_nome: '-' }]);
	const [lista_situacao, setLista_situacao] = useState([{ sit_id: 0, situacao: '-' }]);
	const { isOpen: isMensagemOpen, onOpen: onMensagemOpen, onClose: onMensagemClose } = useDisclosure();

	//Variável imprimir
	const [msg_imprimir, setMsg_imprimir] = useState("");

	//Filtros
	const [nucleo_selecionado, setNucleo_selecionado] = useState(0);
	const [situacao_selecionada, setSituacao_selecionada] = useState(0);

	//Ler dados
	useEffect(() => {
		const data = {
			rem_id,
			nucleo_selecionado,
			situacao_selecionada
		}
		//Lista processos
		api.post('/gerarpdf/LerGerarPDF.php', JSON.stringify(data))
			.then(function (rp) {
				setLista_processos(rp.data.lista_processos);
				setLista_nucleo(rp.data.lista_nucleo);
				setLista_situacao(rp.data.lista_situacao);
			})
			.catch(function (error) {
				if (error.response.data === "EXPIRED") {
					logout();
				} else {
					console.log(error);
				}
			});
	}, [nucleo_selecionado, situacao_selecionada]);

	//Abrir mensagem imprimir
	useEffect(() => {
		//Exibir mensagem quando mudar
		if (msg_imprimir !== '') {
			onMensagemOpen();
		}
	}, [msg_imprimir]);

	//Ir para remessa
	function goToRemessa() {
		navigate('/remessa');
	}

	return (
		<div id="gerarpdf">
			<NavSuperior />
			<DadosRemessa titulo="Gerar PDF" />
			<Container maxW='95%' bg='white'>
				<Flex direction='row' w={'100%'} h={'60px'} bg={'gray.200'}>
					<Center w={'100px'} h={'100%'} color={'gray.600'} cursor='pointer' _hover={{ color: 'teal' }} onClick={e => goToRemessa()}>
						<ChevronLeftIcon boxSize={12} />
					</Center>
					<Center>
						<Heading size={'md'} color={'gray.700'} ml={3}>Lista de Processos</Heading>
					</Center>
					<Spacer />
					<Center>
						<Text mr={3}>Núcleo</Text>
						<Select mr={4} borderColor={'teal'} size='sm' w='200px' value={nucleo_selecionado} onChange={e => { setNucleo_selecionado(e.target.value) }}>
							<option key={0} value={0}>{'-'}</option>
							{lista_nucleo.map((item, i) => {
								return (<option key={i + 1} value={item.nui_id}>{item.nui_cod}</option>);
							})}
						</Select>
						<Text mr={3}>Situação</Text>
						<Select mr={4} borderColor={'teal'} size='sm' w='200px' value={situacao_selecionada} onChange={e => { setSituacao_selecionada(e.target.value) }}>
							<option key={0} value={0}>{'-'}</option>
							{lista_situacao.map((item, i) => {
								return (<option key={i + 1} value={item.sit_id}>{item.situacao}</option>);
							})}
						</Select>
					</Center>					
				</Flex>
				<Box overflowX="auto"
					whiteSpace="nowrap"
					sx={{
						'&::-webkit-scrollbar': {
							width: '16px',
							borderRadius: '8px',
							backgroundColor: `rgba(0, 0, 0, 0.05)`,
						},
						'&::-webkit-scrollbar-thumb': {
							backgroundColor: `rgba(0, 0, 0, 0.05)`,
						},
					}}
					w='100%'
					height={(window.innerHeight - 260)}>
					<TableContainer mt={5}>
						<Table size='sm'>
							<Thead>
								<Tr>
									<Th textAlign={'center'}>CÓDIGO</Th>
									<Th textAlign={'center'}>BENEFICIÁRIOS</Th>
									<Th textAlign={'center'}>CONTRATO</Th>
									<Th textAlign={'center'}>PROCURAÇÃO</Th>
									<Th textAlign={'center'}>REQUERIMENTO</Th>
								</Tr>
							</Thead>
							<Tbody>
								{lista_processos.map((item, i) => {
									return (
										<Tr key={i} bg={item.reurb_situacao === 'Cancelado' ? 'red.100' : 'white'}>
											<Td textAlign={'center'} minW={'150px'}>{item.unid_cod}</Td>
											<Td textAlign={'left'} minW={'300px'}>{item.beneficiarios}</Td>
											<Td textAlign={'center'}>
												<Button display={item.unid_id === 0 ? 'none' : 'inline'} w={'180px'} colorScheme={'blackAlpha'} variant={'outline'} onClick={e => ContratoPDF(item.unid_id, setMsg_imprimir, 'download')}>Contrato <DownloadIcon ml={2} /></Button>
												<Text display={item.unid_id === 0 ? 'inline' : 'none'}>-</Text>
											</Td>
											<Td textAlign={'center'}>
												<Button display={item.unid_id === 0 ? 'none' : 'inline'} w={'180px'} colorScheme={'blackAlpha'} variant={'outline'} onClick={e => ProcuracaoPDF(item.unid_id, setMsg_imprimir, 'download')}>Procuração <DownloadIcon ml={2} /></Button>
												<Text display={item.unid_id === 0 ? 'inline' : 'none'}>-</Text>
											</Td>
											<Td textAlign={'center'}>
												<Button display={item.unid_id === 0 ? 'none' : 'inline'} w={'180px'} colorScheme={'blackAlpha'} variant={'outline'} onClick={e => RequerimentoPDF(item.unid_id, setMsg_imprimir, 'download')}>Requerimento <DownloadIcon ml={2} /></Button>
												<Text display={item.unid_id === 0 ? 'inline' : 'none'}>-</Text>
											</Td>
										</Tr>
									);
								})}
							</Tbody>
						</Table>
					</TableContainer>
				</Box>
			</Container>

			{/*ALERT MENSAGEM IMPRIMIR*/}
			<Modal closeOnEsc={false} closeOnOverlayClick={false} isOpen={isMensagemOpen} onClose={onMensagemClose} isCentered size='2xl'>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Dados Faltantes</ModalHeader>
					<ModalBody>
						<Text>{msg_imprimir}</Text>
					</ModalBody>
					<ModalFooter>
						<Button onClick={e => { setMsg_imprimir(''); onMensagemClose(); }}>
							OK
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</div>
	);
}

export default GerarPDF;