import React, { useRef } from "react";
import { useEffect, useState, useContext } from 'react';
import { useToast, Text, Table, Thead, Tbody, Tr, Th, Td, TableContainer, Button, Select, Flex, Spacer, Container, ButtonGroup, Tabs, TabList, Tab, Heading, TabPanel, TabPanels, Box, Center } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import ExportExcel from "../../components/Excel/excelexport";
import { ChevronLeftIcon, CheckIcon, CloseIcon } from "@chakra-ui/icons";

import NavSuperior from "../../components/NavBar/navsuperior";
import DadosRemessa from "../../components/Remessa/dadosremessa";

import { AuthContext } from "../../contexts/auth";
import { ReurbContext } from "../../contexts/reurb";

import { api } from "../../../services/api";

const DocumentalPage = () => {
	const { logout } = useContext(AuthContext);

	//Contexto rem_id
	const { rem_id, setReq_id, rem_municipio, rem_remessa } = useContext(ReurbContext);

	//Navegação
	const navigate = useNavigate();

	//Variáveis Chakra UI
	const toast = useToast();

	//Variáveis
	const [lista_requerentes, setLista_requerentes] = useState([{ req_id: 0, codigo: '-', requerente: '-', contato: '-', estado_civil: '-', tipo: '-', contrato: -1, procuracao: -1, requerimento: -1, distrato: -1, documento_faltante: '-', informacao_faltante: '-', doc_observacao: '-', situacao: '-' }]);
	const [excel_documental, setExcel_documental] = useState([{ CodigoProcesso: '-', Requerente: '-', Contato: '-', EstadoCivil: '-', Tipo: '-', Contrato: '-', Procuracao: '-', Requerimento: '-', Distrato: '-', DocumentoFaltante: '-', InformacaoFaltante: '-', Observacao: '-', Situacao: '-' }]);
	const [lista_documento, setLista_documento] = useState([{ doc_id: 0, documento: '-' }]);
	const [lista_informacao, setLista_informacao] = useState([{ inf_id: 0, informacao: '-' }]);
	const [lista_situacao, setLista_situacao] = useState([{ sit_id: 0, situacao: '-' }]);

	//Filtros
	const [documento_selecionado, setDocumento_selecionado] = useState(0);
	const [informacao_selecionada, setInformacao_selecionada] = useState(0);
	const [situacao_selecionada, setSituacao_selecionada] = useState(0);

	//Ler dados
	useEffect(() => {
		const data = {
			rem_id,
			documento_selecionado,
			informacao_selecionada,
			situacao_selecionada
		}
		api.post('/documental/LerDocumental.php', JSON.stringify(data))
			.then(function (rp) {
				setLista_requerentes(rp.data.lista_requerentes);
				setExcel_documental(rp.data.excel_documental);
				setLista_documento(rp.data.lista_documento);
				setLista_informacao(rp.data.lista_informacao);
				setLista_situacao(rp.data.lista_situacao);
			})
			.catch(function (error) {
				if (error.response.data === "EXPIRED") {
					logout();
				} else {
					console.log(error);
				}
			});
	}, [documento_selecionado, informacao_selecionada, situacao_selecionada]);

	//Ir para remessa
	function goToRemessa() {
		navigate('/remessa');
	}

	//Ir para requerente
	function irParaRequerente(id) {
		if (id !== 0) {
			setReq_id(id);
			navigate('/Requerente', { replace: true });
		}
	}

	return (
		<div id="documental">
			<NavSuperior />
			<DadosRemessa titulo="Análise Documental" />
			<Container maxW='95%' bg='white'>

				<Flex direction='row' w={'100%'} h={'60px'} bg={'gray.200'}>
					<Center w={'100px'} h={'100%'} color={'gray.600'} cursor='pointer' _hover={{ color: 'teal' }} onClick={e => goToRemessa()}>
						<ChevronLeftIcon boxSize={12} />
					</Center>
					<Spacer />
					<Center>
						<Text mr={3}>Documento</Text>
						<Select mr={4} borderColor={'teal'} size='sm' w='150px' value={documento_selecionado} onChange={e => { setDocumento_selecionado(e.target.value) }}>
							<option key={0} value={0}>{'-'}</option>
							{lista_documento.map((item, i) => {
								return (<option key={i + 1} value={item.doc_id}>{item.documento}</option>);
							})}
						</Select>
						<Text mr={3}>Informação</Text>
						<Select mr={4} borderColor={'teal'} size='sm' w='150px' value={informacao_selecionada} onChange={e => { setInformacao_selecionada(e.target.value) }}>
							<option key={0} value={0}>{'-'}</option>
							{lista_informacao.map((item, i) => {
								return (<option key={i + 1} value={item.inf_id}>{item.informacao}</option>);
							})}
						</Select>
						<Text mr={3}>Situação</Text>
						<Select mr={4} borderColor={'teal'} size='sm' w='150px' value={situacao_selecionada} onChange={e => { setSituacao_selecionada(e.target.value) }}>
							<option key={0} value={0}>{'-'}</option>
							{lista_situacao.map((item, i) => {
								return (<option key={i + 1} value={item.sit_id}>{item.situacao}</option>);
							})}
						</Select>
					</Center>
					<Center w={'130px'}>
						<ExportExcel excelData={excel_documental} fileName={"Dados Documental"} />
					</Center>
				</Flex>
				<Tabs isFitted>
					<TabList color={'gray.600'} backgroundColor={'gray.200'} h={'40px'}>
						<Tab className="docs_internos" _selected={{ bg: 'gray.300' }}><Heading size='sm'>DOCUMENTOS INTERNOS</Heading></Tab>
						<Tab className="docs_requerentes" _selected={{ bg: 'gray.300' }}><Heading size='sm'>DOCUMENTOS DOS REQUERENTES</Heading></Tab>
						<Tab className="info_qualificacao" _selected={{ bg: 'gray.300' }}><Heading size='sm'>INFORMAÇÕES DA QUALIFICAÇÃO</Heading></Tab>
					</TabList>
					<Box overflowX="auto"
						whiteSpace="nowrap"
						sx={{
							'&::-webkit-scrollbar': {
								width: '16px',
								borderRadius: '8px',
								backgroundColor: `rgba(0, 0, 0, 0.05)`,
							},
							'&::-webkit-scrollbar-thumb': {
								backgroundColor: `rgba(0, 0, 0, 0.05)`,
							},
						}}
						w='100%'
						height={(window.innerHeight - 300)}>
						<TabPanels>
							<TabPanel className="docs_internos">
								<TableContainer>
									<Table size='sm'>
										<Thead>
											<Tr>
												<Th textAlign={'center'}>CÓDIGO</Th>
												<Th textAlign={'center'}>REQUERENTE</Th>
												<Th textAlign={'center'}>CONTATO</Th>
												<Th textAlign={'center'}>CONTRATO</Th>
												<Th textAlign={'center'}>PROCURAÇÃO</Th>
												<Th textAlign={'center'}>REQUERIMENTO</Th>
												<Th textAlign={'center'}>DISTRATO</Th>
												<Th textAlign={'center'}>SITUAÇÃO</Th>
											</Tr>
										</Thead>
										<Tbody>
											{lista_requerentes.map((item, i) => {
												return (
													<Tr key={i} cursor='pointer' _hover={{ bg: 'gray.200' }} onClick={e => { irParaRequerente(item.req_id) }} bg={item.situacao === 'Cancelado' ? 'red.100' : 'white'}>
														<Td textAlign={'center'}>{item.codigo}</Td>
														<Td whiteSpace={'pre-wrap'} maxW={'250px'}>{item.requerente}</Td>
														<Td whiteSpace={'pre-wrap'} textAlign={'center'}>{item.contato}</Td>
														<Td textAlign={'center'}>
															<Text display={item.contrato === -1 ? 'inline' : 'none'}>-</Text>
															<CheckIcon display={item.contrato === 1 ? 'inline' : 'none'} color={'green.500'} />
															<CloseIcon display={item.contrato === 0 ? 'inline' : 'none'} color={'red'} />
														</Td>
														<Td textAlign={'center'}>
															<Text display={item.procuracao === -1 ? 'inline' : 'none'}>-</Text>
															<CheckIcon display={item.procuracao === 1 ? 'inline' : 'none'} color={'green.500'} />
															<CloseIcon display={item.procuracao === 0 ? 'inline' : 'none'} color={'red'} />
														</Td>
														<Td textAlign={'center'}>
															<Text display={item.requerimento === -1 ? 'inline' : 'none'}>-</Text>
															<CheckIcon display={item.requerimento === 1 ? 'inline' : 'none'} color={'green.500'} />
															<CloseIcon display={item.requerimento === 0 ? 'inline' : 'none'} color={'red'} />
														</Td>
														<Td textAlign={'center'}>
															<Text display={item.distrato === -1 ? 'inline' : 'none'}>-</Text>
															<CheckIcon display={item.distrato === 1 ? 'inline' : 'none'} color={'green.500'} />
															<CloseIcon display={item.distrato === 0 ? 'inline' : 'none'} color={'red'} />
														</Td>
														<Td textAlign={'center'}>{item.situacao}</Td>
													</Tr>
												);
											})}
										</Tbody>
									</Table>
								</TableContainer>

							</TabPanel>
							<TabPanel className="docs_requerentes">
								<TableContainer>
									<Table size='sm'>
										<Thead>
											<Tr>
												<Th textAlign={'center'}>CÓDIGO</Th>
												<Th textAlign={'center'}>REQUERENTE</Th>
												<Th textAlign={'center'}>CONTATO</Th>
												<Th textAlign={'center'}>TIPO</Th>
												<Th textAlign={'center'}>DOCUMENTO FALTANTE</Th>
												<Th textAlign={'center'}>OBSERVAÇÃO</Th>
												<Th textAlign={'center'}>SITUAÇÃO</Th>
											</Tr>
										</Thead>
										<Tbody>
											{lista_requerentes.map((item, i) => {
												return (
													<Tr key={i} cursor='pointer' _hover={{ bg: 'gray.200' }} onClick={e => { irParaRequerente(item.req_id) }} bg={item.situacao === 'Cancelado' ? 'red.100' : 'white'}>
														<Td textAlign={'center'}>{item.codigo}</Td>
														<Td whiteSpace={'pre-wrap'} maxW={'250px'}>{item.requerente}</Td>
														<Td whiteSpace={'pre-wrap'} textAlign={'center'}>{item.contato}</Td>
														<Td textAlign={'center'} whiteSpace={'pre-wrap'}>{item.tipo}</Td>
														<Td whiteSpace={'pre-wrap'} maxW={'20vw'}>{item.documento_faltante}</Td>
														<Td whiteSpace={'pre-wrap'} maxW={'20vw'}>{item.doc_observacao}</Td>
														<Td textAlign={'center'}>{item.situacao}</Td>
													</Tr>
												);
											})}
										</Tbody>
									</Table>
								</TableContainer>
							</TabPanel>
							<TabPanel className="info_qualificacao">
								<TableContainer>
									<Table size='sm'>
										<Thead>
											<Tr>
												<Th textAlign={'center'}>CÓDIGO</Th>
												<Th textAlign={'center'}>REQUERENTE</Th>
												<Th textAlign={'center'}>CONTATO</Th>
												<Th textAlign={'center'}>TIPO</Th>
												<Th textAlign={'center'}>DOCUMENTO FALTANTE</Th>
												<Th textAlign={'center'}>SITUAÇÃO</Th>
											</Tr>
										</Thead>
										<Tbody>
											{lista_requerentes.map((item, i) => {
												return (
													<Tr key={i} cursor='pointer' _hover={{ bg: 'gray.200' }} onClick={e => { irParaRequerente(item.req_id) }} bg={item.situacao === 'Cancelado' ? 'red.100' : 'white'}>
														<Td textAlign={'center'}>{item.codigo}</Td>
														<Td whiteSpace={'pre-wrap'} maxW={'250px'}>{item.requerente}</Td>
														<Td whiteSpace={'pre-wrap'} textAlign={'center'}>{item.contato}</Td>
														<Td textAlign={'center'} whiteSpace={'pre-wrap'}>{item.tipo}</Td>
														<Td whiteSpace={'pre-wrap'} maxW={'30vw'}>{item.informacao_faltante}</Td>
														<Td textAlign={'center'}>{item.situacao}</Td>
													</Tr>
												);
											})}
										</Tbody>
									</Table>
								</TableContainer>
							</TabPanel>
						</TabPanels>
					</Box>
				</Tabs>
			</Container>
		</div>
	);
}

export default DocumentalPage;