import React, { useRef } from "react";
import { useContext, useState, useEffect } from "react";
import { Text, Button, TableContainer, Table, Thead, Tr, Th, Td, Tbody, Flex, Spacer, Select, Container, Tab, Tabs, TabList, Heading, Center, Box } from '@chakra-ui/react';
import { useNavigate } from "react-router-dom";
import { ChevronLeftIcon } from "@chakra-ui/icons";
import ExportExcel from "../../components/Excel/excelexport";

import NavSuperior from "../../components/NavBar/navsuperior";
import DadosRemessa from "../../components/Remessa/dadosremessa";

import { api } from "../../../services/api";
import { AuthContext } from "../../contexts/auth";
import { ReurbContext } from "../../contexts/reurb";

const QualificacaoPage = () => {
	//Variáveis do Contexto
	const { logout } = useContext(AuthContext);
	const { rem_id, rem_municipio, rem_remessa } = useContext(ReurbContext);

	//Navegação
	const navigate = useNavigate();

	//Variáveis
	const [lista_qualificacao, setLista_qualificacao] = useState([{ unid_id: 0, unid_cod: '-', nui_cod: '-', nui_nome: '-', beneficiarios: '-', qualificacao: '-', info_faltante: '-', situacao: '-' }]);
	const [excel_qualificacao, setExcel_qualificacao] = useState([{ CodigoProcesso: '-', CodigoNucleo: '-', NomeNucleo: '-', Beneficiarios: '-', Qualificacao: '-', InformacaoFaltante: '-', Situacao: '-' }]);
	const [lista_nucleo, setLista_nucleo] = useState([{ nui_id: 0, nui_cod: '-', nui_nome: '-' }]);
	const [lista_situacao, setLista_situacao] = useState([{ sit_id: 0, situacao: '-' }]);

	//Filtros
	const [qualificacao_selecionada, setQualificacao_selecionada] = useState(1);
	const [nucleo_selecionado, setNucleo_selecionado] = useState(0);
	const [situacao_selecionada, setSituacao_selecionada] = useState(0);

	//Ler dados
	useEffect(() => {

		const data = {
			rem_id,
			qualificacao_selecionada,
			nucleo_selecionado,
			situacao_selecionada
		}

		//Lista de qualificações
		api.post('/qualificacao/LerQualificacao.php', JSON.stringify(data))
			.then(function (rp) {
				setLista_qualificacao(rp.data.lista_qualificacao);
				setExcel_qualificacao(rp.data.excel_qualificacao);
				setLista_nucleo(rp.data.lista_nucleo);
				setLista_situacao(rp.data.lista_situacao);
			})
			.catch(function (error) {
				if (error.response.data === "EXPIRED") {
					logout();
				} else {
					console.log(error);
				}
			});

	}, [qualificacao_selecionada, nucleo_selecionado, situacao_selecionada]);

	//Ir para remessa
	function goToRemessa() {
		navigate('/remessa');
	}

	return (
		<div id="qualificacao">
			<NavSuperior />
			<DadosRemessa titulo="Qualificação dos Beneficiários" />

			<Container maxW='95%' bg='white'>
				<Flex direction='row' w={'100%'} h={'60px'} bg={'gray.200'}>
					<Center w={'100px'} h={'100%'} color={'gray.600'} cursor='pointer' _hover={{ color: 'teal' }} onClick={e => goToRemessa()}>
						<ChevronLeftIcon boxSize={12} />
					</Center>
					<Spacer />
					<Center>
						<Text mr={3}>Núcleo</Text>
						<Select mr={4} borderColor={'teal'} size='sm' w='200px' value={nucleo_selecionado} onChange={e => { setNucleo_selecionado(e.target.value) }}>
							<option key={0} value={0}>{'-'}</option>
							{lista_nucleo.map((item, i) => {
								return (<option key={i + 1} value={item.nui_id}>{item.nui_cod}</option>);
							})}
						</Select>
						<Text mr={2} pt={2}>Situação</Text>
						<Select mr={4} borderColor={'teal'} size='sm' w='200px' value={situacao_selecionada} onChange={e => { setSituacao_selecionada(e.target.value) }}>
							<option key={0} value={0}>{'-'}</option>
							{lista_situacao.map((item, i) => {
								return (<option key={i + 1} value={item.sit_id}>{item.situacao}</option>);
							})}
						</Select>
					</Center>
					<Center w={'130px'}>
						<ExportExcel excelData={excel_qualificacao} fileName={"Dados Qualificação"} />
					</Center>
				</Flex>
				<Tabs isFitted>
					<TabList color={'gray.600'} backgroundColor={'gray.200'} h={'40px'}>
						<Tab className="simples" _selected={{ bg: 'gray.300' }} onClick={e => { setQualificacao_selecionada(1) }}><Heading size='sm'>SIMPLES</Heading></Tab>
						<Tab className="compromisso" _selected={{ bg: 'gray.300' }} onClick={e => { setQualificacao_selecionada(2) }}><Heading size='sm'>COMPROMISSO</Heading></Tab>
						<Tab className="basica" _selected={{ bg: 'gray.300' }} onClick={e => { setQualificacao_selecionada(3) }}><Heading size='sm'>BÁSICA</Heading></Tab>
						<Tab className="crf" _selected={{ bg: 'gray.300' }} onClick={e => { setQualificacao_selecionada(4) }}><Heading size='sm'>COMPLETA</Heading></Tab>
					</TabList>
					<Box overflowX="auto"
						whiteSpace="nowrap"
						sx={{
							'&::-webkit-scrollbar': {
								width: '16px',
								borderRadius: '8px',
								backgroundColor: `rgba(0, 0, 0, 0.05)`,
							},
							'&::-webkit-scrollbar-thumb': {
								backgroundColor: `rgba(0, 0, 0, 0.05)`,
							},
						}}
						w='100%'
						height={(window.innerHeight - 300)}>
						<TableContainer mt={5}>
							<Table size='sm'>
								<Thead>
									<Tr>
										<Th textAlign={'center'}>NUI</Th>
										<Th textAlign={'center'}>CÓDIGO</Th>
										<Th textAlign={'center'}>BENEFICIÁRIOS</Th>
										<Th textAlign={'center'}>QUALIFICAÇÃO</Th>
									</Tr>
								</Thead>
								<Tbody>
									{lista_qualificacao.map((item, i) => {
										return (
											<Tr key={i}>
												<Td textAlign={'center'}>{item.nui_cod}</Td>
												<Td textAlign={'center'}>{item.unid_cod}</Td>
												<Td whiteSpace={'pre-wrap'}>{item.beneficiarios}</Td>
												<Td whiteSpace={'pre-wrap'} maxWidth={'1000px'} color={item.situacao === 'Completo' ? 'black' : 'red'}><Text display={item.situacao === 'Completo' ? 'flex' : 'none'}>{item.qualificacao}</Text><Text display={item.situacao === 'Incompleto' ? 'flex' : 'none'}>{'Dados faltantes: ' + item.info_faltante}</Text></Td>
											</Tr>
										);
									})}
								</Tbody>
							</Table>
						</TableContainer>
					</Box>
				</Tabs>
			</Container>
		</div>
	);
}

export default QualificacaoPage;