import React from "react";
import { useContext, useState, useEffect } from "react";
import ResizeTextarea from "react-textarea-autosize";
import { Grid, GridItem, Text, Textarea, Input, Button, ButtonGroup, Select, Stack, RadioGroup, Radio, Checkbox, Box, Heading, Flex, Divider, useToast, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, useDisclosure, AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogBody, AlertDialogFooter, TableContainer, Tbody, Table, Tr, Td, Container, Spacer, Center, Thead, Th, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Collapse } from '@chakra-ui/react';
import { ChevronLeftIcon, CheckIcon, EditIcon } from "@chakra-ui/icons";
import { SaveIcon } from "../../icons/saveicon";

import ReactInputMask from "react-input-mask";

import { api } from "../../../services/api";

import { AuthContext } from "../../contexts/auth";
import { ReurbContext } from "../../contexts/reurb";

const EditarRequerente = () => {

  //Variáveis do Contexto
  const { logout } = useContext(AuthContext);
  const { rem_id, unid_id, req_id, setEditar_requerente } = useContext(ReurbContext);

  //Variáveis Chakra UI
  const toast = useToast();
  const { isOpen: isTitularOpen, onToggle: onTitularToggle } = useDisclosure();
  const { isOpen: isBuscarOpen, onOpen: onBuscarOpen, onClose: onBuscarClose } = useDisclosure();
  const { isOpen: isMenorOpen, onOpen: onMenorOpen, onClose: onMenorClose } = useDisclosure();
  const { isOpen: isDocumentalOpen, onOpen: onDocumentalOpen, onClose: onDocumentalClose } = useDisclosure();
  const cancelMenorRef = React.useRef();

  const lista_emissores = ['BM - Brigada Militar', 'CRP - Conselho Regional Profissional', 'DPC - Departamento de Polícia Civil', 'DPF - Departamento de Polícia Federal', 'IE - Identidade de Estrangeiro', 'IFP - Instituto Félix Pacheco', 'MAER - Ministério da Aeronáutica', 'MM - Ministério da Marinha', 'ME - Ministério do Exército', 'PM - Polícia Militar', 'SSI - Secretaria de Segurança e Informação', 'SSP - Secretaria de Segurança Pública', 'IIRGD - Inst. de Ident. Ricardo Gumbleton Daunt', 'SJS - SJS', 'SPMAF - SPMAF', 'SJTC - SJTC', 'PC - PC', 'DETRAN - Departamento de Trânsito', 'MT - Ministério do Trabalho', 'IGP - Institulo Geral de Perícias', 'OAB - Ordem dos Advogados do Brasil', 'SPTC - Super. Polícia Técnico-Científica', 'SDS - Sec de Desenvolvimento Social', 'CRMV - Conselho Regional de Medicina Veterinária', 'Outro órgão emissor...'];

  const lista_sigla_emissores = ['BM', 'CRP', 'DPC', 'DPF', 'IE', 'IFP', 'MAER', 'MM', 'ME', 'PM', 'SSI', 'SSP', 'IIRGD', 'SJS', 'SPMAF', 'SJTC', 'PC', 'DETRAN', 'MT', 'IGP', 'OAB', 'SPTC', 'SDS', 'CRMV', 'OUTRO'];

  //Controle
  const [loaded, setLoaded] = useState(0);
  const [habilitar_conjuge, setHabilitar_conjuge] = useState(false);

  //Controle Salvamento
  const [check_reqpf, setCheck_reqpf] = useState(0);
  const [check_conj, setCheck_conj] = useState(0);
  const [check_resid, setCheck_resid] = useState(0);
  const [check_reqpj, setCheck_reqpj] = useState(0);

  //Listas Buscar
  const [id_buscar_remessa, setId_buscar_remessa] = useState(rem_id);
  const [lista_remessas, setLista_remessas] = useState([{ value: 0, text: '' }]);
  const [lista_requerentes, setLista_requerentes] = useState([{ value: 0, requerente: '', conjuge: '', codigo: '' }]);

  //Variáveis do processo
  const [unid_cod, setUnid_cod] = useState("");
  const [nui_cod, setNui_cod] = useState("");
  const [reurb_modalidade, setReurb_modalidade] = useState("");

  //Tipo de Requerente
  const [req_tipo, setReq_tipo] = useState(0);

  //Variáveis Requerente PF
  const [lista_representantes, setLista_representantes] = useState([{ value: 0, texto: '' }]);
  const [req_representante, setReq_representante] = useState(0);

  const [req_beneficiario, setReq_beneficiario] = useState(0);
  const [req_nome, setReq_nome] = useState("");
  const [req_nacionalidade, setReq_nacionalidade] = useState("");
  const [req_naturalidade, setReq_naturalidade] = useState("");
  const [req_rg, setReq_rg] = useState("");
  const [req_emissor, setReq_emissor] = useState("");
  const [req_outroemissor, setReq_outroemissor] = useState("");
  const [req_ufemissor, setReq_ufemissor] = useState("");
  const [req_cpf, setReq_cpf] = useState("");
  const [req_sexo, setReq_sexo] = useState('');
  const [req_nascimento, setReq_nascimento] = useState("");
  const [req_idade, setReq_idade] = useState("");
  const [req_pai, setReq_pai] = useState("");
  const [req_mae, setReq_mae] = useState("");
  const [req_sempai, setReq_sempai] = useState(0);
  const [req_semmae, setReq_semmae] = useState(0);
  const [req_profissao, setReq_profissao] = useState("");
  const [req_empregador, setReq_empregador] = useState("");
  const [req_renda, setReq_renda] = useState("");
  const [req_estadocivil, setReq_estadocivil] = useState("");
  const [req_uniao, setReq_uniao] = useState("");
  const [req_regime, setReq_regime] = useState("");
  const [req_separacao, setReq_separacao] = useState("");
  const [req_telefone, setReq_telefone] = useState("");
  const [req_whatsapp, setReq_whatsapp] = useState(0);
  const [req_email, setReq_email] = useState("");
  const [req_deficiencia, setReq_deficiencia] = useState(0);
  const [req_identidade, setReq_identidade] = useState(0);
  const [req_motorista, setReq_motorista] = useState(0);
  const [req_trabalho, setReq_trabalho] = useState(0);
  const [req_passaporte, setReq_passaporte] = useState(0);
  const [req_certnascimento, setReq_certnascimento] = useState(0);
  const [req_decsolteiro, setReq_decsolteiro] = useState(0);
  const [req_decuniao, setReq_decuniao] = useState(0);
  const [req_certcasamento, setReq_certcasamento] = useState(0);
  const [req_compdivorcio, setReq_compdivorcio] = useState(0);
  const [req_compdissolucao, setReq_compdissolucao] = useState(0);
  const [req_certobito, setReq_certobito] = useState(0);
  const [req_folha, setReq_folha] = useState(0);
  const [req_compaposentadoria, setReq_compaposentadoria] = useState(0);
  const [req_compbeneficio, setReq_compbeneficio] = useState(0);
  const [req_decrenda, setReq_decrenda] = useState(0);

  //Variáveis Requerente PJ
  const [req_cnpj, setReq_cnpj] = useState("");
  const [req_cartaocnpj, setReq_cartaocnpj] = useState(0);
  const [req_contratosocial, setReq_contratosocial] = useState(0);
  const [req_inscricaoestadual, setReq_inscricaoestadual] = useState(0);

  //Variáveis Cônjuge
  const [conj_beneficiario, setConj_beneficiario] = useState(0);
  const [conj_nome, setConj_nome] = useState("");
  const [conj_nacionalidade, setConj_nacionalidade] = useState("");
  const [conj_naturalidade, setConj_naturalidade] = useState("");
  const [conj_rg, setConj_rg] = useState("");
  const [conj_emissor, setConj_emissor] = useState("");
  const [conj_outroemissor, setConj_outroemissor] = useState("");
  const [conj_ufemissor, setConj_ufemissor] = useState("");
  const [conj_cpf, setConj_cpf] = useState("");
  const [conj_sexo, setConj_sexo] = useState('');
  const [conj_nascimento, setConj_nascimento] = useState("");
  const [conj_idade, setConj_idade] = useState(0);
  const [conj_pai, setConj_pai] = useState("");
  const [conj_mae, setConj_mae] = useState("");
  const [conj_sempai, setConj_sempai] = useState(0);
  const [conj_semmae, setConj_semmae] = useState(0);
  const [conj_profissao, setConj_profissao] = useState("");
  const [conj_empregador, setConj_empregador] = useState("");
  const [conj_renda, setConj_renda] = useState("");
  const [conj_estadocivil, setConj_estadocivil] = useState("");
  const [conj_telefone, setConj_telefone] = useState("");
  const [conj_whatsapp, setConj_whatsapp] = useState(0);
  const [conj_email, setConj_email] = useState("");
  const [conj_deficiencia, setConj_deficiencia] = useState(0);
  const [conj_identidade, setConj_identidade] = useState(0);
  const [conj_motorista, setConj_motorista] = useState(0);
  const [conj_trabalho, setConj_trabalho] = useState(0);
  const [conj_passaporte, setConj_passaporte] = useState(0);
  const [conj_certnascimento, setconj_certnascimento] = useState(0);
  const [conj_decsolteiro, setconj_decsolteiro] = useState(0);
  const [conj_decuniao, setconj_decuniao] = useState(0);
  const [conj_certcasamento, setconj_certcasamento] = useState(0);
  const [conj_compdivorcio, setconj_compdivorcio] = useState(0);
  const [conj_compdissolucao, setconj_compdissolucao] = useState(0);
  const [conj_certobito, setConj_certobito] = useState(0);
  const [conj_folha, setConj_folha] = useState(0);
  const [conj_compaposentadoria, setConj_compaposentadoria] = useState(0);
  const [conj_compbeneficio, setConj_compbeneficio] = useState(0);
  const [conj_decrenda, setConj_decrenda] = useState(0);

  //Variáveis Residência
  const [resid_logradouro, setResid_logradouro] = useState("");
  const [resid_numero, setResid_numero] = useState("");
  const [resid_complemento, setResid_complemento] = useState("");
  const [resid_bairro, setResid_bairro] = useState("");
  const [resid_localidade, setResid_localidade] = useState("");
  const [resid_municipio, setResid_municipio] = useState("");
  const [resid_uf, setResid_uf] = useState("");
  const [resid_cep, setResid_cep] = useState("");
  const [resid_reqtitular, setResid_reqtitular] = useState(0);
  const [resid_conjtitular, setResid_conjtitular] = useState(0);
  const [resid_outrotitular, setResid_outrotitular] = useState(0);
  const [resid_titular, setResid_titular] = useState("");
  const [resid_cpf, setResid_cpf] = useState("");
  const [resid_nascimento, setResid_nascimento] = useState("");
  const [resid_iptu, setResid_iptu] = useState(0);
  const [resid_energia, setResid_energia] = useState(0);
  const [resid_agua, setResid_agua] = useState(0);
  const [resid_dectitular, setResid_dectitular] = useState(0);

  //Variáveis Avaliação Documental
  const [doc_observacao, setDoc_observacao] = useState("");

  //Ler dados
  useEffect(() => {
    const data = {
      rem_id,
      req_id
    }
    api.post('/requerente/LerEditarRequerente.php', JSON.stringify(data))
      .then(function (rp) {

        //Dados do Processo
        setUnid_cod(rp.data.unid_cod);
        setNui_cod(rp.data.nui_cod);
        setReurb_modalidade(rp.data.reurb_modalidade);

        //Tipo de requerente
        setReq_tipo(rp.data.req_tipo);

        //Requerente PF
        setLista_representantes(rp.data.lista_representantes);
        setReq_representante(rp.data.req_representante);

        setReq_beneficiario(rp.data.req_beneficiario);
        setReq_nome(rp.data.req_nome);
        setReq_nacionalidade(rp.data.req_nacionalidade);
        setReq_naturalidade(rp.data.req_naturalidade);
        setReq_rg(rp.data.req_rg);
        setReq_emissor(rp.data.req_emissor);
        setReq_outroemissor(rp.data.req_outroemissor);
        setReq_ufemissor(rp.data.req_ufemissor);
        setReq_cpf(rp.data.req_cpf);
        setReq_sexo(rp.data.req_sexo);
        setReq_nascimento(rp.data.req_nascimento);
        setReq_idade(rp.data.req_idade);
        setReq_pai(rp.data.req_pai);
        setReq_mae(rp.data.req_mae);
        setReq_sempai(rp.data.req_sempai);
        setReq_semmae(rp.data.req_semmae);
        setReq_profissao(rp.data.req_profissao);
        setReq_empregador(rp.data.req_empregador);
        setReq_renda(rp.data.req_renda);
        setReq_estadocivil(rp.data.req_estadocivil);
        setReq_uniao(rp.data.req_uniao);
        setReq_regime(rp.data.req_regime);
        setReq_separacao(rp.data.req_separacao);
        setReq_telefone(rp.data.req_telefone);
        setReq_whatsapp(rp.data.req_whatsapp);
        setReq_email(rp.data.req_email);
        setReq_deficiencia(rp.data.req_deficiencia);
        setReq_identidade(rp.data.req_identidade);
        setReq_motorista(rp.data.req_motorista);
        setReq_trabalho(rp.data.req_trabalho);
        setReq_passaporte(rp.data.req_passaporte);
        setReq_certnascimento(rp.data.req_certnascimento);
        setReq_decsolteiro(rp.data.req_decsolteiro);
        setReq_decuniao(rp.data.req_decuniao);
        setReq_certcasamento(rp.data.req_certcasamento);
        setReq_compdivorcio(rp.data.req_compdivorcio);
        setReq_compdissolucao(rp.data.req_compdissolucao);
        setReq_certobito(rp.data.req_certobito);
        setReq_folha(rp.data.req_folha);
        setReq_compaposentadoria(rp.data.req_compaposentadoria);
        setReq_compbeneficio(rp.data.req_compbeneficio);
        setReq_decrenda(rp.data.req_decrenda);

        //Requerente PJ
        setReq_cnpj(rp.data.req_cnpj);
        setReq_cartaocnpj(rp.data.req_cartaocnpj);
        setReq_contratosocial(rp.data.req_contratosocial);
        setReq_inscricaoestadual(rp.data.req_inscricaoestadual);

        //Cônjuge
        setConj_beneficiario(rp.data.conj_beneficiario);
        setConj_nome(rp.data.conj_nome);
        setConj_nacionalidade(rp.data.conj_nacionalidade);
        setConj_naturalidade(rp.data.conj_naturalidade);
        setConj_rg(rp.data.conj_rg);
        setConj_emissor(rp.data.conj_emissor);
        setConj_outroemissor(rp.data.conj_outroemissor);
        setConj_ufemissor(rp.data.conj_ufemissor);
        setConj_cpf(rp.data.conj_cpf);
        setConj_sexo(rp.data.conj_sexo);
        setConj_nascimento(rp.data.conj_nascimento);
        setConj_idade(rp.data.conj_idade);
        setConj_pai(rp.data.conj_pai);
        setConj_mae(rp.data.conj_mae);
        setConj_sempai(rp.data.conj_sempai);
        setConj_semmae(rp.data.conj_semmae);
        setConj_profissao(rp.data.conj_profissao);
        setConj_empregador(rp.data.conj_empregador);
        setConj_renda(rp.data.conj_renda);
        setConj_estadocivil(rp.data.conj_estadocivil);
        setConj_telefone(rp.data.conj_telefone);
        setConj_whatsapp(rp.data.conj_whatsapp);
        setConj_email(rp.data.conj_email);
        setConj_deficiencia(rp.data.conj_deficiencia);
        setConj_identidade(rp.data.conj_identidade);
        setConj_motorista(rp.data.conj_motorista);
        setConj_trabalho(rp.data.conj_trabalho);
        setConj_passaporte(rp.data.conj_passaporte);
        setconj_certnascimento(rp.data.conj_certnascimento);
        setconj_decsolteiro(rp.data.conj_decsolteiro);
        setconj_decuniao(rp.data.conj_decuniao);
        setconj_certcasamento(rp.data.conj_certcasamento);
        setconj_compdivorcio(rp.data.conj_compdivorcio);
        setconj_compdissolucao(rp.data.conj_compdissolucao);
        setConj_certobito(rp.data.conj_certobito);
        setConj_folha(rp.data.conj_folha);
        setConj_compaposentadoria(rp.data.conj_compaposentadoria);
        setConj_compbeneficio(rp.data.conj_compbeneficio);
        setConj_decrenda(rp.data.conj_decrenda);

        //Residência
        setResid_logradouro(rp.data.resid_logradouro);
        setResid_numero(rp.data.resid_numero);
        setResid_complemento(rp.data.resid_complemento);
        setResid_bairro(rp.data.resid_bairro);
        setResid_localidade(rp.data.resid_localidade);
        setResid_municipio(rp.data.resid_municipio);
        setResid_uf(rp.data.resid_uf);
        setResid_cep(rp.data.resid_cep);
        setResid_reqtitular(rp.data.resid_reqtitular);
        setResid_conjtitular(rp.data.resid_conjtitular);
        setResid_outrotitular(rp.data.resid_outrotitular);
        setResid_titular(rp.data.resid_titular);
        setResid_cpf(rp.data.resid_cpf);
        setResid_nascimento(rp.data.resid_nascimento);
        setResid_iptu(rp.data.resid_iptu);
        setResid_energia(rp.data.resid_energia);
        setResid_agua(rp.data.resid_agua);
        setResid_dectitular(rp.data.resid_dectitular);

        //Documental
        setDoc_observacao(rp.data.doc_observacao);

        setLoaded(1);

      })
      .catch(function (error) {
        if (error.response.data === "EXPIRED") {
          logout();
        } else {
          console.log(error);
        }
      });
  }, []);

  //Habilitar Cônjuge
  useEffect(() => {
    if (req_estadocivil === "Casado(a)" || req_estadocivil.slice(-13) === "União Estável") {
      setHabilitar_conjuge(true);
      if(req_estadocivil === "Casado(a)"){
        setConj_estadocivil("Casado(a)");
      }
    } else {
      setHabilitar_conjuge(false);
    }
  }, [req_estadocivil]);

  //Carregar lista de remessas e requerentes
  async function carregarListaBuscar(id) {
    const data = {
      rem_id: id,
      req_id,
      req_tipo
    }
    await api.post('/requerente/LerListaBuscar.php', JSON.stringify(data))
      .then(function (rp) {
        setLista_remessas(rp.data.lista_remessas);
        setLista_requerentes(rp.data.lista_requerentes);
      })
      .catch(function (error) {
        if (error.response.data === "EXPIRED") {
          logout();
        } else {
          console.log(error);
        }
      });
  };

  //Pegar dados do requerente
  async function pegarDadosRequerente(id) {
    if (id > 0) {
      const data = {
        req_id: id,
        unid_id
      }
      await api.post('/requerente/PegarDados.php', JSON.stringify(data))
        .then(function (rp) {

          //Tipo de requerente
          setReq_tipo(rp.data.req_tipo);

          //Requerente PF
          setLista_representantes(rp.data.lista_representantes);
          setReq_representante(rp.data.req_representante);

          setReq_beneficiario(rp.data.req_beneficiario);
          setReq_nome(rp.data.req_nome);
          setReq_nacionalidade(rp.data.req_nacionalidade);
          setReq_naturalidade(rp.data.req_naturalidade);
          setReq_rg(rp.data.req_rg);
          setReq_emissor(rp.data.req_emissor);
          setReq_outroemissor(rp.data.req_outroemissor);
          setReq_ufemissor(rp.data.req_ufemissor);
          setReq_cpf(rp.data.req_cpf);
          setReq_sexo(rp.data.req_sexo);
          setReq_nascimento(rp.data.req_nascimento);
          setReq_idade(rp.data.req_idade);
          setReq_pai(rp.data.req_pai);
          setReq_mae(rp.data.req_mae);
          setReq_sempai(rp.data.req_sempai);
          setReq_semmae(rp.data.req_semmae);
          setReq_profissao(rp.data.req_profissao);
          setReq_empregador(rp.data.req_empregador);
          setReq_renda(rp.data.req_renda);
          setReq_estadocivil(rp.data.req_estadocivil);
          setReq_uniao(rp.data.req_uniao);
          setReq_regime(rp.data.req_regime);
          setReq_separacao(rp.data.req_separacao);
          setReq_telefone(rp.data.req_telefone);
          setReq_whatsapp(rp.data.req_whatsapp);
          setReq_email(rp.data.req_email);
          setReq_deficiencia(rp.data.req_deficiencia);
          setReq_identidade(rp.data.req_identidade);
          setReq_motorista(rp.data.req_motorista);
          setReq_trabalho(rp.data.req_trabalho);
          setReq_passaporte(rp.data.req_passaporte);
          setReq_certnascimento(rp.data.req_certnascimento);
          setReq_decsolteiro(rp.data.req_decsolteiro);
          setReq_decuniao(rp.data.req_decuniao);
          setReq_certcasamento(rp.data.req_certcasamento);
          setReq_compdivorcio(rp.data.req_compdivorcio);
          setReq_compdissolucao(rp.data.req_compdissolucao);
          setReq_certobito(rp.data.req_certobito);
          setReq_folha(rp.data.req_folha);
          setReq_compaposentadoria(rp.data.req_compaposentadoria);
          setReq_compbeneficio(rp.data.req_compbeneficio);
          setReq_decrenda(rp.data.req_decrenda);

          //Requerente PJ
          setReq_cnpj(rp.data.req_cnpj);
          setReq_cartaocnpj(rp.data.req_cartaocnpj);
          setReq_contratosocial(rp.data.req_contratosocial);
          setReq_inscricaoestadual(rp.data.req_inscricaoestadual);

          //Cônjuge
          setConj_beneficiario(rp.data.conj_beneficiario);
          setConj_nome(rp.data.conj_nome);
          setConj_nacionalidade(rp.data.conj_nacionalidade);
          setConj_naturalidade(rp.data.conj_naturalidade);
          setConj_rg(rp.data.conj_rg);
          setConj_emissor(rp.data.conj_emissor);
          setConj_outroemissor(rp.data.conj_outroemissor);
          setConj_ufemissor(rp.data.conj_ufemissor);
          setConj_cpf(rp.data.conj_cpf);
          setConj_sexo(rp.data.conj_sexo);
          setConj_nascimento(rp.data.conj_nascimento);
          setConj_idade(rp.data.conj_idade);
          setConj_pai(rp.data.conj_pai);
          setConj_mae(rp.data.conj_mae);
          setConj_sempai(rp.data.conj_sempai);
          setConj_semmae(rp.data.conj_semmae);
          setConj_profissao(rp.data.conj_profissao);
          setConj_empregador(rp.data.conj_empregador);
          setConj_renda(rp.data.conj_renda);
          setConj_estadocivil(rp.data.conj_estadocivil);
          setConj_telefone(rp.data.conj_telefone);
          setConj_whatsapp(rp.data.conj_whatsapp);
          setConj_email(rp.data.conj_email);
          setConj_deficiencia(rp.data.conj_deficiencia);
          setConj_identidade(rp.data.conj_identidade);
          setConj_motorista(rp.data.conj_motorista);
          setConj_trabalho(rp.data.conj_trabalho);
          setConj_passaporte(rp.data.conj_passaporte);
          setconj_certnascimento(rp.data.conj_certnascimento);
          setconj_decsolteiro(rp.data.conj_decsolteiro);
          setconj_decuniao(rp.data.conj_decuniao);
          setconj_certcasamento(rp.data.conj_certcasamento);
          setconj_compdivorcio(rp.data.conj_compdivorcio);
          setconj_compdissolucao(rp.data.conj_compdissolucao);
          setConj_folha(rp.data.conj_folha);
          setConj_compaposentadoria(rp.data.conj_compaposentadoria);
          setConj_compbeneficio(rp.data.conj_compbeneficio);
          setConj_decrenda(rp.data.conj_decrenda);

          //Residência
          setResid_logradouro(rp.data.resid_logradouro);
          setResid_numero(rp.data.resid_numero);
          setResid_complemento(rp.data.resid_complemento);
          setResid_bairro(rp.data.resid_bairro);
          setResid_localidade(rp.data.resid_localidade);
          setResid_municipio(rp.data.resid_municipio);
          setResid_uf(rp.data.resid_uf);
          setResid_cep(rp.data.resid_cep);
          setResid_reqtitular(rp.data.resid_reqtitular);
          setResid_conjtitular(rp.data.resid_conjtitular);
          setResid_outrotitular(rp.data.resid_outrotitular);
          setResid_titular(rp.data.resid_titular);
          setResid_cpf(rp.data.resid_cpf);
          setResid_nascimento(rp.data.resid_nascimento);
          setResid_iptu(rp.data.resid_iptu);
          setResid_energia(rp.data.resid_energia);
          setResid_agua(rp.data.resid_agua);
          setResid_dectitular(rp.data.resid_dectitular);

          onBuscarClose();

        })
        .catch(function (error) {
          if (error.response.data === "EXPIRED") {
            logout();
          } else {
            console.log(error);
          }
        });
    }
  }

  //Pegar endereço
  async function pegarEndereco() {
    await api.post('/requerente/PegarEndereco.php', JSON.stringify({ unid_id }))
      .then(function (rp) {
        setResid_logradouro(rp.data.resid_logradouro);
        setResid_numero(rp.data.resid_numero);
        setResid_complemento(rp.data.resid_complemento);
        setResid_bairro(rp.data.resid_bairro);
        setResid_localidade(rp.data.resid_localidade);
        setResid_municipio(rp.data.resid_municipio);
        setResid_uf(rp.data.resid_uf);
        setResid_cep(rp.data.resid_cep);
      })
      .catch(function (error) {
        if (error.response.data === "EXPIRED") {
          logout();
        } else {
          console.log(error);
        }
      });
  }

  //Calcular Idade
  function CalcularIdade(dataNasc) {
    //verificar se data é válida
    var date_regex = /^([0-9]{4}[-/]?((0[13-9]|1[012])[-/]?(0[1-9]|[12][0-9]|30)|(0[13578]|1[02])[-/]?31|02[-/]?(0[1-9]|1[0-9]|2[0-8]))|([0-9]{2}(([2468][048]|[02468][48])|[13579][26])|([13579][26]|[02468][048]|0[0-9]|1[0-6])00)[-/]?02[-/]?29)$/;
    if (date_regex.test(dataNasc)) {
      var dataAtual = new Date();
      var anoAtual = dataAtual.getFullYear();
      var anoNascParts = dataNasc.split('-');
      var diaNasc = parseInt(anoNascParts[2]);
      var mesNasc = parseInt(anoNascParts[1]);
      var anoNasc = parseInt(anoNascParts[0]);
      var idade = anoAtual - anoNasc;
      var mesAtual = dataAtual.getMonth() + 1;
      //Se mes atual for menor que o nascimento, nao fez aniversario ainda;  
      if (mesAtual < mesNasc) {
        idade--;
      } else {
        //Se estiver no mes do nascimento, verificar o dia
        if (mesAtual === mesNasc) {
          if (new Date().getDate() < diaNasc) {
            //Se a data atual for menor que o dia de nascimento ele ainda nao fez aniversario
            idade--;
          }
        }
      }
      if (idade < 0) {
        return '';
      } else {
        return idade;
      }
    }
    else {
      return "";
    }
  }

  //Verificar se é menor de idade
  function verificarMenor() {
    if (req_tipo !== 4) {
      if (parseInt(req_idade) < 18 && req_representante === 0) {
        onMenorOpen();
      }
    }
  }

  //Sair da edição
  function sairEditarRequerente() {
    setEditar_requerente(0);
  }

  //Salvar Requerente Pessoa Física
  function salvarRequerentePF() {
    const data = {
      rem_id,
      req_id,
      req_tipo,
      req_representante,
      req_beneficiario,
      req_nome,
      req_nacionalidade,
      req_naturalidade,
      req_rg,
      req_emissor,
      req_outroemissor,
      req_ufemissor,
      req_cpf,
      req_sexo,
      req_nascimento,
      req_idade,
      req_pai,
      req_mae,
      req_sempai,
      req_semmae,
      req_profissao,
      req_empregador,
      req_renda,
      req_estadocivil,
      req_uniao,
      req_regime,
      req_separacao,
      req_telefone,
      req_whatsapp,
      req_email,
      req_deficiencia,
      req_identidade,
      req_motorista,
      req_trabalho,
      req_passaporte,
      req_certnascimento,
      req_decsolteiro,
      req_decuniao,
      req_certcasamento,
      req_compdivorcio,
      req_compdissolucao,
      req_certobito,
      req_folha,
      req_compaposentadoria,
      req_compbeneficio,
      req_decrenda,
      conj_estadocivil
    }
    api.post('/requerente/SalvarRequerentePF.php', JSON.stringify(data))
      .then(function (rp) {
        setCheck_reqpf(1);
        verificarMenor();
        return (toast({
          position: 'bottom-left', render: () => (<Box color='white' p={3} bg={rp.data.sucesso === true ? 'teal' : 'red'}>{rp.data.msg}</Box>),
        })
        );
      })
      .catch(function (error) {
        if (error.response.data === "EXPIRED") {
          logout();
        } else {
          console.log(error);
        }
      });
  }

  //Salvar Requerente Pessoa Física
  function salvarConjuge() {
    const data = {
      rem_id,
      req_id,
      conj_beneficiario,
      conj_nome,
      conj_nacionalidade,
      conj_naturalidade,
      conj_rg,
      conj_emissor,
      conj_outroemissor,
      conj_ufemissor,
      conj_cpf,
      conj_sexo,
      conj_nascimento,
      conj_idade,
      conj_pai,
      conj_mae,
      conj_sempai,
      conj_semmae,
      conj_profissao,
      conj_empregador,
      conj_renda,
      conj_estadocivil,
      conj_telefone,
      conj_whatsapp,
      conj_email,
      conj_deficiencia,
      conj_identidade,
      conj_motorista,
      conj_trabalho,
      conj_passaporte,
      conj_certnascimento,
      conj_decsolteiro,
      conj_decuniao,
      conj_certcasamento,
      conj_compdivorcio,
      conj_compdissolucao,
      conj_certobito,
      conj_folha,
      conj_compaposentadoria,
      conj_compbeneficio,
      conj_decrenda
    }
    api.post('/requerente/SalvarConjuge.php', JSON.stringify(data))
      .then(function (rp) {
        setCheck_conj(1);
        return (toast({
          position: 'bottom-left', render: () => (<Box color='white' p={3} bg={rp.data.sucesso === true ? 'teal' : 'red'}>{rp.data.msg}</Box>),
        })
        );
      })
      .catch(function (error) {
        if (error.response.data === "EXPIRED") {
          logout();
        } else {
          console.log(error);
        }
      });
  }

  //Salvar Requerente Pessoa Física
  function salvarResidencia() {
    const data = {
      rem_id,
      req_id,
      resid_logradouro,
      resid_numero,
      resid_complemento,
      resid_bairro,
      resid_localidade,
      resid_municipio,
      resid_uf,
      resid_cep,
      resid_energia,
      resid_agua,
      resid_iptu,
      resid_dectitular,
      resid_reqtitular,
      resid_conjtitular,
      resid_outrotitular,
      resid_titular,
      resid_cpf,
      resid_nascimento
    }
    api.post('/requerente/SalvarResidencia.php', JSON.stringify(data))
      .then(function (rp) {
        setCheck_resid(1);
        return (toast({
          position: 'bottom-left', render: () => (<Box color='white' p={3} bg={rp.data.sucesso === true ? 'teal' : 'red'}>{rp.data.msg}</Box>),
        })
        );
      })
      .catch(function (error) {
        if (error.response.data === "EXPIRED") {
          logout();
        } else {
          console.log(error);
        }
      });
  }

  //Salvar Requerente Pessoa Jurídica
  function salvarRequerentePJ() {
    const data = {
      rem_id,
      req_id,
      req_representante,
      req_beneficiario,
      req_nome,
      req_cnpj,
      req_contratosocial,
      req_inscricaoestadual,
      req_cartaocnpj,
      resid_logradouro,
      resid_numero,
      resid_complemento,
      resid_bairro,
      resid_localidade,
      resid_municipio,
      resid_uf,
      resid_cep
    }
    api.post('/requerente/SalvarRequerentePJ.php', JSON.stringify(data))
      .then(function (rp) {
        setCheck_reqpj(1);
        return (toast({
          position: 'bottom-left', render: () => (<Box color='white' p={3} bg={rp.data.sucesso === true ? 'teal' : 'red'}>{rp.data.msg}</Box>),
        })
        );
      })
      .catch(function (error) {
        if (error.response.data === "EXPIRED") {
          logout();
        } else {
          console.log(error);
        }
      });
  }

  //Salvar Observação Documental
  function salvarDocumental() {
    const data = {
      rem_id,
      req_id,
      doc_observacao
    }
    api.post('/requerente/SalvarDocumental.php', JSON.stringify(data))
      .then(function (rp) {
        onDocumentalClose();
        return (toast({
          position: 'bottom-left', render: () => (<Box color='white' p={3} bg={rp.data.sucesso === true ? 'teal' : 'red'}>{rp.data.msg}</Box>),
        })
        );
      })
      .catch(function (error) {
        if (error.response.data === "EXPIRED") {
          logout();
        } else {
          console.log(error);
        }
      });
  }

  //Renderizar
  function renderizar() {
    if (loaded === 1) {
      if (req_tipo === 4) {//Pessoa Jurídica
        return (
          <Accordion defaultIndex={[0]} allowMultiple mb={20} mt={8}>
            <AccordionItem className="requerentepf">
              <h2>
                <AccordionButton h={"60px"} _expanded={{ bg: 'blackAlpha.100' }}>
                  <Flex as="span" flex='1' textAlign='left' dir="row">
                    <Heading as='h4' size='md'>REQUERENTE</Heading>
                    <CheckIcon boxSize={5} color={'teal'} ml={3} display={check_reqpj === 1 ? 'block' : 'none'} />
                  </Flex>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel mt={4} pb={4}>
                <Heading as='h4' size='md' textAlign={'left'}>
                  Dados de Identificação
                </Heading>
                <Divider borderColor={'gray.400'} orientation='horizontal' mt={4} mb={4} />
                <Grid
                  h='80px'
                  templateRows='repeat(2, 1fr)'
                  templateColumns='repeat(12, 1fr)'
                  gap={4}
                >
                  <GridItem colSpan={4} >
                    <Stack spacing={3} direction='row'><Text>Nome</Text><Input autoComplete="no" borderColor={'gray'} maxLength={250} placeholder='Nome Completo' size='sm' value={req_nome} onChange={e => setReq_nome(e.target.value)} /></Stack>
                  </GridItem>
                  <GridItem colSpan={1}><Button colorScheme='black' variant='outline' size='sm' onClick={e => { carregarListaBuscar(id_buscar_remessa); onBuscarOpen(); }}>Buscar</Button></GridItem>
                  <GridItem colSpan={3}><Stack spacing={3} direction='row'><Text>Categoria</Text>
                    <Select borderColor={'gray'} size='sm' value={req_beneficiario} onChange={e => setReq_beneficiario(e.target.value)} >
                      <option key={1} value={1}>Beneficiário</option>
                      <option key={0} value={0}>Não Beneficiário</option>
                    </Select></Stack>
                  </GridItem>
                  <GridItem colSpan={4}><Stack spacing={3} direction='row'><Text>Representante</Text>
                    <Select borderColor={'gray'} size='sm' value={req_representante} onChange={e => setReq_representante(e.target.value)} >
                      <option key={0} value={0}></option>
                      {lista_representantes.map(item => {
                        return (<option key={item.value} value={item.value}>{item.texto}</option>);
                      })}
                    </Select></Stack>
                  </GridItem>

                  <GridItem colSpan={4} >
                    <Stack spacing={3} direction='row'><Text>CNPJ</Text><Input autoComplete="no" as={ReactInputMask} mask="99.999.999/9999-99" maskChar={null} borderColor={'gray'} size='sm' value={req_cnpj} onChange={e => setReq_cnpj(e.target.value)} /></Stack>
                  </GridItem>
                  <GridItem colSpan={1}></GridItem>
                  <GridItem colSpan={7}>
                    <Stack spacing={5} direction='row'>
                      <Text>Documento Pessoa Jurídica</Text>
                      <Checkbox borderColor={'gray'} size='md' colorScheme='teal' value={req_cartaocnpj} onChange={e => setReq_cartaocnpj(e.target.checked ? 1 : 0)} isChecked={req_cartaocnpj === 1} >Cartão do CNPJ</Checkbox>
                      <Checkbox borderColor={'gray'} size='md' colorScheme='teal' value={req_contratosocial} onChange={e => setReq_contratosocial(e.target.checked ? 1 : 0)} isChecked={req_contratosocial === 1} >Contrato Social</Checkbox>
                      <Checkbox borderColor={'gray'} size='md' colorScheme='teal' value={req_inscricaoestadual} onChange={e => setReq_inscricaoestadual(e.target.checked ? 1 : 0)} isChecked={req_inscricaoestadual === 1} >Inscrição Estadual</Checkbox>
                    </Stack>
                  </GridItem>
                </Grid>

                <Heading as='h4' size='md' textAlign={'left'} mt={'8'}>
                  Dados de Localização
                </Heading>
                <Divider borderColor={'gray.400'} orientation='horizontal' mt={4} mb={4} />
                <Grid
                  h='150px'
                  templateRows='repeat(3, 1fr)'
                  templateColumns='repeat(12, 1fr)'
                  gap={4}
                >
                  <GridItem colSpan={5}>
                    <Stack spacing={3} direction='row'><Text>Logradouro</Text><Input autoComplete="no" borderColor={'gray'} maxLength={250} placeholder='Rua, Avenida, Beco, Rodovia...' size='sm' value={resid_logradouro} onChange={e => setResid_logradouro(e.target.value)} /></Stack>
                  </GridItem>
                  <GridItem colSpan={1}><Button colorScheme='black' variant='outline' size='sm' onClick={e => pegarEndereco()}>Pegar Endereço</Button></GridItem>
                  <GridItem colSpan={2}>
                    <Stack spacing={3} direction='row'><Text>Número</Text><Input autoComplete="no" borderColor={'gray'} maxLength={11} size='sm' value={resid_numero} onChange={e => setResid_numero(e.target.value)} /></Stack>
                  </GridItem>
                  <GridItem colSpan={4}>
                    <Stack spacing={3} direction='row'><Text>Complemento</Text><Input autoComplete="no" borderColor={'gray'} maxLength={250} placeholder='Casa, Apartamento...' size='sm' value={resid_complemento} onChange={e => setResid_complemento(e.target.value)} /></Stack>
                  </GridItem>

                  <GridItem colSpan={6}>
                    <Stack spacing={3} direction='row'><Text>Bairro</Text><Input autoComplete="no" borderColor={'gray'} maxLength={250} size='sm' value={resid_bairro} onChange={e => setResid_bairro(e.target.value)} /></Stack>
                  </GridItem>
                  <GridItem colSpan={6}>
                    <Stack spacing={3} direction='row'><Text>Localidade</Text><Input autoComplete="no" borderColor={'gray'} maxLength={250} size='sm' value={resid_localidade} onChange={e => setResid_localidade(e.target.value)} /></Stack>
                  </GridItem>

                  <GridItem colSpan={7}>
                    <Stack spacing={3} direction='row'><Text>Município</Text><Input autoComplete="no" borderColor={'gray'} maxLength={250} size='sm' value={resid_municipio} onChange={e => setResid_municipio(e.target.value)} /></Stack>
                  </GridItem>
                  <GridItem colSpan={2}>
                    <Stack spacing={3} direction='row'><Text>UF</Text><Input autoComplete="no" borderColor={'gray'} maxLength={2} size='sm' value={resid_uf} onChange={e => setResid_uf(e.target.value)} /></Stack>
                  </GridItem>
                  <GridItem colSpan={3}>
                    <Stack spacing={3} direction='row'><Text>CEP</Text><Input autoComplete="no" as={ReactInputMask} mask="99.999-999" maskChar={null} borderColor={'gray'} size='sm' value={resid_cep} onChange={e => setResid_cep(e.target.value)} /></Stack>
                  </GridItem>
                </Grid>
                <Flex direction='row' p={5}>
                  <Spacer />
                  <Button colorScheme="teal" variant={'outline'} onClick={e => salvarRequerentePJ()}>Salvar Requerente <SaveIcon ml={2} /></Button>
                </Flex>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        );
      }
      else {//Pessoa Física
        return (
          <Accordion defaultIndex={[0]} allowMultiple mb={20} mt={8}>
            <AccordionItem className="requerentepf">
              <h2>
                <AccordionButton h={"60px"} _expanded={{ bg: 'blackAlpha.100' }}>
                  <Flex as="span" flex='1' textAlign='left' dir="row">
                    <Heading as='h4' size='md'>REQUERENTE</Heading>
                    <CheckIcon boxSize={5} color={'teal'} ml={3} display={check_reqpf === 1 ? 'block' : 'none'} />
                  </Flex>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel mt={4} pb={4}>
                <Heading as='h4' size='md' textAlign={'left'}>
                  Dados de Identificação
                </Heading>
                <Divider borderColor={'gray.400'} orientation='horizontal' mt={4} mb={4} />
                <Grid
                  h='140px'
                  templateRows='repeat(3, 1fr)'
                  templateColumns='repeat(12, 1fr)'
                  gap={4}
                >
                  <GridItem colSpan={4} >
                    <Stack spacing={3} direction='row'><Text>Nome</Text><Input autoComplete="no" borderColor={'gray'} maxLength={250} placeholder='Nome Completo' size='sm' value={req_nome} onChange={e => setReq_nome(e.target.value)} /></Stack>
                  </GridItem>
                  <GridItem colSpan={1}><Button colorScheme='black' variant='outline' size='sm' onClick={e => { carregarListaBuscar(id_buscar_remessa); onBuscarOpen(); }}>Buscar</Button></GridItem>
                  <GridItem colSpan={3}><Stack spacing={3} direction='row'><Text>Categoria</Text>
                    <Select borderColor={'gray'} size='sm' value={req_beneficiario} onChange={e => setReq_beneficiario(e.target.value)}>
                      <option key={1} value={1}>Beneficiário</option>
                      <option key={0} value={0}>Não Beneficiário</option>
                    </Select></Stack>
                  </GridItem>
                  <GridItem colSpan={4}><Stack spacing={3} direction='row'><Text>Representante</Text>
                    <Select borderColor={'gray'} size='sm' value={req_representante} onChange={e => setReq_representante(e.target.value)}>
                      <option key={0} value={0}></option>
                      {lista_representantes.map(item => {
                        return (<option key={item.value} value={item.value}>{item.texto}</option>);
                      })}
                    </Select></Stack>
                  </GridItem>

                  <GridItem colSpan={2}>
                    <Stack spacing={3} direction='row'><Text>CPF</Text><Input autoComplete="no" as={ReactInputMask} mask="999.999.999-99" maskChar={null} borderColor={'gray'} size='sm' value={req_cpf} onChange={e => setReq_cpf(e.target.value)} /></Stack>
                  </GridItem>
                  <GridItem colSpan={2}>
                    <Stack spacing={3} direction='row'><Text>RG</Text><Input autoComplete="no" borderColor={'gray'} maxLength={15} size='sm' value={req_rg} onChange={e => setReq_rg(e.target.value)} /></Stack>
                  </GridItem>
                  <GridItem colSpan={4}>
                    <Stack spacing={3} direction='row' ><Text whiteSpace='nowrap'>Órgão Emissor</Text><Select borderColor={'gray'} size='sm' value={req_emissor} onChange={e => setReq_emissor(e.target.value)} ><option></option>
                      {lista_emissores.map((item, i) => {
                        return (<option key={i} value={lista_sigla_emissores[i]}>{item}</option>);
                      })}
                    </Select></Stack>
                  </GridItem>
                  <GridItem colSpan={3}>
                    <Stack spacing={3} direction='row'><Input disabled={req_emissor === 'OUTRO' ? 0 : 1} autoComplete="no" borderColor={'gray'} maxLength={100} size='sm' placeholder='Nome do outro órgão emissor...' value={req_outroemissor} onChange={e => setReq_outroemissor(e.target.value)} /></Stack>
                  </GridItem>
                  <GridItem colSpan={1}>
                    <Stack spacing={3} direction='row'><Text>UF</Text><Input autoComplete="no" borderColor={'gray'} maxLength={2} size='sm' value={req_ufemissor} onChange={e => setReq_ufemissor(e.target.value.toUpperCase())} /></Stack>
                  </GridItem>

                  <GridItem colSpan={4}>
                    <Stack spacing={3} direction='row'>
                      <Text>Sexo</Text>
                      <Checkbox borderColor={'gray'} size='md' colorScheme='teal' onChange={e => setReq_sexo(e.target.checked ? 'M' : '')} isChecked={req_sexo === 'M'}>Masculino</Checkbox>
                      <Checkbox borderColor={'gray'} size='md' colorScheme='teal' onChange={e => setReq_sexo(e.target.checked ? 'F' : '')} isChecked={req_sexo === 'F'}>Feminino</Checkbox>
                    </Stack>
                  </GridItem>
                  <GridItem colSpan={8}>
                    <Stack spacing={3} direction='row'>
                      <Text>Documento Pessoal</Text>
                      <Checkbox borderColor={'gray'} size='md' colorScheme='teal' onChange={e => setReq_identidade(e.target.checked ? 1 : 0)} isChecked={req_identidade === 1}>Identidade</Checkbox>
                      <Checkbox borderColor={'gray'} size='md' colorScheme='teal' onChange={e => setReq_motorista(e.target.checked ? 1 : 0)} isChecked={req_motorista === 1}>Carteira de Motorista</Checkbox>
                      <Checkbox borderColor={'gray'} size='md' colorScheme='teal' onChange={e => setReq_trabalho(e.target.checked ? 1 : 0)} isChecked={req_trabalho === 1}>Carteira de Trabalho</Checkbox>
                      <Checkbox borderColor={'gray'} size='md' colorScheme='teal' onChange={e => setReq_passaporte(e.target.checked ? 1 : 0)} isChecked={req_passaporte === 1}>Passaporte</Checkbox>
                    </Stack>
                  </GridItem>
                </Grid>

                <Heading as='h4' size='md' textAlign={'left'} mt={5}>
                  Dados de Nascimento
                </Heading>
                <Divider borderColor={'gray.400'} orientation='horizontal' mt={4} mb={4} />
                <Grid
                  h='80px'
                  templateRows='repeat(2, 1fr)'
                  templateColumns='repeat(24, 1fr)'
                  gap={4}
                >

                  <GridItem colSpan={7}>
                    <Stack spacing={3} direction='row'><Text>Nacionalidade</Text><Input autoComplete="no" borderColor={'gray'} maxLength={50} size='sm' value={req_nacionalidade} onChange={e => setReq_nacionalidade(e.target.value)} /></Stack>
                  </GridItem>
                  <GridItem colSpan={8}>
                    <Stack spacing={3} direction='row'><Text>Naturalidade</Text><Input autoComplete="no" borderColor={'gray'} maxLength={100} size='sm' value={req_naturalidade} onChange={e => setReq_naturalidade(e.target.value)} /></Stack>
                  </GridItem>
                  <GridItem colSpan={6}>
                    <Stack spacing={3} direction='row'><Text whiteSpace='nowrap'>Data de Nascimento</Text><Input autoComplete="no" borderColor={'gray'} type='date' size='sm' value={req_nascimento} onChange={e => { setReq_nascimento(e.target.value); setReq_idade(CalcularIdade(e.target.value)); }} /></Stack>
                  </GridItem>
                  <GridItem colSpan={3}>
                    <Stack spacing={3} direction='row'><Text>Idade</Text><Input autoComplete="no" borderColor={'gray'} size='sm' value={req_idade} disabled /></Stack>
                  </GridItem>

                  <GridItem colSpan={8}>
                    <Stack spacing={3} direction='row'><Text>Pai</Text><Input disabled={req_sempai === 1 ? 1 : 0} autoComplete="no" borderColor={'gray'} maxLength={250} placeholder='Nome Completo' size='sm' value={req_pai} onChange={e => setReq_pai(e.target.value)} /></Stack>
                  </GridItem>
                  <GridItem colSpan={4}>
                    <Checkbox borderColor={'gray'} size='md' colorScheme='teal' onChange={e => setReq_sempai(e.target.checked ? 1 : 0)} isChecked={req_sempai === 1}>Pai Não Registrado</Checkbox>
                  </GridItem>
                  <GridItem colSpan={8}>
                    <Stack spacing={3} direction='row'><Text>Mãe</Text><Input disabled={req_semmae === 1 ? 1 : 0} autoComplete="no" borderColor={'gray'} maxLength={250} placeholder='Nome Completo' size='sm' value={req_mae} onChange={e => setReq_mae(e.target.value)} /></Stack>
                  </GridItem>
                  <GridItem colSpan={4}>
                    <Checkbox borderColor={'gray'} size='md' colorScheme='teal' onChange={e => setReq_semmae(e.target.checked ? 1 : 0)} isChecked={req_semmae === 1}>Mãe Não Registrada</Checkbox>
                  </GridItem>
                </Grid>

                <Heading as='h4' size='md' textAlign={'left'} mt={8}>
                  Dados de Estado Civil
                </Heading>
                <Divider borderColor={'gray.400'} orientation='horizontal' mt={4} mb={4} />
                <Grid
                  h='150px'
                  templateRows='repeat(1, 1fr)'
                  templateColumns='repeat(12, 1fr)'
                  gap={4}
                >
                  <GridItem colSpan={6}>
                    <Stack spacing={3} direction='row'><Text whiteSpace='nowrap'>Estado Civil</Text>
                      <Select borderColor={'gray'} size='sm' value={req_estadocivil} onChange={e => setReq_estadocivil(e.target.value)}>
                        <option key={0}></option>
                        <option key={1}>Casado(a)</option>
                        <option key={2}>Solteiro(a)</option>
                        <option key={3}>Solteiro(a) com Dissolução</option>
                        <option key={4}>Solteiro(a) em União Estável</option>
                        <option key={5}>Divorciado(a)</option>
                        <option key={6}>Divorciado(a) em União Estável</option>
                        <option key={7}>Separado(a)</option>
                        <option key={8}>Separado(a) em União Estável</option>
                        <option key={9}>Viúvo(a)</option>
                        <option key={10}>Viúvo(a) em União Estável</option>
                      </Select>
                    </Stack>
                  </GridItem>
                  <GridItem colSpan={3}>
                    <Stack spacing={3} direction='row'><Text whiteSpace='nowrap'>Data da União</Text><Input autoComplete="no" borderColor={'gray'} type='date' size='sm' value={req_uniao} onChange={e => setReq_uniao(e.target.value)} /></Stack>
                  </GridItem>
                  <GridItem colSpan={3}>
                    <Stack spacing={3} direction='row'><Text whiteSpace='nowrap'>Data da Separação</Text><Input autoComplete="no" borderColor={'gray'} type='date' size='sm' value={req_separacao} onChange={e => setReq_separacao(e.target.value)} /></Stack>
                  </GridItem>

                  <GridItem colSpan={4}>
                    <Text>Regime de Bens</Text>
                    <Stack spacing={10} direction='row'>
                      <Stack spacing={1} direction='column'>
                        <Checkbox borderColor={'gray'} size='md' colorScheme='teal' onChange={e => setReq_regime(e.target.checked ? 'Comunhão Universal' : '')} isChecked={req_regime === 'Comunhão Universal'}>Comunhão Universal</Checkbox>
                        <Checkbox borderColor={'gray'} size='md' colorScheme='teal' onChange={e => setReq_regime(e.target.checked ? 'Comunhão Parcial' : '')} isChecked={req_regime === 'Comunhão Parcial'}>Comunhão Parcial</Checkbox>
                      </Stack>
                      <Stack spacing={1} direction='column'>
                        <Checkbox borderColor={'gray'} size='md' colorScheme='teal' onChange={e => setReq_regime(e.target.checked ? 'Separação Total' : '')} isChecked={req_regime === 'Separação Total'}>Separação Total</Checkbox>
                        <Checkbox borderColor={'gray'} size='md' colorScheme='teal' onChange={e => setReq_regime(e.target.checked ? 'Não Definido' : '')} isChecked={req_regime === 'Não Definido'}>Não Definido</Checkbox>
                      </Stack>
                    </Stack>
                  </GridItem>
                  <GridItem colSpan={8}><Text>Comprovante de Estado Civil</Text>
                    <Grid
                      h='50px'
                      templateRows='repeat(1, 1fr)'
                      templateColumns='repeat(3, 1fr)'
                      gap={1}
                    >
                      <GridItem colSpan={1}>
                        <Flex direction={'column'}>
                          <Checkbox mt={1} borderColor={'gray'} size='md' colorScheme='teal' value={req_decsolteiro} onChange={e => setReq_decsolteiro(e.target.checked ? 1 : 0)} isChecked={req_decsolteiro === 1} >Declaração de Solteiro</Checkbox>
                          <Checkbox mt={1} borderColor={'gray'} size='md' colorScheme='teal' value={req_certnascimento} onChange={e => setReq_certnascimento(e.target.checked ? 1 : 0)} isChecked={req_certnascimento === 1} >Certidão de Nascimento</Checkbox>
                          <Checkbox mt={1} borderColor={'gray'} size='md' colorScheme='teal' value={req_certcasamento} onChange={e => setReq_certcasamento(e.target.checked ? 1 : 0)} isChecked={req_certcasamento === 1} >Certidão de Casamento</Checkbox>
                        </Flex>
                      </GridItem>
                      <GridItem colSpan={1}>
                        <Flex direction={'column'}>
                          <Checkbox mt={1} borderColor={'gray'} size='md' colorScheme='teal' value={req_decuniao} onChange={e => setReq_decuniao(e.target.checked ? 1 : 0)} isChecked={req_decuniao === 1} >Declaração de União</Checkbox>
                          <Checkbox mt={1} borderColor={'gray'} size='md' colorScheme='teal' value={req_compdivorcio} onChange={e => setReq_compdivorcio(e.target.checked ? 1 : 0)} isChecked={req_compdivorcio === 1} >Certidão de Divórcio</Checkbox>
                          <Checkbox mt={1} borderColor={'gray'} size='md' colorScheme='teal' value={req_compdissolucao} onChange={e => setReq_compdissolucao(e.target.checked ? 1 : 0)} isChecked={req_compdissolucao === 1} >Certidão de Dissolução</Checkbox>
                        </Flex>
                      </GridItem>
                      <GridItem colSpan={1}>
                        <Flex direction={'column'}>
                          <Checkbox mt={1} borderColor={'gray'} size='md' colorScheme='teal' value={req_certobito} onChange={e => setReq_certobito(e.target.checked ? 1 : 0)} isChecked={req_certobito === 1} >Certidão de Óbito do Cônjuge</Checkbox>
                        </Flex>
                      </GridItem>
                    </Grid>
                  </GridItem>
                </Grid>

                <Heading as='h4' size='md' textAlign={'left'} mt={12}>
                  Dados de Profissão e Renda
                </Heading>
                <Divider borderColor={'gray.400'} orientation='horizontal' mt={4} mb={4} />
                <Grid
                  h='120px'
                  templateRows='repeat(3, 1fr)'
                  templateColumns='repeat(12, 1fr)'
                  gap={4}
                >
                  <GridItem colSpan={6}>
                    <Stack spacing={3} direction='row'><Text>Profissão</Text><Input autoComplete="no" borderColor={'gray'} maxLength={50} placeholder='Atividade, Desempregado, Estudante...' size='sm' value={req_profissao} onChange={e => setReq_profissao(e.target.value)} /></Stack>
                  </GridItem>
                  <GridItem colSpan={6}>
                    <Stack spacing={3} direction='row'><Text>Empregador</Text><Input autoComplete="no" borderColor={'gray'} maxLength={100} size='sm' placeholder="Nome da empresa ou instituição empregadora" value={req_empregador} onChange={e => setReq_empregador(e.target.value)} /></Stack>
                  </GridItem>

                  <GridItem colSpan={4}>
                    <Stack spacing={3} direction='row'><Text whiteSpace='nowrap'>Renda Mensal</Text><Input autoComplete="no" size='sm' borderColor={'gray'} maxLength={11} placeholder='R$/mês' value={req_renda} onChange={e => setReq_renda(e.target.value)} />
                    </Stack>
                  </GridItem>
                  <GridItem colSpan={8}>
                    <Checkbox borderColor={'gray'} size='md' colorScheme='teal' whiteSpace='nowrap' onChange={e => setReq_deficiencia(e.target.checked ? 1 : 0)} isChecked={req_deficiencia === 1}>Possui Deficiência Incapacitante</Checkbox>
                  </GridItem>

                  <GridItem colSpan={12}>
                    <Stack spacing={3} direction='row'>
                      <Text>Comprovante de Renda</Text>
                      <Checkbox borderColor={'gray'} size='md' colorScheme='teal' onChange={e => setReq_folha(e.target.checked ? 1 : 0)} isChecked={req_folha === 1} >Folha de Pagamento</Checkbox>
                      <Checkbox borderColor={'gray'} size='md' colorScheme='teal' onChange={e => setReq_compaposentadoria(e.target.checked ? 1 : 0)} isChecked={req_compaposentadoria === 1} >Comprovante de Aposentadoria</Checkbox>
                      <Checkbox borderColor={'gray'} size='md' colorScheme='teal' onChange={e => setReq_compbeneficio(e.target.checked ? 1 : 0)} isChecked={req_compbeneficio === 1} >Comprovante de Benefício</Checkbox>
                      <Checkbox borderColor={'gray'} size='md' colorScheme='teal' onChange={e => setReq_decrenda(e.target.checked ? 1 : 0)} isChecked={req_decrenda === 1} >Declaração de Renda</Checkbox>
                    </Stack>
                  </GridItem>
                </Grid>

                <Heading as='h4' size='md' textAlign={'left'} mt={8}>
                  Dados de Contato
                </Heading>
                <Divider borderColor={'gray.400'} orientation='horizontal' mt={4} mb={4} />
                <Grid
                  h='40px'
                  templateRows='repeat(1, 1fr)'
                  templateColumns='repeat(12, 1fr)'
                  gap={4}
                >
                  <GridItem colSpan={4}>
                    <Stack spacing={3} direction='row'><Text>Telefone</Text><Input autoComplete="no" as={ReactInputMask} mask="(99) 99999-9999" maskChar={null} borderColor={'gray'} type='tel' size='sm' value={req_telefone} onChange={e => setReq_telefone(e.target.value)} /></Stack>
                  </GridItem>
                  <GridItem colSpan={2}>
                    <Checkbox borderColor={'gray'} size='md' colorScheme='teal' value={req_whatsapp} onChange={e => setReq_whatsapp(e.target.checked ? 1 : 0)} isChecked={req_whatsapp === 1} >Possui Whatsapp</Checkbox>
                  </GridItem>
                  <GridItem colSpan={6}>
                    <Stack spacing={3} direction='row'><Text whiteSpace='nowrap'>E-mail</Text><Input autoComplete="no" borderColor={'gray'} maxLength={100} size='sm' value={req_email} onChange={e => setReq_email(e.target.value)} /></Stack>
                  </GridItem>
                </Grid>

                <Flex direction='row' p={5}>
                  <Spacer />
                  <Button colorScheme="teal" variant={'outline'} onClick={e => salvarRequerentePF()}>Salvar Requerente<SaveIcon ml={2} /></Button>
                </Flex>

              </AccordionPanel>

            </AccordionItem>

            <AccordionItem className="conjuge" display={habilitar_conjuge ? 'block' : 'none'}>
              <h2>
                <AccordionButton h={"60px"} _expanded={{ bg: 'blackAlpha.100' }}>
                  <Flex as="span" flex='1' textAlign='left' dir="row">
                    <Heading as='h4' size='md'>CÔNJUGE</Heading>
                    <CheckIcon boxSize={5} color={'teal'} ml={3} display={check_conj === 1 ? 'block' : 'none'} />
                  </Flex>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel mt={4} pb={4}>
                <Heading as='h4' size='md' textAlign={'left'}>
                  Dados de Identificação
                </Heading>
                <Divider borderColor={'gray.400'} orientation='horizontal' mt={4} mb={4} />
                <Grid
                  h='140px'
                  templateRows='repeat(3, 1fr)'
                  templateColumns='repeat(12, 1fr)'
                  gap={4}
                >
                  <GridItem colSpan={6} >
                    <Stack spacing={3} direction='row'><Text>Nome</Text><Input autoComplete="no" borderColor={'gray'} maxLength={250} placeholder='Nome Completo' size='sm' value={conj_nome} onChange={e => setConj_nome(e.target.value)} /></Stack>
                  </GridItem>
                  <GridItem colSpan={6}><Stack spacing={3} direction='row'><Text>Categoria</Text>
                    <Select borderColor={'gray'} size='sm' value={conj_beneficiario} onChange={e => setConj_beneficiario(e.target.value)} >
                      <option key={1} value={1}>Beneficiário</option>
                      <option key={0} value={0}>Não Beneficiário</option>
                    </Select></Stack>
                  </GridItem>

                  <GridItem colSpan={2}>
                    <Stack spacing={3} direction='row'><Text>CPF</Text><Input autoComplete="no" as={ReactInputMask} mask="999.999.999-99" maskChar={null} borderColor={'gray'} size='sm' value={conj_cpf} onChange={e => setConj_cpf(e.target.value)} /></Stack>
                  </GridItem>
                  <GridItem colSpan={2}>
                    <Stack spacing={3} direction='row'><Text>RG</Text><Input autoComplete="no" borderColor={'gray'} maxLength={15} size='sm' value={conj_rg} onChange={e => setConj_rg(e.target.value)} /></Stack>
                  </GridItem>
                  <GridItem colSpan={4}>
                    <Stack spacing={3} direction='row' ><Text whiteSpace='nowrap'>Órgão Emissor</Text><Select borderColor={'gray'} size='sm' value={conj_emissor} onChange={e => setConj_emissor(e.target.value)} ><option></option>
                      {lista_emissores.map((item, i) => {
                        return (<option key={i} value={lista_sigla_emissores[i]}>{item}</option>);
                      })}
                    </Select></Stack>
                  </GridItem>
                  <GridItem colSpan={3}>
                    <Stack spacing={3} direction='row'><Input disabled={conj_emissor === 'OUTRO' ? 0 : 1} autoComplete="no" borderColor={'gray'} maxLength={100} size='sm' placeholder='Nome do outro órgão emissor...' value={conj_outroemissor} onChange={e => setConj_outroemissor(e.target.value)} /></Stack>
                  </GridItem>
                  <GridItem colSpan={1}>
                    <Stack spacing={3} direction='row'><Text>UF</Text><Input autoComplete="no" borderColor={'gray'} maxLength={2} size='sm' value={conj_ufemissor} onChange={e => setConj_ufemissor(e.target.value.toUpperCase())} /></Stack>
                  </GridItem>

                  <GridItem colSpan={4}>
                    <Stack spacing={3} direction='row'>
                      <Text>Sexo</Text>
                      <Checkbox borderColor={'gray'} size='md' colorScheme='teal' onChange={e => setConj_sexo(e.target.checked ? 'M' : '')} isChecked={conj_sexo === 'M'}>Masculino</Checkbox>
                      <Checkbox borderColor={'gray'} size='md' colorScheme='teal' onChange={e => setConj_sexo(e.target.checked ? 'F' : '')} isChecked={conj_sexo === 'F'}>Feminino</Checkbox>
                    </Stack>
                  </GridItem>
                  <GridItem colSpan={8}>
                    <Stack spacing={3} direction='row'>
                      <Text>Documento Pessoal</Text>
                      <Checkbox borderColor={'gray'} size='md' colorScheme='teal' value={conj_identidade} onChange={e => setConj_identidade(e.target.checked ? 1 : 0)} isChecked={conj_identidade === 1} >Identidade</Checkbox>
                      <Checkbox borderColor={'gray'} size='md' colorScheme='teal' value={conj_motorista} onChange={e => setConj_motorista(e.target.checked ? 1 : 0)} isChecked={conj_motorista === 1} >Carteira de Motorista</Checkbox>
                      <Checkbox borderColor={'gray'} size='md' colorScheme='teal' value={conj_trabalho} onChange={e => setConj_trabalho(e.target.checked ? 1 : 0)} isChecked={conj_trabalho === 1} >Carteira de Trabalho</Checkbox>
                      <Checkbox borderColor={'gray'} size='md' colorScheme='teal' value={conj_passaporte} onChange={e => setConj_passaporte(e.target.checked ? 1 : 0)} isChecked={conj_passaporte === 1} >Passaporte</Checkbox>
                    </Stack>
                  </GridItem>

                </Grid>

                <Heading as='h4' size='md' textAlign={'left'} mt={5}>
                  Dados de Nascimento
                </Heading>
                <Divider borderColor={'gray.400'} orientation='horizontal' mt={4} mb={4} />
                <Grid
                  h='80px'
                  templateRows='repeat(2, 1fr)'
                  templateColumns='repeat(24, 1fr)'
                  gap={4}
                >
                  <GridItem colSpan={7}>
                    <Stack spacing={3} direction='row'><Text>Nacionalidade</Text><Input autoComplete="no" borderColor={'gray'} maxLength={50} size='sm' value={conj_nacionalidade} onChange={e => setConj_nacionalidade(e.target.value)} /></Stack>
                  </GridItem>
                  <GridItem colSpan={8}>
                    <Stack spacing={3} direction='row'><Text>Naturalidade</Text><Input autoComplete="no" borderColor={'gray'} maxLength={100} size='sm' value={conj_naturalidade} onChange={e => setConj_naturalidade(e.target.value)} /></Stack>
                  </GridItem>
                  <GridItem colSpan={6}>
                    <Stack spacing={3} direction='row'><Text whiteSpace='nowrap'>Data de Nascimento</Text><Input autoComplete="no" borderColor={'gray'} type='date' size='sm' value={conj_nascimento} onChange={e => { setConj_nascimento(e.target.value); setConj_idade(CalcularIdade(e.target.value)); }} /></Stack>
                  </GridItem>
                  <GridItem colSpan={3}>
                    <Stack spacing={3} direction='row'><Text>Idade</Text><Input autoComplete="no" borderColor={'gray'} size='sm' value={conj_idade} disabled /></Stack>
                  </GridItem>

                  <GridItem colSpan={8}>
                    <Stack spacing={3} direction='row'><Text>Pai</Text><Input disabled={conj_sempai === 1 ? 1 : 0} autoComplete="no" borderColor={'gray'} maxLength={250} placeholder='Nome Completo' size='sm' value={conj_pai} onChange={e => setConj_pai(e.target.value)} /></Stack>
                  </GridItem>
                  <GridItem colSpan={4}>
                    <Checkbox borderColor={'gray'} size='md' colorScheme='teal' onChange={e => setConj_sempai(e.target.checked ? 1 : 0)} isChecked={conj_sempai === 1}>Pai Não Registrado</Checkbox>
                  </GridItem>
                  <GridItem colSpan={8}>
                    <Stack spacing={3} direction='row'><Text>Mãe</Text><Input disabled={conj_semmae === 1 ? 1 : 0} autoComplete="no" borderColor={'gray'} maxLength={250} placeholder='Nome Completo' size='sm' value={conj_mae} onChange={e => setConj_mae(e.target.value)} /></Stack>
                  </GridItem>
                  <GridItem colSpan={4}>
                    <Checkbox borderColor={'gray'} size='md' colorScheme='teal' onChange={e => setConj_semmae(e.target.checked ? 1 : 0)} isChecked={conj_semmae === 1}>Mãe Não Registrada</Checkbox>
                  </GridItem>
                </Grid>

                <Heading as='h4' size='md' textAlign={'left'} mt={8}>
                  Dados de Estado Civil
                </Heading>
                <Divider borderColor={'gray.400'} orientation='horizontal' mt={4} mb={4} />
                <Grid
                  h='60px'
                  templateRows='repeat(1, 1fr)'
                  templateColumns='repeat(12, 1fr)'
                  gap={4}
                >
                  <GridItem colSpan={4}>
                    <Stack spacing={3} direction='row'><Text whiteSpace='nowrap'>Estado Civil</Text>
                      <Select borderColor={'gray'} size='sm' value={conj_estadocivil} onChange={e => setConj_estadocivil(e.target.value)} isDisabled={req_estadocivil === 'Casado(a)' ? true : false}>
                        <option key={0}></option>
                        <option key={1}>Casado(a)</option>
                        <option key={2}>Solteiro(a) em União Estável</option>
                        <option key={3}>Divorciado(a) em União Estável</option>
                        <option key={4}>Separado(a) em União Estável</option>
                        <option key={5}>Viúvo(a) em União Estável</option>
                      </Select>
                    </Stack>
                  </GridItem>

                  <GridItem colSpan={8} pl={10}>

                    <Text whiteSpace='nowrap'>Comprovante de Estado Civil</Text>
                    <Grid
                      h='50px'
                      templateRows='repeat(1, 1fr)'
                      templateColumns='repeat(4, 1fr)'
                      gap={1}
                    >
                      <GridItem colSpan={1}>
                        <Flex direction={'column'}>
                          <Checkbox mt={1} borderColor={'gray'} size='md' colorScheme='teal' value={conj_decsolteiro} onChange={e => setconj_decsolteiro(e.target.checked ? 1 : 0)} isChecked={conj_decsolteiro === 1} >Declaração de Solteiro</Checkbox>
                          <Checkbox mt={1} borderColor={'gray'} size='md' colorScheme='teal' value={conj_certnascimento} onChange={e => setconj_certnascimento(e.target.checked ? 1 : 0)} isChecked={conj_certnascimento === 1} >Certidão de Nascimento</Checkbox>
                        </Flex>
                      </GridItem>
                      <GridItem colSpan={1}>
                        <Flex direction={'column'}>
                          <Checkbox mt={1} borderColor={'gray'} size='md' colorScheme='teal' value={conj_certcasamento} onChange={e => setconj_certcasamento(e.target.checked ? 1 : 0)} isChecked={conj_certcasamento === 1} >Certidão de Casamento</Checkbox>
                          <Checkbox mt={1} borderColor={'gray'} size='md' colorScheme='teal' value={conj_decuniao} onChange={e => setconj_decuniao(e.target.checked ? 1 : 0)} isChecked={conj_decuniao === 1} >Declaração de União</Checkbox>
                        </Flex>
                      </GridItem>
                      <GridItem colSpan={1}>
                        <Flex direction={'column'}>
                          <Checkbox mt={1} borderColor={'gray'} size='md' colorScheme='teal' value={conj_compdivorcio} onChange={e => setconj_compdivorcio(e.target.checked ? 1 : 0)} isChecked={conj_compdivorcio === 1} >Certidão de Divórcio</Checkbox>
                          <Checkbox mt={1} borderColor={'gray'} size='md' colorScheme='teal' value={conj_compdissolucao} onChange={e => setconj_compdissolucao(e.target.checked ? 1 : 0)} isChecked={conj_compdissolucao === 1} >Certidão de Dissolução</Checkbox>
                        </Flex>
                      </GridItem>
                      <GridItem colSpan={1}>
                        <Flex direction={'column'}>
                          <Checkbox mt={1} borderColor={'gray'} size='md' colorScheme='teal' value={conj_certobito} onChange={e => setConj_certobito(e.target.checked ? 1 : 0)} isChecked={conj_certobito === 1} >Certidão de Óbito</Checkbox>
                        </Flex>
                      </GridItem>

                    </Grid>
                  </GridItem>

                </Grid>

                <Heading as='h4' size='md' textAlign={'left'} mt={8}>
                  Dados de Profissão e Renda
                </Heading>
                <Divider borderColor={'gray.400'} orientation='horizontal' mt={4} mb={4} />
                <Grid
                  h='120px'
                  templateRows='repeat(3, 1fr)'
                  templateColumns='repeat(12, 1fr)'
                  gap={4}
                >
                  <GridItem colSpan={6}>
                    <Stack spacing={3} direction='row'><Text>Profissão</Text><Input autoComplete="no" borderColor={'gray'} maxLength={50} placeholder='Atividade, Desempregado, Estudante...' size='sm' value={conj_profissao} onChange={e => setConj_profissao(e.target.value)} /></Stack>
                  </GridItem>
                  <GridItem colSpan={6}>
                    <Stack spacing={3} direction='row'><Text>Empregador</Text><Input autoComplete="no" borderColor={'gray'} maxLength={100} size='sm' placeholder="Nome da empresa ou instituição empregadora" value={conj_empregador} onChange={e => setConj_empregador(e.target.value)} /></Stack>
                  </GridItem>

                  <GridItem colSpan={4}>
                    <Stack spacing={3} direction='row'><Text whiteSpace='nowrap'>Renda Mensal</Text><Input autoComplete="no" borderColor={'gray'} maxLength={11} placeholder='R$/mês' size='sm' value={conj_renda} onChange={e => setConj_renda(e.target.value)} /></Stack>
                  </GridItem>
                  <GridItem colSpan={8}>
                    <Checkbox borderColor={'gray'} size='md' colorScheme='teal' whiteSpace='nowrap' value={conj_deficiencia} onChange={e => setConj_deficiencia(e.target.checked ? 1 : 0)} isChecked={conj_deficiencia === 1} >Possui Deficiência Incapacitante</Checkbox>
                  </GridItem>

                  <GridItem colSpan={12}>
                    <Stack spacing={3} direction='row'>
                      <Text>Comprovante de Renda</Text>
                      <Checkbox borderColor={'gray'} size='md' colorScheme='teal' value={conj_folha} onChange={e => setConj_folha(e.target.checked ? 1 : 0)} isChecked={conj_folha === 1} >Folha de Pagamento</Checkbox>
                      <Checkbox borderColor={'gray'} size='md' colorScheme='teal' value={conj_compaposentadoria} onChange={e => setConj_compaposentadoria(e.target.checked ? 1 : 0)} isChecked={conj_compaposentadoria === 1} >Comprovante de Aposentadoria</Checkbox>
                      <Checkbox borderColor={'gray'} size='md' colorScheme='teal' value={conj_compbeneficio} onChange={e => setConj_compbeneficio(e.target.checked ? 1 : 0)} isChecked={conj_compbeneficio === 1} >Comprovante de Benefício</Checkbox>
                      <Checkbox borderColor={'gray'} size='md' colorScheme='teal' value={conj_decrenda} onChange={e => setConj_decrenda(e.target.checked ? 1 : 0)} isChecked={conj_decrenda === 1} >Declaração de Renda</Checkbox>
                    </Stack>
                  </GridItem>
                </Grid>

                <Heading as='h4' size='md' textAlign={'left'} mt={8}>
                  Dados de Contato
                </Heading>
                <Divider borderColor={'gray.400'} orientation='horizontal' mt={4} mb={4} />
                <Grid
                  h='40px'
                  templateRows='repeat(1, 1fr)'
                  templateColumns='repeat(12, 1fr)'
                  gap={4}
                >
                  <GridItem colSpan={4}>
                    <Stack spacing={3} direction='row'><Text>Telefone</Text><Input autoComplete="no" as={ReactInputMask} mask="(99) 99999-9999" maskChar={null} borderColor={'gray'} type='tel' size='sm' value={conj_telefone} onChange={e => setConj_telefone(e.target.value)} /></Stack>
                  </GridItem>
                  <GridItem colSpan={2}>
                    <Checkbox borderColor={'gray'} size='md' colorScheme='teal' value={conj_whatsapp} onChange={e => setConj_whatsapp(e.target.checked ? 1 : 0)} isChecked={conj_whatsapp === 1} >Whatsapp</Checkbox>
                  </GridItem>
                  <GridItem colSpan={6}>
                    <Stack spacing={3} direction='row'><Text whiteSpace='nowrap'>E-mail</Text><Input autoComplete="no" borderColor={'gray'} maxLength={100} size='sm' value={conj_email} onChange={e => setConj_email(e.target.value)} /></Stack>
                  </GridItem>
                </Grid>

                <Flex direction='row' p={5}>
                  <Spacer />
                  <Button colorScheme="teal" variant={'outline'} onClick={e => salvarConjuge()}>Salvar Cônjuge<SaveIcon ml={2} /></Button>
                </Flex>

              </AccordionPanel>

            </AccordionItem>

            <AccordionItem className="residencia">
              <h2>
                <AccordionButton h={"60px"} _expanded={{ bg: 'blackAlpha.100' }}>
                  <Flex as="span" flex='1' textAlign='left' dir="row">
                    <Heading as='h4' size='md'>RESIDÊNCIA</Heading>
                    <CheckIcon boxSize={5} color={'teal'} ml={3} display={check_resid === 1 ? 'block' : 'none'} />
                  </Flex>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel mt={4} pb={4}>
                <Heading as='h4' size='md' textAlign={'left'}>
                  Dados de Localização
                </Heading>
                <Divider borderColor={'gray.400'} orientation='horizontal' mt={4} mb={4} />
                <Grid
                  h='200px'
                  templateRows='repeat(5, 1fr)'
                  templateColumns='repeat(12, 1fr)'
                  gap={4}
                >
                  <GridItem colSpan={5}>
                    <Stack spacing={3} direction='row'><Text>Logradouro</Text><Input autoComplete="no" borderColor={'gray'} maxLength={250} placeholder='Rua, Avenida, Beco, Rodovia...' size='sm' value={resid_logradouro} onChange={e => setResid_logradouro(e.target.value)} /></Stack>
                  </GridItem>
                  <GridItem colSpan={1}><Button colorScheme='black' variant='outline' size='sm' onClick={e => pegarEndereco()}>Pegar Endereço</Button></GridItem>
                  <GridItem colSpan={2}>
                    <Stack spacing={3} direction='row'><Text>Número</Text><Input autoComplete="no" borderColor={'gray'} maxLength={11} size='sm' value={resid_numero} onChange={e => setResid_numero(e.target.value)} /></Stack>
                  </GridItem>
                  <GridItem colSpan={4}>
                    <Stack spacing={3} direction='row'><Text>Complemento</Text><Input autoComplete="no" borderColor={'gray'} maxLength={250} placeholder='Casa, Apartamento...' size='sm' value={resid_complemento} onChange={e => setResid_complemento(e.target.value)} /></Stack>
                  </GridItem>

                  <GridItem colSpan={6}>
                    <Stack spacing={3} direction='row'><Text>Bairro</Text><Input autoComplete="no" borderColor={'gray'} maxLength={250} size='sm' value={resid_bairro} onChange={e => setResid_bairro(e.target.value)} /></Stack>
                  </GridItem>
                  <GridItem colSpan={6}>
                    <Stack spacing={3} direction='row'><Text>Localidade</Text><Input autoComplete="no" borderColor={'gray'} maxLength={250} size='sm' value={resid_localidade} onChange={e => setResid_localidade(e.target.value)} /></Stack>
                  </GridItem>

                  <GridItem colSpan={7}>
                    <Stack spacing={3} direction='row'><Text>Município</Text><Input autoComplete="no" borderColor={'gray'} maxLength={250} size='sm' value={resid_municipio} onChange={e => setResid_municipio(e.target.value)} /></Stack>
                  </GridItem>
                  <GridItem colSpan={2}>
                    <Stack spacing={3} direction='row'><Text>UF</Text><Input autoComplete="no" borderColor={'gray'} maxLength={2} size='sm' value={resid_uf} onChange={e => setResid_uf(e.target.value)} /></Stack>
                  </GridItem>
                  <GridItem colSpan={3}>
                    <Stack spacing={3} direction='row'><Text>CEP</Text><Input autoComplete="no" as={ReactInputMask} mask="99.999-999" maskChar={null} borderColor={'gray'} size='sm' value={resid_cep} onChange={e => setResid_cep(e.target.value)} /></Stack>
                  </GridItem>

                  <GridItem colSpan={12}>
                    <Stack spacing={5} direction='row'>
                      <Text>Comprovante de Residência</Text>
                      <Checkbox borderColor={'gray'} size='md' colorScheme='teal' value={resid_energia} onChange={e => setResid_energia(e.target.checked ? 1 : 0)} isChecked={resid_energia === 1} >Conta de Energia</Checkbox>
                      <Checkbox borderColor={'gray'} size='md' colorScheme='teal' value={resid_agua} onChange={e => setResid_agua(e.target.checked ? 1 : 0)} isChecked={resid_agua === 1} >Conta de Água</Checkbox>
                      <Checkbox borderColor={'gray'} size='md' colorScheme='teal' value={resid_iptu} onChange={e => setResid_iptu(e.target.checked ? 1 : 0)} isChecked={resid_iptu === 1} >Conta de IPTU</Checkbox>
                      <Checkbox borderColor={'gray'} size='md' colorScheme='teal' value={resid_dectitular} onChange={e => setResid_dectitular(e.target.checked ? 1 : 0)} isChecked={resid_dectitular === 1} >Declaração de Outro Titular</Checkbox>
                    </Stack>
                  </GridItem>
                </Grid>

                <Heading as='h4' size='md' textAlign={'left'}>
                  Dados do Titular
                </Heading>
                <Divider borderColor={'gray.400'} orientation='horizontal' mt={4} mb={4} />
                <Grid
                  h='40px'
                  templateRows='repeat(1, 1fr)'
                  templateColumns='repeat(12, 1fr)'
                  gap={4}
                >
                  <GridItem colSpan={12}>
                    <Stack spacing={5} direction='row'>
                      <Text>Titular do Comprovante de Residência</Text>
                      <Checkbox borderColor={'gray'} size='md' colorScheme='teal' value={resid_reqtitular} onChange={e => setResid_reqtitular(e.target.checked ? 1 : 0)} isChecked={resid_reqtitular === 1} >Requerente</Checkbox>
                      <Checkbox borderColor={'gray'} size='md' colorScheme='teal' value={resid_conjtitular} onChange={e => setResid_conjtitular(e.target.checked ? 1 : 0)} isChecked={resid_conjtitular === 1} >Cônjuge</Checkbox>
                      <Checkbox borderColor={'gray'} size='md' colorScheme='teal' value={resid_outrotitular} onChange={e => { setResid_outrotitular(e.target.checked ? 1 : 0); onTitularToggle() }} isChecked={resid_outrotitular === 1}>Outro Titular</Checkbox>
                    </Stack>
                  </GridItem>
                </Grid>

                <Collapse in={isTitularOpen} animateOpacity>
                  <Grid
                    h='40px'
                    templateRows='repeat(1, 1fr)'
                    templateColumns='repeat(24, 1fr)'
                    gap={4}
                  >
                    <GridItem colSpan={11}>
                      <Stack spacing={3} direction='row'><Text whiteSpace='nowrap'>Nome do Titular</Text><Input autoComplete="no" borderColor={'gray'} maxLength={250} placeholder='Nome Completo' size='sm' value={resid_titular} onChange={e => setResid_titular(e.target.value)} /></Stack>
                    </GridItem>
                    <GridItem colSpan={7}>
                      <Stack spacing={3} direction='row'><Text>CPF</Text><Input autoComplete="no" as={ReactInputMask} mask="999.999.999-99" maskChar={null} borderColor={'gray'} size='sm' value={resid_cpf} onChange={e => setResid_cpf(e.target.value)} /></Stack>
                    </GridItem>
                    <GridItem colSpan={6}>
                      <Stack spacing={3} direction='row'><Text whiteSpace='nowrap'>Data de Nascimento</Text><Input autoComplete="no" borderColor={'gray'} type='date' size='sm' value={resid_nascimento} onChange={e => setResid_nascimento(e.target.value)} /></Stack>
                    </GridItem>
                  </Grid>
                </Collapse>

                <Flex direction='row' p={5}>
                  <Spacer />
                  <Button colorScheme="teal" variant={'outline'} onClick={e => salvarResidencia()}>Salvar Residência<SaveIcon ml={2} /></Button>
                </Flex>

              </AccordionPanel>

            </AccordionItem>
          </Accordion>
        );
      }
    } else {
      return null;
    }
  }

  return (
    <div id="editar_requerente">
      <Container maxW='95%' bg='white'>
        <Flex direction='row' w={'100%'} h={'60px'} bg={'gray.200'}>
					<Center w={'100px'} h={'100%'} color={'gray.600'} cursor='pointer' _hover={{ color: 'teal' }} onClick={e => sairEditarRequerente()}>
						<ChevronLeftIcon boxSize={12} />
					</Center>
					<Center>
						<Heading size={'md'} color={'gray.700'} ml={3}>Processo {unid_cod} - {reurb_modalidade} - {nui_cod}</Heading>
					</Center>
					<Spacer />
					<Center>
						<ButtonGroup gap='4' mr={4}>
            <Button colorScheme="teal" variant='outline' onClick={onDocumentalOpen}>Observação Documental<EditIcon ml={2} /></Button>							
						</ButtonGroup>
					</Center>
				</Flex>

        {renderizar()}

        {/*Modal buscar dados do requerente*/}
        <Modal isOpen={isBuscarOpen} onClose={onBuscarClose} isCentered size='4xl'>
          <ModalOverlay />
          <ModalContent bg='gray.300'>
            <ModalHeader textAlign='center'>PEGAR DADOS</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Center w='100%' bg="white" p={5} mb={5}>
                <Stack spacing={3} direction='row'><Text>Remessa</Text>
                  <Select w={'400px'} borderColor={'gray'} size='sm' value={id_buscar_remessa} onChange={e => { setId_buscar_remessa(e.target.value); carregarListaBuscar(e.target.value); }}>
                    {lista_remessas.map(item => {
                      return (<option key={item.value} value={item.value}>{item.texto}</option>);
                    })}
                  </Select>
                </Stack>
              </Center>
              <Box w='100%' bg="white" p='5px' mb='10px' sx={'::-webkit-scrollbar'} overflowX="auto" height='430px'>
                <TableContainer m={1}>
                  <Table size='sm'>
                    <Thead>
                      <Tr>
                        <Th textAlign={'center'}>CÓDIGO</Th>
                        <Th textAlign={'center'}>REQUERENTE</Th>
                        <Th textAlign={'center'}>CÔNJUGE</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {lista_requerentes.map((item, i) => {
                        return (
                          <Tr key={i} cursor='pointer' _hover={{ bg: 'gray.100' }} onClick={e => pegarDadosRequerente(item.value)}>
                            <Td textAlign={'center'}>{item.codigo}</Td>
                            <Td>{item.requerente}</Td>
                            <Td>{item.conjuge}</Td>
                          </Tr>
                        );
                      })}
                    </Tbody>
                  </Table>
                </TableContainer>
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>

        {/*MODAL OBSERVAÇÃO DOCUMENTAL*/}
        <Modal isOpen={isDocumentalOpen} onClose={onDocumentalClose} isCentered size='5xl'>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader textAlign='center'>OBSERVAÇÃO DOCUMENTAL</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Flex direction={'column'} p={5} gap={5}>
                <Textarea borderColor={'gray'} bg={'white'}
                  p={4}
                  minH="unset"
                  overflow="hidden"
                  w="100%"
                  resize="none"
                  minRows={5}
                  as={ResizeTextarea}
                  placeholder="Observação sobre os documentos do requerente..."
                  value={doc_observacao}
                  onChange={e => setDoc_observacao(e.target.value)}
                  textAlign={'justify'}
                />
              </Flex>
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="teal" variant='outline' onClick={e => salvarDocumental()}>Concluir</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        {/*Alert menor de idade sem representante*/}
        <AlertDialog
          isOpen={isMenorOpen}
          leastDestructiveRef={cancelMenorRef}
          onClose={onMenorClose}
          isCentered
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                Menor de Idade
              </AlertDialogHeader>
              <AlertDialogBody>
                O requerente é menor de idade, caso nenhum representante seja indicado, ele será considerado emancipado.
              </AlertDialogBody>
              <AlertDialogFooter>
                <Button ref={cancelMenorRef} onClick={onMenorClose}>
                  OK
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </Container >
    </div>
  );
}

export default EditarRequerente;