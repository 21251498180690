import React from "react";
import { useContext, useState, useEffect } from "react";
import { Box, Input, Text, Flex, Button, Table, Thead, Tbody, Tr, Th, Td, TableContainer, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Stack, useDisclosure, Spacer, Container, Heading, ButtonGroup, Center } from '@chakra-ui/react';
import { PlusSquareIcon, ChevronLeftIcon, HamburgerIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";

import NavSuperior from "../../components/NavBar/navsuperior";
import DadosRemessa from "../../components/Remessa/dadosremessa";

import { AuthContext } from "../../contexts/auth";
import { ReurbContext } from "../../contexts/reurb";

import { api } from "../../../services/api";

const ProcessosPage = () => {
	const navigate = useNavigate();

	//Variáveis de Contexto
	const { logout } = useContext(AuthContext);
	const { rem_id, setUnid_id } = useContext(ReurbContext);

	//Variáveis Chakra UI
	const { isOpen: isProcessoOpen, onOpen: onProcessoOpen, onClose: onProcessoClose } = useDisclosure();

	//Variáveis
	const [qtd_processos, setQtd_processos] = useState(0);
	const [lista_processos, setLista_processos] = useState([{ unid_id: 0, unid_cod: '', nui_id: 0, nui_cod: '', nui_nome: '', reurb_situacao: '', endereco: '', beneficiarios: '', principal: '', reurb_matricula: '', modalidade: '' }]);
	const [msg, setMsg] = useState('');

	//Variáveis Reurb
	const [unid_cod, setUnid_cod] = useState("");

	//Ler dados
	useEffect(() => {
		api.post('/processo/LerListaProcessos.php', JSON.stringify({ rem_id }))
			.then(function (rp) {
				if (rp.data.sigla !== '') {
					setUnid_cod(rp.data.sigla + rp.data.remessa + '_');
				}
				setQtd_processos(rp.data.qtd_processos);
				setLista_processos(rp.data.lista_processos);
			})
			.catch(function (error) {
				if (error.response.data === "EXPIRED") {
					logout();
				} else {
					console.log(error);
				}
			});
	}, []);

	//Ir para remessa
	function goToRemessa() {
		navigate('/remessa');
	}

	//Ir para requerentes
	function goToRequerentes() {
		navigate('/requerentes');
	}

	//Ir para núcleos
	function goToNucleos() {
		navigate('/nucleos');
	}

	//Ir para processo
	function goToProcesso(id) {
		setUnid_id(id);
		if (id !== 0) {
			navigate('/processo');
		}
	}

	//Adicionar novo processo
	async function adicionarProcesso(tipo) {
		if (tipo === 'proximo') {
			//Novo processo
			await api.post('/processo/NovoProcessoProximo.php', JSON.stringify({ rem_id }))
				.then(function (rp) {
					setMsg(rp.data.msg);
					if (rp.data.msg === "") {
						onProcessoClose();
						if (parseInt(rp.data.id) > 0) {
							setUnid_id(rp.data.id);
							goToProcesso(rp.data.id);
						}
					}
				})
				.catch(function (error) {
					if (error.response.data === "EXPIRED") {
						logout();
					} else {
						console.log(error);
					}
				});
		} else {
			if (unid_cod !== "") {
				//Novo processo
				await api.post('/processo/NovoProcessoInformado.php', JSON.stringify({ rem_id, unid_cod }))
					.then(function (rp) {
						setMsg(rp.data.msg);
						if (rp.data.msg === "") {
							onProcessoClose();
							if (parseInt(rp.data.id) > 0) {
								setUnid_id(rp.data.id);
								goToProcesso(rp.data.id);
							}
						}
					})
					.catch(function (error) {
						if (error.response.data === "EXPIRED") {
							logout();
						} else {
							console.log(error);
						}
					});
			}
		}
	}

	return (
		<div id="processos">
			<NavSuperior />
			<DadosRemessa titulo="Lista de Processos" />
			<Container maxW='95%' bg='white'>				
				<Flex direction='row' w={'100%'} h={'60px'} bg={'gray.200'}>
					<Center w={'100px'} h={'100%'} color={'gray.600'} cursor='pointer' _hover={{ color: 'teal' }} onClick={e => goToRemessa()}>
						<ChevronLeftIcon boxSize={12} />
					</Center>
					<Center>
						<Heading size={'md'} color={'gray.700'} ml={3}>Processos Cadastrados ({qtd_processos})</Heading>
					</Center>
					<Spacer />
					<Center>
						<ButtonGroup gap='4' mr={4}>
							<Button colorScheme="teal" variant='outline' onClick={e => goToRequerentes()}>Requerentes<HamburgerIcon ml={2} /></Button>
							<Button colorScheme="teal" variant='outline' onClick={e => goToNucleos()}>Núcleos<HamburgerIcon ml={2} /></Button>
							<Button colorScheme="teal" variant='outline' onClick={onProcessoOpen}>Novo Processo<PlusSquareIcon ml={2} /></Button>
						</ButtonGroup>
					</Center>
				</Flex>
				<Box overflowX="auto"
					whiteSpace="nowrap"
					sx={{
						'&::-webkit-scrollbar': {
							width: '16px',
							borderRadius: '8px',
							backgroundColor: `rgba(0, 0, 0, 0.05)`,
						},
						'&::-webkit-scrollbar-thumb': {
							backgroundColor: `rgba(0, 0, 0, 0.05)`,
						},
					}}
					w='100%'
					height={(window.innerHeight - 260)}>
					<TableContainer mt={5}>
						<Table size='sm'>
							<Thead>
								<Tr>
									<Th textAlign={'center'}>CÓDIGO</Th>
									<Th textAlign={'center'}>PRINCIPAL</Th>
									<Th textAlign={'center'}>BENEFICIÁRIOS</Th>
									<Th textAlign={'center'}>LOCAL</Th>
									<Th textAlign={'center'}>NÚCLEO</Th>
									<Th textAlign={'center'}>MODALIDADE</Th>
									<Th textAlign={'center'}>MATRÍCULA EMITIDA</Th>
									<Th textAlign={'center'}>SITUAÇÃO</Th>
								</Tr>
							</Thead>
							<Tbody>
								{lista_processos.map((item, i) => {
									var nucleo;
									if (item.nui_cod !== '-') {
										nucleo = item.nui_nome + ' (' + item.nui_cod + ')';
									} else {
										nucleo = '-';
									}
									return (
										<Tr key={i} cursor='pointer' _hover={{ bg: 'gray.200' }} onClick={e => goToProcesso(item.unid_id)} bg={item.reurb_situacao === 'Cancelado' ? 'red.100' : 'white'}>
											<Td textAlign={'center'}>{item.unid_cod}</Td>
											<Td maxW={'250px'} whiteSpace={'pre-wrap'}>{item.principal}</Td>
											<Td textAlign={'center'}>{item.beneficiarios}</Td>
											<Td maxW={'200px'}><Text isTruncated>{item.endereco}</Text></Td>
											<Td maxW={'150px'}><Text isTruncated>{nucleo}</Text></Td>
											<Td textAlign={'center'}>{item.modalidade}</Td>
											<Td textAlign={'center'}>{item.reurb_matricula}</Td>
											<Td textAlign={'center'}>{item.reurb_situacao}</Td>
										</Tr>
									);
								})}
							</Tbody>
						</Table>
					</TableContainer>
				</Box>
			</Container>

			{/*MODAL NOVO PROCESSO*/}
			<Modal isOpen={isProcessoOpen} onClose={onProcessoClose} isCentered size='2xl' >
				<ModalOverlay />
				<ModalContent>
					<ModalHeader textAlign='center'>NOVO PROCESSO</ModalHeader>
					<ModalCloseButton />
					<ModalBody mb={8}>
						<Flex justifyContent='center'>
							<Stack direction='column' spacing={5} mt={1}><Text whiteSpace={'nowrap'}>Informe o código do Processo</Text><Input borderColor={'gray'} w={'400px'} textAlign={'center'} size='lg' value={unid_cod} onChange={e => setUnid_cod(e.target.value)} /></Stack>
							<Button mt={12} ml={3} size='lg' colorScheme='teal' variant={'outline'} onClick={e => adicionarProcesso('informado')}>OK</Button>
						</Flex>
						<Flex justifyContent='center' mt='20px'><Text whiteSpace={'nowrap'} color='red.500'>{msg}</Text></Flex>
						<Center mt={5}>
							<Button w={'78%'} colorScheme='gray' onClick={e => adicionarProcesso('proximo')}>Usar Próximo Código da Sequência</Button>
						</Center>
					</ModalBody>
				</ModalContent>
			</Modal>

		</div>
	);
}

export default ProcessosPage;