import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { api } from "../../../services/api";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

function EstadoCivilPDF(req_id, setMsg_imprimir) {  

  //Variáveis
  const data = { req_id };

  var titulo;
  var texto;
  var local_data;
  var assinatura;
  var timbrado;
  var erro;
  var mensagem;

  //Pegar dados
  pegarDados(data);
  async function pegarDados(data) {
    await api.post('/requerente/EstadoCivilPDF.php', JSON.stringify(data)).then(rp => {
      titulo = rp.data.titulo;
      texto = rp.data.texto;
      local_data = rp.data.local_data;
      assinatura = rp.data.assinatura;
      timbrado = rp.data.timbrado;
      erro = rp.data.erro;
      mensagem = rp.data.mensagem;
    });
    gerarPDF();
  }

  //Gerar PDF
  function gerarPDF() {

    if (erro === false) {

      //Conteúdo do documento
      const conteudo = [
        {
          text: titulo + '\n\n\n', style: 'titulo'
        },
        texto.map((item) => {
          return [{ text: item + '\n\n', style: 'corpo' },]
        }),
        {
          text: local_data + '\n\n', style: 'data'
        },        
        assinatura.map((item) => {
					return [{stack: [{ text: item, style: 'assinatura' }], unbreakable: true},]
				})
      ];

      //Rodapé
      const rodape = function (currentPage, pageCount) { return ({text: '\nPágina ' + currentPage.toString() + ' de ' + pageCount, style: 'rodape'}); };

      //Estilos
      const estilos = {
        titulo: {
          fontSize: 13,
          alignment: 'center',
          bold: true
        },
        corpo: {
          fontSize: 11,
          alignment: 'justify'
        },
        data: {
          fontSize: 11,
          alignment: 'right'
        },
        assinatura: {
          fontSize: 11,
          alignment: 'center'
        },
        rodape: {
          fontSize: 10,
          alignment: 'center'
        }
      };

      //Imagem de fundo
      var imagem;
      if(timbrado!==""){
        imagem = {image: timbrado, width: 610};
      }else{
        imagem = "";
      }

      //Definições
      var docDefinition = {
        pageSize: 'A4',
        pageMargins: [70, 80, 50, 70],
        info: { title: 'Declaração de Estado Civil' },
        background: imagem,
        content: conteudo,
        styles: estilos,
        footer: rodape
      };

      pdfMake.createPdf(docDefinition).open();
      //pdfMake.createPdf(docDefinition).download();    
      //pdfMake.createPdf(docDefinition).open({}, window);

    } else {
      setMsg_imprimir(mensagem.replaceAll(";", "; "));
    }
  }

}

export default EstadoCivilPDF;