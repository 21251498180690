import React from "react";
import { useContext, useState, useEffect } from "react";
import ResizeTextarea from "react-textarea-autosize";
import { Text, Textarea, Button, Heading, Divider, Container, Grid, GridItem, Stack, SimpleGrid, Box, Flex, useDisclosure, Collapse, Spacer, ButtonGroup, AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogBody, AlertDialogFooter, Modal, ModalCloseButton, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, useToast, Center } from '@chakra-ui/react';
import { ChevronLeftIcon, DeleteIcon, EditIcon, TriangleDownIcon } from "@chakra-ui/icons";
import { NavigateIcon } from "../../icons/navigateicon";
import { CheckSquareIcon } from "../../icons/checksquareicon";
import { useNavigate } from "react-router-dom";

import { AuthContext } from "../../contexts/auth";
import { ReurbContext } from "../../contexts/reurb";

import { api } from "../../../services/api";

import { RendaPDF } from "./rendapdf";
import ResidenciaPDF from "./residenciapdf";
import EstadoCivilPDF from "./estadocivilpdf";

const ExibirRequerente = () => {
  const { logout } = useContext(AuthContext);
  const { rem_id, req_id, setUnid_id, nui_id, setNui_id, setEditar_requerente } = useContext(ReurbContext);

  const navigate = useNavigate();

  //Transitions
  const { isOpen, onToggle } = useDisclosure();
  const cancelRef = React.useRef();

  //Variáveis Chakra UI
  const toast = useToast();
  const { isOpen: isExcluirOpen, onOpen: onExcluirOpen, onClose: onExcluirClose } = useDisclosure();
  const { isOpen: isDocumentalOpen, onOpen: onDocumentalOpen, onClose: onDocumentalClose } = useDisclosure();
  const { isOpen: isMensagemOpen, onOpen: onMensagemOpen, onClose: onMensagemClose } = useDisclosure();

  //Controle
  const [loaded, setLoaded] = useState(0);

  //Variáveis do processo
  const [unid_cod, setUnid_cod] = useState("");
  const [nui_cod, setNui_cod] = useState("");
  const [reurb_modalidade, setReurb_modalidade] = useState("");

  //Tipo de Requerente
  const [req_tipo, setReq_tipo] = useState(0);

  //Variáveis Requerente PF
  const [req_beneficiario, setReq_beneficiario] = useState("");
  const [req_nome, setReq_nome] = useState("");
  const [req_nacionalidade, setReq_nacionalidade] = useState("");
  const [req_rgcompleto, setReq_rg] = useState("");
  const [req_cpf, setReq_cpf] = useState("");
  const [req_sexo, setReq_sexo] = useState('');
  const [req_nascimento, setReq_nascimento] = useState("");
  const [req_idade, setReq_idade] = useState("");
  const [req_pai, setReq_pai] = useState("");
  const [req_mae, setReq_mae] = useState("");
  const [req_profissao, setReq_profissao] = useState("");
  const [req_renda, setReq_renda] = useState("");
  const [req_estadocivil, setReq_estadocivil] = useState("");
  const [req_uniao, setReq_uniao] = useState("");
  const [req_regime, setReq_regime] = useState("");
  const [req_telefone, setReq_telefone] = useState("");
  const [req_whatsapp, setReq_whatsapp] = useState(0);
  const [req_email, setReq_email] = useState("");

  //Variáveis Requerente PJ
  const [req_cnpj, setReq_cnpj] = useState("");

  //Variáveis Cônjuge
  const [conj_beneficiario, setConj_beneficiario] = useState("");
  const [conj_nome, setConj_nome] = useState("");
  const [conj_nacionalidade, setConj_nacionalidade] = useState("");
  const [conj_rgcompleto, setConj_rg] = useState("");
  const [conj_cpf, setConj_cpf] = useState("");
  const [conj_sexo, setConj_sexo] = useState('');
  const [conj_nascimento, setConj_nascimento] = useState("");
  const [conj_idade, setConj_idade] = useState("");
  const [conj_pai, setConj_pai] = useState("");
  const [conj_mae, setConj_mae] = useState("");
  const [conj_profissao, setConj_profissao] = useState("");
  const [conj_renda, setConj_renda] = useState("");
  const [conj_estadocivil, setConj_estadocivil] = useState("");
  const [conj_uniao, setConj_uniao] = useState("");
  const [conj_regime, setConj_regime] = useState("");
  const [conj_telefone, setConj_telefone] = useState("");
  const [conj_whatsapp, setConj_whatsapp] = useState("");
  const [conj_email, setConj_email] = useState("");

  //Variáveis Residência
  const [resid_logradouro, setResid_logradouro] = useState("");
  const [resid_numero, setResid_numero] = useState("");
  const [resid_complemento, setResid_complemento] = useState("");
  const [resid_bairro, setResid_bairro] = useState("");
  const [resid_localidade, setResid_localidade] = useState("");
  const [resid_municipio, setResid_municipio] = useState("");
  const [resid_uf, setResid_uf] = useState("");
  const [resid_cep, setResid_cep] = useState("");

  const [resid_outrotitular, setResid_outrotitular] = useState(0);

  //Variáveis Documental
  const [doc_observacao, setDoc_observacao] = useState("");
  const [doc_novaobservacao, setDoc_novaobservacao] = useState("");
  const [controle, setControle] = useState(false);

  //Variável imprimir
  const [msg_imprimir, setMsg_imprimir] = useState("");

  //Ler dados
  useEffect(() => {
    const data = {
      req_id
    }
    api.post('/requerente/LerExibirRequerente.php', JSON.stringify(data))
      .then(function (rp) {

        //Dados do Processo
        setUnid_id(rp.data.unid_id);
        setNui_id(rp.data.nui_id);
        setUnid_cod(rp.data.unid_cod);
        setNui_cod(rp.data.nui_cod);
        setReurb_modalidade(rp.data.reurb_modalidade);

        //Tipo de Requerente
        setReq_tipo(rp.data.req_tipo);

        //Requerente PF
        setReq_beneficiario(rp.data.req_beneficiario);
        setReq_nome(rp.data.req_nome);
        setReq_nacionalidade(rp.data.req_nacionalidade);
        setReq_rg(rp.data.req_rgcompleto);
        setReq_cpf(rp.data.req_cpf);
        setReq_sexo(rp.data.req_sexo);
        setReq_nascimento(rp.data.req_nascimento);
        setReq_idade(rp.data.req_idade);
        setReq_pai(rp.data.req_pai);
        setReq_mae(rp.data.req_mae);
        setReq_profissao(rp.data.req_profissao);
        setReq_renda(rp.data.req_renda);
        setReq_estadocivil(rp.data.req_estadocivil);
        setReq_uniao(rp.data.req_uniao);
        setReq_regime(rp.data.req_regime);
        setReq_telefone(rp.data.req_telefone);
        setReq_whatsapp(rp.data.req_whatsapp);
        setReq_email(rp.data.req_email);

        //Requerente PJ
        setReq_cnpj(rp.data.req_cnpj);

        //Cônjuge
        setConj_beneficiario(rp.data.conj_beneficiario);
        setConj_nome(rp.data.conj_nome);
        setConj_nacionalidade(rp.data.conj_nacionalidade);
        setConj_rg(rp.data.conj_rgcompleto);
        setConj_cpf(rp.data.conj_cpf);
        setConj_sexo(rp.data.conj_sexo);
        setConj_nascimento(rp.data.conj_nascimento);
        setConj_idade(rp.data.conj_idade);
        setConj_pai(rp.data.conj_pai);
        setConj_mae(rp.data.conj_mae);
        setConj_profissao(rp.data.conj_profissao);
        setConj_renda(rp.data.conj_renda);
        setConj_estadocivil(rp.data.conj_estadocivil);
        setConj_uniao(rp.data.conj_uniao);
        setConj_regime(rp.data.conj_regime);
        setConj_telefone(rp.data.conj_telefone);
        setConj_whatsapp(rp.data.conj_whatsapp);
        setConj_email(rp.data.conj_email);

        //Residência
        setResid_logradouro(rp.data.resid_logradouro);
        setResid_numero(rp.data.resid_numero);
        setResid_complemento(rp.data.resid_complemento);
        setResid_bairro(rp.data.resid_bairro);
        setResid_localidade(rp.data.resid_localidade);
        setResid_municipio(rp.data.resid_municipio);
        setResid_uf(rp.data.resid_uf);
        setResid_cep(rp.data.resid_cep);

        setResid_outrotitular(rp.data.resid_outrotitular);

        setLoaded(1);

      })
      .catch(function (error) {
        if (error.response.data === "EXPIRED") {
          logout();
        } else {
          console.log(error);
        }
      });
  }, []);

  //Observação Documental
  useEffect(() => {
    const data = {
      req_id
    }
    api.post('/requerente/LerDocumental.php', JSON.stringify(data))
      .then(function (rp) {
        setDoc_observacao(rp.data.doc_observacao);
        setDoc_novaobservacao(rp.data.doc_observacao);
      })
      .catch(function (error) {
        if (error.response.data === "EXPIRED") {
          logout();
        } else {
          console.log(error);
        }
      });
  }, [controle]);

  //Salvar Observação Documental
  function salvarDocumental() {
    const data = {
      rem_id,
      req_id,
      doc_observacao: doc_novaobservacao
    }
    api.post('/requerente/SalvarDocumental.php', JSON.stringify(data))
      .then(function (rp) {
        setControle(!controle);
        onDocumentalClose();
        return (toast({
          position: 'bottom-left', render: () => (<Box color='white' p={3} bg={rp.data.sucesso === true ? 'teal' : 'red'}>{rp.data.msg}</Box>),
        })
        );
      })
      .catch(function (error) {
        if (error.response.data === "EXPIRED") {
          logout();
        } else {
          console.log(error);
        }
      });
  }

  //Abrir mensagem imprimir
  useEffect(() => {
    //Exibir mensagem quando mudar
    if (msg_imprimir !== '') {
      onMensagemOpen();
    }
  }, [msg_imprimir]);

  //Excluir requerente
  async function excluirRequerente() {
    const data = {
      req_id
    }
    await api.post('/requerente/ExcluirRequerente.php', JSON.stringify(data))
      .then(function (rp) {
        //Ir para processo
        navigate('/processo');
      })
      .catch(function (error) {
        if (error.response.data === "EXPIRED") {
          logout();
        } else {
          console.log(error);
        }
      });
  }

  //Ir para lista de requerentes
  function goToRequerentes() {
    navigate('/requerentes');
  }

  //Ir para processo
  function goToProcesso() {
    navigate('/processo');
  }

  //Ir para núcleo
  function goToNucleo() {
    if (nui_id > 0) {
      navigate('/nucleo');
    } else {
      navigate('/nucleos');
    }
  }

  //Ir para documental
  function goToDocumental() {
    navigate('/documental');
  }

  //Renderizar
  function renderizar() {
    if (loaded === 1) {
      if (req_tipo === 4) {//Pessoa Jurídica
        return (
          <>
            <Heading as='h4' size='md' textAlign={'left'} mb={5}>
              Dados do Requerente
            </Heading>
            <Divider borderColor={'gray.400'} orientation='horizontal' mt={4} mb={4} />
            <Grid
              templateRows='repeat(1, 1fr)'
              templateColumns='repeat(12, 1fr)'
              gap={4}
            >
              <GridItem colSpan={5} >
                <Stack spacing={3} direction='row'><Text>Nome</Text><Text as='b' color={req_nome === 'Não Informado' ? 'red' : 'black'}>{req_nome}</Text></Stack>
              </GridItem>
              <GridItem colSpan={7} >
                <Stack spacing={3} direction='row'><Text>CNPJ</Text><Text as='b' color={req_cnpj === 'Não Informado' ? 'red' : 'black'}>{req_cnpj}</Text></Stack>
              </GridItem>
            </Grid>
            <Heading as='h4' size='md' textAlign={'left'} mt={8}>
              Dados de Localização
            </Heading>
            <Divider borderColor={'gray.400'} orientation='horizontal' mt={4} mb={4} />
            <Grid
              templateRows='repeat(3, 1fr)'
              templateColumns='repeat(12, 1fr)'
              gap={4}
            >
              <GridItem colSpan={5} >
                <Stack spacing={3} direction='row'><Text>Logradouro</Text><Text as='b' color={resid_logradouro === 'Não Informado' ? 'red' : 'black'}>{resid_logradouro}</Text></Stack>
              </GridItem>
              <GridItem colSpan={2} >
                <Stack spacing={3} direction='row'><Text>Número</Text><Text as='b' color={resid_numero === 'Não Informado' ? 'red' : 'black'}>{resid_numero}</Text></Stack>
              </GridItem>
              <GridItem colSpan={5} >
                <Stack spacing={3} direction='row'><Text>Complemento</Text><Text as='b' color={resid_complemento === 'Não Informado' ? 'red' : 'black'}>{resid_complemento}</Text></Stack>
              </GridItem>

              <GridItem colSpan={5} >
                <Stack spacing={3} direction='row'><Text>Bairro</Text><Text as='b' color={resid_bairro === 'Não Informado' ? 'red' : 'black'}>{resid_bairro}</Text></Stack>
              </GridItem>
              <GridItem colSpan={7} >
                <Stack spacing={3} direction='row'><Text>Localidade</Text><Text as='b' color={resid_localidade === 'Não Informado' ? 'red' : 'black'}>{resid_localidade}</Text></Stack>
              </GridItem>

              <GridItem colSpan={5} >
                <Stack spacing={3} direction='row'><Text>Município</Text><Text as='b' color={resid_municipio === 'Não Informado' ? 'red' : 'black'}>{resid_municipio}</Text></Stack>
              </GridItem>
              <GridItem colSpan={2} >
                <Stack spacing={3} direction='row'><Text>UF</Text><Text as='b' color={resid_uf === 'Não Informado' ? 'red' : 'black'}>{resid_uf}</Text></Stack>
              </GridItem>
              <GridItem colSpan={5} >
                <Stack spacing={3} direction='row'><Text>CEP</Text><Text as='b' color={resid_cep === 'Não Informado' ? 'red' : 'black'}>{resid_cep}</Text></Stack>
              </GridItem>
            </Grid>
          </>
        );
      }
      else {//Pessoa Física
        return (
          <>
            <SimpleGrid columns={2} spacing={10} mt={5}>
              <Box>
                <Flex dir={'horizontal'} gap={4}>
                  <Heading as='h4' size='md' textAlign={'left'}>
                    Dados do Requerente
                  </Heading>
                  <Text as='b' size='md' textAlign={'left'} color={'gray'}>{req_beneficiario}</Text>
                </Flex>
                <Divider borderColor={'gray.400'} orientation='horizontal' mt={4} mb={4} />
                <Grid
                  templateRows='repeat(11, 1fr)'
                  templateColumns='repeat(12, 1fr)'
                  gap={4}
                >
                  <GridItem colSpan={12} >
                    <Stack spacing={3} direction='row'><Text>Nome</Text><Text as='b' color={req_nome === 'Não Informado' ? 'red' : 'black'}>{req_nome}</Text></Stack>
                  </GridItem>

                  <GridItem colSpan={6} >
                    <Stack spacing={3} direction='row'><Text>Sexo</Text><Text as='b'>{req_sexo}</Text></Stack>
                  </GridItem>
                  <GridItem colSpan={6} >
                    <Stack spacing={3} direction='row'><Text>Nacionalidade</Text><Text as='b' color={req_nacionalidade === 'Não Informado' ? 'red' : 'black'}>{req_nacionalidade}</Text></Stack>
                  </GridItem>

                  <GridItem colSpan={6} >
                    <Stack spacing={3} direction='row'><Text>RG</Text><Text as='b' color={req_rgcompleto === 'Não Informado' ? 'red' : 'black'}>{req_rgcompleto}</Text></Stack>
                  </GridItem>
                  <GridItem colSpan={6} >
                    <Stack spacing={3} direction='row'><Text>CPF</Text><Text as='b' color={req_cpf === 'Não Informado' ? 'red' : 'black'}>{req_cpf}</Text></Stack>
                  </GridItem>

                  <GridItem colSpan={6} >
                    <Stack spacing={3} direction='row'><Text>Data de Nascimento</Text><Text as='b' color={req_nascimento === 'Não Informado' ? 'red' : 'black'}>{req_nascimento}</Text></Stack>
                  </GridItem>

                  <GridItem colSpan={6} >
                    <Stack spacing={3} direction='row'><Text>Idade</Text><Text as='b'>{req_idade}</Text></Stack>
                  </GridItem>

                  <GridItem colSpan={12} >
                    <Stack spacing={3} direction='row'><Text>Pai</Text><Text as='b' color={req_pai === 'Não Informado' ? 'red' : 'black'}>{req_pai}</Text></Stack>
                  </GridItem>

                  <GridItem colSpan={12} >
                    <Stack spacing={3} direction='row'><Text>Mãe</Text><Text as='b' color={req_mae === 'Não Informado' ? 'red' : 'black'}>{req_mae}</Text></Stack>
                  </GridItem>

                  <GridItem colSpan={12} >
                    <Stack spacing={3} direction='row'><Text>Estado Civil</Text><Text as='b' color={req_estadocivil === 'Não Informado' ? 'red' : 'black'}>{req_estadocivil}</Text></Stack>
                  </GridItem>

                  <GridItem colSpan={6} >
                    <Stack spacing={3} direction='row'><Text>Regime de Bens</Text><Text as='b' color={req_regime === 'Não Informado' ? 'red' : 'black'}>{req_regime}</Text></Stack>
                  </GridItem>
                  <GridItem colSpan={6} >
                    <Stack spacing={3} direction='row'><Text>Data da União</Text><Text as='b' color={req_uniao === 'Não Informado' ? 'red' : 'black'}>{req_uniao}</Text></Stack>
                  </GridItem>

                  <GridItem colSpan={6} >
                    <Stack spacing={3} direction='row'><Text>Profissão</Text><Text as='b' color={req_profissao === 'Não Informado' ? 'red' : 'black'} noOfLines={1}>{req_profissao}</Text></Stack>
                  </GridItem>
                  <GridItem colSpan={6} >
                    <Stack spacing={3} direction='row'><Text>Renda Mensal</Text><Text as='b' color={req_renda === 'Não Informado' ? 'red' : 'black'}>{req_renda}</Text></Stack>
                  </GridItem>

                  <GridItem colSpan={6} >
                    <Stack spacing={3} direction='row'><Text>Telefone</Text><Text as='b'>{req_telefone}</Text></Stack>
                  </GridItem>
                  <GridItem colSpan={6} >
                    <Stack spacing={3} direction='row'><Text>Whatsapp</Text><Text as='b'>{req_whatsapp}</Text></Stack>
                  </GridItem>

                  <GridItem colSpan={12} >
                    <Stack spacing={3} direction='row'><Text>E-mail</Text><Text as='b'>{req_email}</Text></Stack>
                  </GridItem>
                </Grid>
              </Box>

              <Box>
                <Flex dir={'horizontal'} gap={4}>
                  <Heading as='h4' size='md' textAlign={'left'}>
                    Dados do Cônjuge
                  </Heading>
                  <Text as='b' size='md' textAlign={'left'} color={'gray'}>{conj_beneficiario}</Text>
                </Flex>
                <Divider borderColor={'gray.400'} orientation='horizontal' mt={4} mb={4} />
                <Grid
                  templateRows='repeat(11, 1fr)'
                  templateColumns='repeat(12, 1fr)'
                  gap={4}
                >
                  <GridItem colSpan={12} >
                    <Stack spacing={3} direction='row'><Text>Nome</Text><Text as='b' color={conj_nome === 'Não Informado' ? 'red' : 'black'}>{conj_nome}</Text></Stack>
                  </GridItem>

                  <GridItem colSpan={6} >
                    <Stack spacing={3} direction='row'><Text>Sexo</Text><Text as='b'>{conj_sexo}</Text></Stack>
                  </GridItem>
                  <GridItem colSpan={6} >
                    <Stack spacing={3} direction='row'><Text>Nacionalidade</Text><Text as='b' color={conj_nacionalidade === 'Não Informado' ? 'red' : 'black'}>{conj_nacionalidade}</Text></Stack>
                  </GridItem>

                  <GridItem colSpan={6} >
                    <Stack spacing={3} direction='row'><Text>RG</Text><Text as='b' color={conj_rgcompleto === 'Não Informado' ? 'red' : 'black'}>{conj_rgcompleto}</Text></Stack>
                  </GridItem>
                  <GridItem colSpan={6} >
                    <Stack spacing={3} direction='row'><Text>CPF</Text><Text as='b' color={conj_cpf === 'Não Informado' ? 'red' : 'black'}>{conj_cpf}</Text></Stack>
                  </GridItem>

                  <GridItem colSpan={6} >
                    <Stack spacing={3} direction='row'><Text>Data de Nascimento</Text><Text as='b' color={conj_nascimento === 'Não Informado' ? 'red' : 'black'}>{conj_nascimento}</Text></Stack>
                  </GridItem>

                  <GridItem colSpan={6} >
                    <Stack spacing={3} direction='row'><Text>Idade</Text><Text as='b'>{conj_idade}</Text></Stack>
                  </GridItem>

                  <GridItem colSpan={12} >
                    <Stack spacing={3} direction='row'><Text>Pai</Text><Text as='b' color={conj_pai === 'Não Informado' ? 'red' : 'black'}>{conj_pai}</Text></Stack>
                  </GridItem>

                  <GridItem colSpan={12} >
                    <Stack spacing={3} direction='row'><Text>Mãe</Text><Text as='b' color={conj_mae === 'Não Informado' ? 'red' : 'black'}>{conj_mae}</Text></Stack>
                  </GridItem>

                  <GridItem colSpan={12} >
                    <Stack spacing={3} direction='row'><Text>Estado Civil</Text><Text as='b' color={conj_estadocivil === 'Não Informado' ? 'red' : 'black'}>{conj_estadocivil}</Text></Stack>
                  </GridItem>

                  <GridItem colSpan={6} >
                    <Stack spacing={3} direction='row'><Text>Regime de Bens</Text><Text as='b' color={conj_regime === 'Não Informado' ? 'red' : 'black'}>{conj_regime}</Text></Stack>
                  </GridItem>
                  <GridItem colSpan={6} >
                    <Stack spacing={3} direction='row'><Text>Data da União</Text><Text as='b' color={conj_uniao === 'Não Informado' ? 'red' : 'black'}>{conj_uniao}</Text></Stack>
                  </GridItem>

                  <GridItem colSpan={6} >
                    <Stack spacing={3} direction='row'><Text>Profissão</Text><Text as='b' color={conj_profissao === 'Não Informado' ? 'red' : 'black'} noOfLines={1}>{conj_profissao}</Text></Stack>
                  </GridItem>
                  <GridItem colSpan={6} >
                    <Stack spacing={3} direction='row'><Text>Renda Mensal</Text><Text as='b' color={conj_renda === 'Não Informado' ? 'red' : 'black'}>{conj_renda}</Text></Stack>
                  </GridItem>

                  <GridItem colSpan={6} >
                    <Stack spacing={3} direction='row'><Text>Telefone</Text><Text as='b'>{conj_telefone}</Text></Stack>
                  </GridItem>
                  <GridItem colSpan={6} >
                    <Stack spacing={3} direction='row'><Text>Whatsapp</Text><Text as='b'>{conj_whatsapp}</Text></Stack>
                  </GridItem>

                  <GridItem colSpan={12} >
                    <Stack spacing={3} direction='row'><Text>E-mail</Text><Text as='b'>{conj_email}</Text></Stack>
                  </GridItem>
                </Grid>
              </Box>
            </SimpleGrid>
            <Heading as='h4' size='md' textAlign={'left'} mt={10}>
              Dados de Residência
            </Heading>
            <Divider borderColor={'gray.400'} orientation='horizontal' mt={4} mb={4} />
            <Grid
              templateRows='repeat(3, 1fr)'
              templateColumns='repeat(12, 1fr)'
              gap={4}
            >
              <GridItem colSpan={5} >
                <Stack spacing={3} direction='row'><Text>Logradouro</Text><Text as='b' color={resid_logradouro === 'Não Informado' ? 'red' : 'black'}>{resid_logradouro}</Text></Stack>
              </GridItem>
              <GridItem colSpan={2} >
                <Stack spacing={3} direction='row'><Text>Número</Text><Text as='b' color={resid_numero === 'Não Informado' ? 'red' : 'black'}>{resid_numero}</Text></Stack>
              </GridItem>
              <GridItem colSpan={5} >
                <Stack spacing={3} direction='row'><Text>Complemento</Text><Text as='b' color={resid_complemento === 'Não Informado' ? 'red' : 'black'}>{resid_complemento}</Text></Stack>
              </GridItem>

              <GridItem colSpan={5} >
                <Stack spacing={3} direction='row'><Text>Bairro</Text><Text as='b' color={resid_bairro === 'Não Informado' ? 'red' : 'black'}>{resid_bairro}</Text></Stack>
              </GridItem>
              <GridItem colSpan={7} >
                <Stack spacing={3} direction='row'><Text>Localidade</Text><Text as='b' color={resid_localidade === 'Não Informado' ? 'red' : 'black'}>{resid_localidade}</Text></Stack>
              </GridItem>

              <GridItem colSpan={5} >
                <Stack spacing={3} direction='row'><Text>Município</Text><Text as='b' color={resid_municipio === 'Não Informado' ? 'red' : 'black'}>{resid_municipio}</Text></Stack>
              </GridItem>
              <GridItem colSpan={2} >
                <Stack spacing={3} direction='row'><Text>UF</Text><Text as='b' color={resid_uf === 'Não Informado' ? 'red' : 'black'}>{resid_uf}</Text></Stack>
              </GridItem>
              <GridItem colSpan={5} >
                <Stack spacing={3} direction='row'><Text>CEP</Text><Text as='b' color={resid_cep === 'Não Informado' ? 'red' : 'black'}>{resid_cep}</Text></Stack>
              </GridItem>
            </Grid>
            <Heading as='h4' size='md' textAlign={'left'} mt={10}>
              Observação Documental
            </Heading>
            <Divider borderColor={'gray.400'} orientation='horizontal' mt={4} mb={4} />
            <Flex direction={'row'} m={2}>
              <Text w={'80%'}>{doc_observacao === '' ? 'Sem Descrição' : doc_observacao}</Text>
              <Spacer />
              <Button mt={2} colorScheme="teal" variant='outline' onClick={onDocumentalOpen}>Observação Documental<EditIcon ml={2} /></Button>
            </Flex>
          </>
        );
      }
    } else {
      return null;
    }
  }

  return (
    <div id="exibir_requerente">
      <Container maxW={'95%'} mb={20}>        
        <Flex direction='row' w={'100%'} h={'60px'} bg={'gray.200'}>
					<Center w={'100px'} h={'100%'} color={'gray.600'} cursor='pointer' _hover={{ color: 'teal' }} onClick={e => goToRequerentes()}>
						<ChevronLeftIcon boxSize={12} />
					</Center>
					<Center>
						<Heading size={'md'} color={'gray.700'} ml={3} noOfLines={1}>Processo {unid_cod} - {reurb_modalidade} - {nui_cod}</Heading>
					</Center>
					<Spacer />
					<Center>
						<ButtonGroup gap='4' mr={4}>							
							<Button colorScheme="teal" variant='outline' onClick={e => goToProcesso()}>Processo<NavigateIcon ml={2} /></Button>
              <Button colorScheme="teal" variant='outline' onClick={e => goToNucleo()}>Núcleo<NavigateIcon ml={2} /></Button>
              <Button colorScheme="teal" variant='outline' onClick={e => goToDocumental()}>Documental<CheckSquareIcon ml={2} /></Button>
              <Button colorScheme="teal" variant='outline' onClick={onToggle} display={req_tipo === 4 ? 'none' : 'block'}>Imprimir <TriangleDownIcon ml={2} /></Button>
              <Button colorScheme="teal" variant='outline' onClick={e => setEditar_requerente(1)}>Editar<EditIcon ml={2} /></Button>
              <Button colorScheme="teal" variant='outline' onClick={onExcluirOpen}>Excluir<DeleteIcon ml={2} /></Button>
						</ButtonGroup>
					</Center>
				</Flex>

        {/*COLLAPSE IMPRIMIR*/}
        <Collapse in={isOpen} animateOpacity>
          <Box
            p={3}
            bg='gray.300'
            shadow='md'
            textAlign={'center'}
          >
            <ButtonGroup gap='4'>
              <Button colorScheme="gray" w={'200px'} h={'70px'} whiteSpace={'normal'} onClick={e => RendaPDF(req_id, "requerente", setMsg_imprimir)}>Declaração de Renda do Requerente</Button>
              <Button colorScheme="gray" w={'200px'} h={'70px'} whiteSpace={'normal'} onClick={e => RendaPDF(req_id, "conjuge", setMsg_imprimir)} isDisabled={req_estadocivil === 'Casado(a)' || req_estadocivil.slice(-13) === 'União Estável' ? false : true}>Declaração de Renda do Cônjuge</Button>
              <Button colorScheme="gray" w={'200px'} h={'70px'} whiteSpace={'normal'} onClick={e => EstadoCivilPDF(req_id, setMsg_imprimir)}>Declaração de Estado Civil</Button>
              <Button colorScheme="gray" w={'200px'} h={'70px'} whiteSpace={'normal'} onClick={e => ResidenciaPDF(req_id, setMsg_imprimir)} isDisabled={resid_outrotitular === 0}>Declaração de Residência</Button>
            </ButtonGroup>
          </Box>
        </Collapse>

        {/*ALERT MENSAGEM IMPRIMIR*/}
        <Modal closeOnEsc={false} closeOnOverlayClick={false} isOpen={isMensagemOpen} onClose={onMensagemClose} isCentered size='2xl'>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Dados Faltantes</ModalHeader>
            <ModalBody>
              <Text>{msg_imprimir}</Text>
            </ModalBody>
            <ModalFooter>
              <Button onClick={e => { setMsg_imprimir(''); onMensagemClose(); }}>
                OK
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        {/*MODAL OBSERVAÇÃO DOCUMENTAL*/}
        <Modal isOpen={isDocumentalOpen} onClose={onDocumentalClose} isCentered size='5xl'>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader textAlign='center'>OBSERVAÇÃO DOCUMENTAL</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Flex direction={'column'} p={5} gap={5}>
                <Textarea borderColor={'gray'} bg={'white'}
                  p={4}
                  minH="unset"
                  overflow="hidden"
                  w="100%"
                  resize="none"
                  minRows={5}
                  as={ResizeTextarea}
                  placeholder="Observação sobre os documentos do requerente..."
                  value={doc_novaobservacao}
                  onChange={e => setDoc_novaobservacao(e.target.value)}
                  textAlign={'justify'}
                />
              </Flex>
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="teal" variant='outline' onClick={e => salvarDocumental()}>Concluir</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        {/*MODAL EXCLUIR*/}
        <AlertDialog isOpen={isExcluirOpen} leastDestructiveRef={cancelRef.current} onClose={onExcluirClose} isCentered>
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                Excluir Requerente
              </AlertDialogHeader>

              <AlertDialogBody>
                Tem certeza que deseja excluir o requerente {req_nome}?
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef.current} onClick={onExcluirClose}>
                  Cancelar
                </Button>
                <Button colorScheme='red' onClick={() => { excluirRequerente(); onExcluirClose(); }} ml={3}>
                  Excluir
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>

        <Container maxW={'99%'}>
          {renderizar()}
        </Container>
      </Container>
    </div>
  );
}

export default ExibirRequerente;