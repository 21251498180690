import './App.css';
import { ChakraProvider } from '@chakra-ui/react';
import { ReurbProvider } from './frontend/contexts/reurb';
import AppRoutes from './AppRoutes';
import { extendTheme } from '@chakra-ui/react';
import '@fontsource-variable/montserrat';
import "@fontsource/open-sans"; 
import '@fontsource/poppins';
import '@fontsource-variable/work-sans';

const theme = extendTheme({
  fonts: {
    body: "Work Sans Variable",
    heading: "Work Sans Variable",
    mono: "Work Sans Variable",
  }
});

function App() {
  return (
    <div className="App">
      <ChakraProvider theme={theme}>
        <ReurbProvider>
          <AppRoutes />
        </ReurbProvider>
      </ChakraProvider>
    </div>
  );
}

export default App;
