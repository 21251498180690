import React from "react";
import { useContext, useState, useEffect } from "react";
import { Input, Divider, Container, Heading, Tabs, TabList, Tab, TabPanels, TabPanel, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, useDisclosure, Spacer, Text, Stack, Select, Textarea, SimpleGrid, Box, Flex, Center, Grid, GridItem, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Badge, Tag } from '@chakra-ui/react';
import ResizeTextarea from "react-textarea-autosize";
import { ChevronLeftIcon, ChevronRightIcon, PlusSquareIcon, EditIcon, DeleteIcon } from "@chakra-ui/icons";
import { SaveIcon } from "../../icons/saveicon";

import NavSuperior from "../../components/NavBar/navsuperior";

import { api } from "../../../services/api";

import { AuthContext } from "../../contexts/auth";

const MetasPage = () => {

  //Variáveis Chakra UI
  const { isOpen: isNovaOpen, onOpen: onNovaOpen, onClose: onNovaClose } = useDisclosure();

  //Variáveis de Contexto
  const { logout } = useContext(AuthContext);

  //Variáveis
  const lista_setores = ['Gestão', 'Jurídico', 'Financeiro', 'Comercial', 'Atendimento', 'Documental', 'Topografia', 'Projeto', 'Pós-Protocolo'];

  const [met_ano, setMet_ano] = useState(segundaAnterior(new Date()).getUTCFullYear());
  const [met_mes, setMet_mes] = useState(segundaAnterior(new Date()).getUTCMonth());
  const [met_setor, setMet_setor] = useState("Gestão");

  const [met_inicio, setMet_inicio] = useState(fdata(segundaAnterior(new Date())));
  const [met_fim, setMet_fim] = useState(fdata(somarDias(segundaAnterior(new Date()), 6)));
  const [lista_semanas, setLista_semanas] = useState(definirSemanas(met_ano, met_mes));

  const [lista_metas, setLista_metas] = useState([{ id: 0, inicio: '-', fim: '-', setor: '-', titulo: '-', situacao: '-', id_responsavel: 0, responsavel: '-', proponente: 0, proponente: '-', descricao: '-', observacao: '-', criado: '-', finalizado: '-' }]);
  const [lista_usuarios, setLista_usuarios] = useState([{ id: 0, nome: '-' }]);
  const [lista_anos, setLista_anos] = useState([]);

  const [usu_responsavel, setUsu_responsavel] = useState(0);
  const [met_meta, setMet_meta] = useState("");
  const [met_descricao, setMet_descricao] = useState("");

  //Ler dados
  useEffect(() => {
    const data = {
      met_inicio,
      met_setor
    };
    api.post('/metas/LerMetas.php', JSON.stringify(data))
      .then(function (rp) {
        setLista_metas(rp.data.lista_metas);
        setLista_usuarios(rp.data.lista_usuarios);
        setLista_anos(rp.data.lista_anos);
      })
      .catch(function (error) {
        if (error.response.data === "EXPIRED") {
          logout();
        } else {
          console.log(error);
        }
      });
  }, [met_inicio, met_setor]);

  //Definir semanas
  function definirSemanas(ano, mes) {
    //Número de semanas no mês
    const numeroSemanas = calcularSemanas(ano, mes);

    //Primeira segunda-feira do mês
    const segunda = primeiraSegunda(ano, mes);

    let semanas = [{
      semana: 1,
      inicio: fdata(segunda),
      fim: fdata(somarDias(segunda, 6))
    }];

    for (let i = 2; i <= numeroSemanas; i++) {
      semanas.push({
        semana: i,
        inicio: fdata(somarDias(segunda, 7 * (i - 1))),
        fim: fdata(somarDias(segunda, 6 * i + (i - 1)))
      });
    }

    return semanas;

  }

  //Formatar data
  function fdata(data) {
    const data_formatada = data.toISOString().split('T')[0];
    return data_formatada;
  }

  //Data por extenso
  function dataPorExtenso(data) {
    const meses = [
      "janeiro", "fevereiro", "março", "abril",
      "maio", "junho", "julho", "agosto",
      "setembro", "outubro", "novembro", "dezembro"
    ];
    const dia = data.getUTCDate();
    const mes = meses[data.getUTCMonth()];
    const ano = data.getUTCFullYear();
    return `${dia} de ${mes} de ${ano}`;
  }

  //Definir primeira segunda-feira do mês
  function primeiraSegunda(ano, mes) {
    const data = new Date(ano, mes, 1); // Primeiro dia do mês
    while (data.getUTCDay() !== 1) { // Enquanto não for segunda
      data.setDate(data.getUTCDate() + 1); // Avança um dia
    }
    return data;
  }

  //Definir a data da segunda-feira anterior
  function segundaAnterior(data) {
    var day = data.getUTCDay();
    var prevMonday = new Date();
    if (data.getDay() === 0) {
      prevMonday.setDate(data.getUTCDate() - 7);
    }
    else {
      prevMonday.setDate(data.getUTCDate() - (day - 1));
    }
    return prevMonday;
  }

  //Somar dias a uma data
  function somarDias(dataInicial, diasParaAdicionar) {
    const data = new Date(dataInicial);
    data.setDate(data.getDate() + diasParaAdicionar);
    return data;
  }

  //Subtrair dias a uma data
  function subtrairDias(dataInicial, diasParaSubtrair) {
    const data = new Date(dataInicial);
    data.setDate(data.getDate() - diasParaSubtrair);
    return data;
  }

  //Calcular quantas semanas há no mês
  function calcularSemanas(ano, mes) {
    let contador = 0;
    const data = new Date(ano, mes, 1); // Primeiro dia do mês
    // Obter o número de dias no mês
    const diasNoMes = new Date(ano, mes + 1, 0).getUTCDate();
    for (let dia = 1; dia <= diasNoMes; dia++) {
      data.setDate(dia); // Define o dia atual
      if (data.getUTCDay() === 1) { // Verifica se é segunda-feira
        contador++;
      }
    }
    return contador;
  }

  //Anterior
  function anterior() {
    const inicio = new Date(fdata(subtrairDias(met_inicio, 7)));
    const fim = new Date(fdata(somarDias(inicio, 6)));
    //Verificar se o ano consta na lista de anos
    if (lista_anos.includes(inicio.getUTCFullYear())) {
      setMet_inicio(fdata(inicio));
      setMet_fim(fdata(fim));
      //Se mudar o mês
      if (met_mes !== inicio.getUTCMonth()) {
        setMet_mes(inicio.getUTCMonth());
        setMet_ano(inicio.getUTCFullYear());
        setLista_semanas(definirSemanas(inicio.getUTCFullYear(), inicio.getUTCMonth()));
      }
    }
  }

  //Próximo
  function proximo() {
    const inicio = new Date(fdata(somarDias(met_inicio, 7)));
    const fim = new Date(fdata(somarDias(inicio, 6)));
    if (lista_anos.includes(inicio.getUTCFullYear())) {
      setMet_inicio(fdata(inicio));
      setMet_fim(fdata(fim));
      //Se mudar o mês
      if (met_mes !== inicio.getUTCMonth()) {
        setMet_mes(inicio.getUTCMonth());
        setMet_ano(inicio.getUTCFullYear());
        setLista_semanas(definirSemanas(inicio.getUTCFullYear(), inicio.getUTCMonth()));
      }
    }
  }

  //Mês alterado
  function alterarMes(novoMes) {
    //Primeira segunda-feira do mês
    const novoInicio = primeiraSegunda(met_ano, novoMes);
    setMet_inicio(fdata(novoInicio));
    setMet_fim(fdata(somarDias(novoInicio, 6)));
    setMet_mes(novoMes);
    setLista_semanas(definirSemanas(met_ano, novoMes));
  }

  //Ano alterado
  function alterarAno(novoAno) {
    //Primeira segunda-feira do mês
    const novoInicio = primeiraSegunda(novoAno, met_mes);
    setMet_inicio(fdata(novoInicio));
    setMet_fim(fdata(somarDias(novoInicio, 6)));
    setMet_ano(novoAno);
    setLista_semanas(definirSemanas(novoAno, met_mes));
  }

  //Salvar dados
  async function salvarMeta() {
    alert("Salvar Meta");
  }

  return (
    <div id="metas">
      <NavSuperior />
      <Divider borderColor={'gray.400'} orientation='horizontal' mt={2} mb={2} />
      <Heading size='lg' color={'gray.600'} textAlign={'center'}>
        METAS
      </Heading>
      <Divider borderColor={'gray.400'} orientation='horizontal' mt={2} mb={2} />
      <Container maxW={'95%'} mb={20}>

        <Grid
          templateAreas={`"header semana"
            "nav main"
            "nav footer"`}
          gridTemplateRows={'1fr'}
          gridTemplateColumns={'150px 1fr'}
          gap='1'
        >
          <GridItem pl='2' bg='gray.100' area={'header'}>
            <Center h={10}>SETOR</Center>
          </GridItem>
          <GridItem pl='2' pr='2' bg='gray.100' area={'semana'}>
            <Flex>
              <Center w='150px' mr={1}>
                <Select textAlign={'center'} variant='flushed' borderColor={'gray'} size='sm' value={met_ano} onChange={e => alterarAno(e.target.value)}>
                  {lista_anos.map((item) => {
                    return (
                      <option value={item}>{item}</option>
                    );
                  })}
                </Select>
              </Center>
              <Center w='200px' mr={2}>
                <Select textAlign={'center'} variant='flushed' borderColor={'gray'} size='sm' value={met_mes} onChange={e => alterarMes(e.target.value)}>
                  <option value={0}>Janeiro</option>
                  <option value={1}>Fevereiro</option>
                  <option value={2}>Março</option>
                  <option value={3}>Abril</option>
                  <option value={4}>Maio</option>
                  <option value={5}>Junho</option>
                  <option value={6}>Julho</option>
                  <option value={7}>Agosto</option>
                  <option value={8}>Setembro</option>
                  <option value={9}>Outubro</option>
                  <option value={10}>Novembro</option>
                  <option value={11}>Dezembro</option>
                </Select>
              </Center>
              <Center w='80px' _hover={{ bg: 'gray.400' }} cursor={'pointer'} onClick={e => anterior()}>
                <ChevronLeftIcon boxSize={10} />
              </Center>
              <Box flex='1'>
                <SimpleGrid columns={lista_semanas.length}>
                  {lista_semanas.map((item) => {
                    return (
                      <Button w={'100%'} fontWeight={'normal'} borderRadius={0} variant={'unstyled'} h='10' bg={met_inicio === item.inicio ? 'white' : 'gray.100'} onClick={e => { setMet_inicio(item.inicio); setMet_fim(item.fim); }}>{"Semana 0" + item.semana}</Button>
                    );
                  })}
                </SimpleGrid>
              </Box>
              <Center w='80px' _hover={{ bg: 'gray.400' }} cursor={'pointer'} onClick={e => proximo()}>
                <ChevronRightIcon boxSize={10} />
              </Center>
            </Flex>
          </GridItem>
          <GridItem pl='2' bg='gray.100' area={'nav'}>
            {lista_setores.map((item) => {
              return (
                <Button fontWeight={'normal'} borderRadius={0} variant={'unstyled'} w={'100%'} h='10' textAlign={'right'} pr={5} cursor={'pointer'} bg={met_setor === item ? 'white' : 'gray.100'} onClick={e => setMet_setor(item)}>{item}</Button>
              );
            })}
          </GridItem>
          <GridItem pl='2' area={'main'}>
            <Text textAlign={'center'} m={2}>De {dataPorExtenso(new Date(met_inicio))} a {dataPorExtenso(new Date(met_fim))}</Text>
            <Box flex={1} mr={10} ml={10}>
              <Accordion allowMultiple display={lista_metas[0]['id']===0?'none':'block'}>
                {lista_metas.map((item, i) => {
                  return (
                    <>
                      <AccordionItem>
                        <h2>
                          <AccordionButton>
                            <Box as="span" flex='1'>
                              <Flex>
                                <Center w='150px'>
                                  <Tag colorScheme='yellow' display={'flex'}>{item.situacao}</Tag>
                                </Center>
                                <Box flex='1' textAlign={'left'}>
                                  <Text as='em'>{item.titulo}</Text>
                                </Box>
                              </Flex>
                            </Box>
                            <AccordionIcon />
                          </AccordionButton>
                        </h2>
                        <AccordionPanel mr={4} ml={4} mb={4}>
                          <Flex>
                            <Box flex='1'>
                              <Flex>
                                <Box w={'400px'}>
                                  <Stack spacing={3} direction='row'><Text whiteSpace='nowrap'>Responsável</Text>
                                    <Text>{item.responsavel}</Text>
                                  </Stack>
                                </Box>
                                <Box flex='1'>
                                  <Stack spacing={3} direction='row' ml={4}>
                                    <Text whiteSpace='nowrap'>Proponente</Text>
                                    <Text whiteSpace='nowrap' flex='1' border={'gray.200'} borderBottomWidth={1}>{item.proponente}</Text>
                                  </Stack>
                                </Box>
                              </Flex>
                              <Box flex='1' border={'gray.200'} borderWidth={1} mt={4}>
                                <Text p={3} fontSize={'sm'}>{item.descricao}</Text>
                              </Box>
                            </Box>
                            <Center w='100px' alignItems={'end'} mb={3}>
                              <Stack spacing={3} direction='column'>
                                <Button colorScheme="teal" variant={'outline'}><DeleteIcon /></Button>
                                <Button colorScheme="teal" variant={'outline'}><EditIcon /></Button></Stack>
                            </Center>
                          </Flex>
                          <Flex>
                            <Box flex='1' p={3}>
                              <Text w={'100%'} fontSize={'sm'}>{item.observacao}</Text>
                            </Box>
                            <Center w='100px' alignItems={'end'} mb={3}>
                              <Stack spacing={3} direction='column'>
                                <Button colorScheme="teal" variant={'outline'}><EditIcon /></Button></Stack>
                            </Center>
                          </Flex>
                        </AccordionPanel>
                      </AccordionItem>
                    </>
                  );
                })}
              </Accordion>
              <Button colorScheme='gray' variant={'ghost'} w={'100%'} mt={3} onClick={onNovaOpen}><PlusSquareIcon mr={5} />Adicionar Meta</Button>
            </Box>
          </GridItem >

          <GridItem pl='2' area={'footer'} h='10' bg={'none'}>
            metas atrasadas
          </GridItem>
        </Grid>

      </Container>

      {/*MODAL NOVA META*/}
      <Modal isOpen={isNovaOpen} onClose={onNovaClose} isCentered size='6xl'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign='center'>ADICIONAR META</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box p={8}>
              <Stack spacing={3} direction='row'><Text>Meta</Text><Input autoComplete="no" borderColor={'gray'} size='sm' value={met_meta} onChange={e => setMet_meta(e.target.value)} /></Stack>
              <Box pl={12} mt={4} mb={4}>
                <Textarea borderColor={'gray'} bg={'white'}
                  p={3}
                  borderRadius={0}
                  minH="unset"
                  overflow="hidden"
                  resize="none"
                  minRows={3}
                  as={ResizeTextarea}
                  placeholder="Descrição da meta..."
                  value={met_descricao}
                  onChange={e => setMet_descricao(e.target.value)}
                  textAlign={'justify'}
                />
              </Box>

              <SimpleGrid columns={2} >
                <Stack spacing={3} direction='row' w={'90%'}><Text whiteSpace='nowrap'>Setor</Text>
                  <Select borderColor={'gray'} size='sm' value={met_setor} onChange={e => setMet_setor(e.target.value)}>
                    <option value={''}>-</option>
                    {lista_setores.map((item) => {
                      return (
                        <option value={item}>{item}</option>
                      );
                    })}
                  </Select>
                </Stack>
                <Stack spacing={3} direction='row'><Text whiteSpace='nowrap'>Responsável</Text>
                  <Select variant='flushed' borderColor={'gray'} size='sm' value={usu_responsavel} onChange={e => setUsu_responsavel(e.target.value)}>
                    <option id={0} value={0}></option>
                    {lista_usuarios.map((item, i) => {
                      return (
                        <option id={i + 1} value={item.id}>{item.nome}</option>
                      );
                    })}
                  </Select>
                </Stack>
              </SimpleGrid>
            </Box>

          </ModalBody>
          <ModalFooter>
            <Spacer />
            <Button colorScheme="teal" variant={'outline'} onClick={e => salvarMeta()}>Concluir <SaveIcon ml={2} /></Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

    </div>
  );
}

export default MetasPage;