import { createIcon } from '@chakra-ui/icons'

// using `path`
export const FinanceiroIcon = createIcon({
  displayName: 'FinanceiroIcon',
  viewBox: '0 0 32 32',
  // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
  path: [
    <path
      fill='currentColor'
      stroke='currentColor'
      strokeWidth='0'
      strokeLinecap='round'
      strokeLinejoin='round'
      d="M31,7H1A1,1,0,0,0,0,8V24a1,1,0,0,0,1,1H31a1,1,0,0,0,1-1V8A1,1,0,0,0,31,7ZM25.09,23H6.91A6,6,0,0,0,2,18.09V13.91A6,6,0,0,0,6.91,9H25.09A6,6,0,0,0,30,13.91v4.18A6,6,0,0,0,25.09,23ZM30,11.86A4,4,0,0,1,27.14,9H30ZM4.86,9A4,4,0,0,1,2,11.86V9ZM2,20.14A4,4,0,0,1,4.86,23H2ZM27.14,23A4,4,0,0,1,30,20.14V23Z"
    />,
    <path
      fill='currentColor'
      stroke='currentColor'
      strokeWidth='0'
      strokeLinecap='round'
      strokeLinejoin='round'
      d="M7.51.71a1,1,0,0,0-.76-.1,1,1,0,0,0-.61.46l-2,3.43a1,1,0,0,0,1.74,1L7.38,2.94l5.07,2.93a1,1,0,0,0,1-1.74Z"
    />,
    <path
      fill='currentColor'
      stroke='currentColor'
      strokeWidth='0'
      strokeLinecap='round'
      strokeLinejoin='round'
      d="M24.49,31.29a1,1,0,0,0,.5.14.78.78,0,0,0,.26,0,1,1,0,0,0,.61-.46l2-3.43a1,1,0,1,0-1.74-1l-1.48,2.56-5.07-2.93a1,1,0,0,0-1,1.74Z"
    />,
    <path
      fill='currentColor'
      stroke='currentColor'
      strokeWidth='1'
      strokeLinecap='round'
      strokeLinejoin='round'
      d="M16,10a6,6,0,1,0,6,6A6,6,0,0,0,16,10Zm0,10a4,4,0,1,1,4-4A4,4,0,0,1,16,20Z"
    />    
  ]
});
