import React from "react";
import { useContext, useState, useEffect } from "react";
import 'leaflet/dist/leaflet.css';
import { Text, Button, Center, Container, Heading, Flex, Spacer, ButtonGroup, Box, TableContainer, Table, Thead, Tr, Th, Td, Tbody, Stack, Collapse, useDisclosure, SimpleGrid, Grid, GridItem, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Input, ModalFooter, Divider, useToast, Textarea, AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogBody, AlertDialogFooter } from '@chakra-ui/react';
import { useNavigate } from "react-router-dom";
import { PlusSquareIcon, ChevronLeftIcon, DeleteIcon, EditIcon, TriangleDownIcon, NotAllowedIcon } from "@chakra-ui/icons";
import { NavigateIcon } from "../../icons/navigateicon";
import { BarCodeIcon } from "../../icons/barcodeicon";

import ContratoPDF from "./contratopdf";
import ProcuracaoPDF from "./procuracaopdf";
import RequerimentoPDF from "./requerimentopdf";
import DistratoPDF from "./distratopdf";
import SigiloPDF from "./sigilopdf";

import { api } from "../../../services/api";
import Comentarios from "../../components/Comentario/comentarios";
import Fase from "../../components/Fase/fase";

import { AuthContext } from "../../contexts/auth";
import { ReurbContext } from "../../contexts/reurb";
import { MatriculaIcon } from "../../icons/matriculaicon";

const ExibirProcesso = () => {
  const { logout, user } = useContext(AuthContext);
  const { rem_id, nui_id, setNui_id, unid_id, setReq_id, setOcup_id, setEditar_processo, comentario_alterado, setComentario_alterado } = useContext(ReurbContext);

  //Transitions  
  const { isOpen, onToggle } = useDisclosure();

  //Variáveis Chakra UI
  const navigate = useNavigate();
  const toast = useToast();
  const cancelRef = React.useRef();
  const { isOpen: isSituacaoOpen, onOpen: onSituacaoOpen, onClose: onSituacaoClose } = useDisclosure();
  const { isOpen: isCodigoOpen, onOpen: onCodigoOpen, onClose: onCodigoClose } = useDisclosure();
  const { isOpen: isMensagemOpen, onOpen: onMensagemOpen, onClose: onMensagemClose } = useDisclosure();
  const { isOpen: isDistratoOpen, onOpen: onDistratoOpen, onClose: onDistratoClose } = useDisclosure();
  const { isOpen: isExcluirOpen, onOpen: onExcluirOpen, onClose: onExcluirClose } = useDisclosure();
  const { isOpen: isMatriculaOpen, onOpen: onMatriculaOpen, onClose: onMatriculaClose } = useDisclosure();

  //Variáveis do processo
  const [unid_cod, setUnid_cod] = useState("");
  const [unid_areacomprovante, setUnid_areacomprovante] = useState("");
  const [reurb_modalidade, setReurb_modalidade] = useState("");
  const [reurb_situacao, setReurb_situacao] = useState("");
  const [reurb_fase, setReurb_fase] = useState("");
  const [localizacao, setLocalizacao] = useState("");
  const [reurb_instrumento, setReurb_instrumento] = useState("");
  const [reurb_objeto, setReurb_objeto] = useState("");
  const [reurb_matricula, setReurb_matricula] = useState("");
  const [matricula_emitida, setMatricula_emitida] = useState("");
  const [especial, setEspecial] = useState("");
  const [unid_historico, setUnid_historico] = useState("");

  //Qualificações
  const [quali_crf, setQuali_crf] = useState("");

  //Variáveis do núcleo
  const [nui_cod, setNui_cod] = useState("");
  const [nui_nome, setNui_nome] = useState("");

  //Lista de requerentes e ocupantes
  const [lista_requerentes, setLista_requerentes] = useState([{ id: 0, nome: '-', tipo: '-', beneficiario: '-', categoria: '-', representante: '-', contato: '-' }]);
  const [lista_ocupantes, setLista_ocupantes] = useState([{ id: 0, nome: '-', parentesco: '-' }]);

  //Variáveis alterar situação
  const [novasit, setNovasit] = useState("");
  const [motivo_situacao, setMotivo_situacao] = useState("");

  //Variáveis alterar código
  const [msg, setMsg] = useState("");
  const [novocod, setNovocod] = useState("");

  //Variável imprimir
  const [msg_imprimir, setMsg_imprimir] = useState("");

  //Distrato
  const [tipo_distrato, setTipo_distrato] = useState("");
  const [dis_valor, setDis_valor] = useState("");
  const [dis_qtd_parcelas, setDis_qtd_parcelas] = useState("");
  const [dis_valor_parcela, setDis_valor_parcela] = useState("");
  const [dis_dia, setDis_dia] = useState("");
  const [dis_comarca, setDis_comarca] = useState("");

  //Ler dados processo
  useEffect(() => {
    //Dados do Processo
    api.post('/processo/LerExibirProcesso.php', JSON.stringify({ unid_id }))
      .then(function (rp) {
        setUnid_cod(rp.data.unid_cod);
        setUnid_areacomprovante(rp.data.unid_areacomprovante);
        setReurb_modalidade(rp.data.reurb_modalidade);
        setReurb_situacao(rp.data.reurb_situacao);
        setNovasit(rp.data.reurb_situacao);
        setReurb_fase(rp.data.reurb_fase);
        setLocalizacao(rp.data.localizacao);
        setReurb_instrumento(rp.data.reurb_instrumento);
        setReurb_objeto(rp.data.reurb_objeto);
        setReurb_matricula(rp.data.reurb_matricula);
        setEspecial(rp.data.especial);
        setUnid_historico(rp.data.unid_historico);
        setQuali_crf(rp.data.quali_crf);
        setNui_id(rp.data.nui_id);
        setNui_cod(rp.data.nui_cod);
        setNui_nome(rp.data.nui_nome);

        setDis_valor(rp.data.dis_valor);
        setDis_qtd_parcelas(rp.data.dis_qtd_parcelas);
        setDis_valor_parcela(rp.data.dis_valor_parcela);
        setDis_dia(rp.data.dis_dia);
        setDis_comarca(rp.data.dis_comarca);

      })
      .catch(function (error) {
        if (error.response.data === "EXPIRED") {
          logout();
        } else {
          console.log(error);
        }
      });

    //Lista de Requerentes
    api.post('/processo/LerListaRequerentes.php', JSON.stringify({ unid_id }))
      .then(function (rp) {
        setLista_requerentes(rp.data.lista_requerentes);
      })
      .catch(function (error) {
        if (error.response.data === "EXPIRED") {
          logout();
        } else {
          console.log(error);
        }
      });

    //Lista de Ocupantes
    api.post('/processo/LerListaOcupantes.php', JSON.stringify({ unid_id }))
      .then(function (rp) {
        setLista_ocupantes(rp.data.lista_ocupantes);
      })
      .catch(function (error) {
        if (error.response.data === "EXPIRED") {
          logout();
        } else {
          console.log(error);
        }
      });
  }, []);

  //Abrir mensagem imprimir
  useEffect(() => {
    //Exibir mensagem quando mudar
    if (msg_imprimir !== '') {
      onMensagemOpen();
    }
  }, [msg_imprimir]);

  //Salvar distrato
  async function salvarDistrato() {
    const data = {
      rem_id,
      unid_id,
      dis_valor,
      dis_qtd_parcelas,
      dis_valor_parcela,
      dis_dia,
      dis_comarca
    }
    await api.post('/processo/SalvarDistrato.php', JSON.stringify(data));      
  }

  //Calcular valor da parcela do distrato
  function valorParcelaDistrato(valor_global, qtd_parcelas) {
    if (valor_global !== null && qtd_parcelas !== null) {
      valor_global = valor_global.replace(",", ".");
      qtd_parcelas = qtd_parcelas.replace(",", ".");
      if (parseFloat(valor_global) > 0 && parseFloat(qtd_parcelas) > 0) {
        const valor = String((parseFloat(valor_global) / parseFloat(qtd_parcelas)).toFixed(2));
        setDis_valor_parcela(valor.replace(".", ","));
      } else {
        setDis_valor_parcela("");
      }
    }

  }

  //Alterar situação
  async function alterarSituacao() {
    if (novasit !== reurb_situacao) {
      const data = {
        user,
        rem_id,
        unid_id,
        reurb_situacao,
        novasit,
        motivo_situacao
      }
      await api.post('/processo/AlterarSituacao.php', JSON.stringify(data))
        .then(function (rp) {
          if (rp.data.sucesso === true) {
            setReurb_situacao(novasit);
            onSituacaoClose();
            if (comentario_alterado === 0) {
              setComentario_alterado(1);
            } else {
              setComentario_alterado(0);
            }
          }
          return (toast({
            position: 'bottom-left', render: () => (<Box color='white' p={3} bg={rp.data.sucesso === true ? 'teal' : 'red'}>{rp.data.msg}</Box>),
          })
          );
        })
        .catch(function (error) {
          if (error.response.data === "EXPIRED") {
            logout();
          } else {
            console.log(error);
            return (toast({
              position: 'bottom-left', render: () => (<Box color='white' p={3} bg='red'>Erro: não foi possível registrar os dados!</Box>),
            })
            );
          }
        });
    }
  }

  //Alterar código
  function alterarCodigo() {
    if (novocod !== "") {
      const data = {
        user,
        rem_id,
        unid_id,
        unid_cod,
        novocod
      }
      api.post('/processo/AlterarCodigo.php', JSON.stringify(data))
        .then(function (rp) {
          setMsg(rp.data.msg);
          if (rp.data.msg === "") {
            setUnid_cod(novocod);
            setNovocod("");
            if (comentario_alterado === 0) {
              setComentario_alterado(1);
            } else {
              setComentario_alterado(0);
            }
            onCodigoClose();
          }
        })
        .catch(function (error) {
          if (error.response.data === "EXPIRED") {
            logout();
          } else {
            console.log(error);
          }
        });
    }
  }

  //Salvar matrícula
  async function salvarMatricula() {
    const data = {
      user,
      rem_id,
      unid_id,
      unid_cod,
      matricula_emitida
    }
    await api.post('/processo/SalvarMatricula.php', JSON.stringify(data))
      .then(function (rp) {
        if (rp.data.sucesso === true) {
          setReurb_matricula(rp.data.matricula);
          onMatriculaClose();
          if (comentario_alterado === 0) {
            setComentario_alterado(1);
          } else {
            setComentario_alterado(0);
          }
        }
        return (toast({
          position: 'bottom-left', render: () => (<Box color='white' p={3} bg={rp.data.sucesso === true ? 'teal' : 'red'}>{rp.data.msg}</Box>),
        })
        );
      })
      .catch(function (error) {
        if (error.response.data === "EXPIRED") {
          logout();
        } else {
          console.log(error);
          return (toast({
            position: 'bottom-left', render: () => (<Box color='white' p={3} bg='red'>Erro: não foi possível registrar os dados!</Box>),
          })
          );
        }
      });
  }

  //Novo Requerente
  async function novoRequerente(tipo) {
    const data = {
      rem_id,
      unid_id,
      tipo,
    }
    await api.post('/requerente/NovoRequerente.php', JSON.stringify(data))
      .catch(function (error) {
        if (error.response.data === "EXPIRED") {
          logout();
        } else {
          console.log(error);
        }
      });

    //Atualizar lista de Requerentes
    await api.post('/processo/LerListaRequerentes.php', JSON.stringify({ unid_id }))
      .then(function (rp) {
        setLista_requerentes(rp.data.lista_requerentes);
      })
      .catch(function (error) {
        if (error.response.data === "EXPIRED") {
          logout();
        } else {
          console.log(error);
        }
      });
  }

  //Novo Ocupante
  async function novoOcupante() {
    const data = {
      rem_id,
      unid_id
    }
    await api.post('/ocupante/NovoOcupante.php', JSON.stringify(data))
      .catch(function (error) {
        if (error.response.data === "EXPIRED") {
          logout();
        } else {
          console.log(error);
        }
      });

    //Atualizar lista de Requerentes
    await api.post('/processo/LerListaOcupantes.php', JSON.stringify({ unid_id }))
      .then(function (rp) {
        setLista_ocupantes(rp.data.lista_ocupantes);
      })
      .catch(function (error) {
        if (error.response.data === "EXPIRED") {
          logout();
        } else {
          console.log(error);
        }
      });
  }

  //Excluir processo
  async function excluirProcesso() {
    const data = {
      rem_id,
      unid_id
    }
    await api.post('/processo/ExcluirProcesso.php', JSON.stringify(data))
      .then(function (rp) {
        //Ir para processo
        navigate('/processos');
      })
      .catch(function (error) {
        if (error.response.data === "EXPIRED") {
          logout();
        } else {
          console.log(error);
        }
      });
  }

  //Ir para lista de processos
  function goToProcessos() {
    navigate('/processos');
  }

  //Ir para requerente
  function irParaRequerente(id) {
    if (id > 0) {
      setReq_id(id);
      navigate('/requerente');
    }
  }

  //Ir para ocupante
  function irParaOcupante(id) {
    if (id > 0) {
      setOcup_id(id);
      navigate('/ocupante');
    }
  }

  //Ir para núcleo
  function irParaNucleo() {
    if (nui_id > 0) {
      navigate('/nucleo');
    } else {
      navigate('/nucleos');
    }
  }

  //Ir para modalidade
  function irParaModalidade() {
    navigate('/modalidade');
  }

  return (
    <div id="exibir_processo">
      <Container maxW={'95%'} mb={20}>       

        <Flex direction='row' w={'100%'} h={'60px'} bg={'gray.200'}>
					<Center w={'100px'} h={'100%'} color={'gray.600'} cursor='pointer' _hover={{ color: 'teal' }} onClick={e => goToProcessos()}>
						<ChevronLeftIcon boxSize={12} />
					</Center>
					<Center>
						<Heading size={'md'} color={'gray.700'} ml={3} noOfLines={1}>Processo {unid_cod} - {reurb_modalidade} - {nui_cod}</Heading>
					</Center>
					<Spacer />
					<Center>
						<ButtonGroup gap='4' mr={4}>
            <Button colorScheme="teal" variant='outline' onClick={onToggle}>Imprimir <TriangleDownIcon ml={2} /></Button>
              <Button colorScheme="teal" variant='outline' onClick={e => setEditar_processo(1)}>Editar<EditIcon ml={2} /></Button>
              <Button colorScheme="teal" variant='outline' onClick={e => onExcluirOpen()}>Excluir<DeleteIcon ml={2} /></Button>
						</ButtonGroup>
					</Center>
				</Flex>

        {/*COLLAPSE IMPRIMIR*/}
        <Collapse in={isOpen} animateOpacity >
          <Stack
            p={3}
            bg='gray.300'
            shadow='md'
            align={'center'}
            dir="column"
          >
            <ButtonGroup gap='4'>
              <Button colorScheme="gray" w={'200px'} h={'70px'} whiteSpace={'normal'} onClick={e => ContratoPDF(unid_id, setMsg_imprimir, 'open')}>Contrato</Button>
              <Button colorScheme="gray" w={'200px'} h={'70px'} whiteSpace={'normal'} onClick={e => ProcuracaoPDF(unid_id, setMsg_imprimir, 'open')}>Procuração</Button>
              <Button colorScheme="gray" w={'200px'} h={'70px'} whiteSpace={'normal'} onClick={e => RequerimentoPDF(unid_id, setMsg_imprimir, 'open')}>Requerimento</Button>
              <Button colorScheme="gray" w={'200px'} h={'70px'} whiteSpace={'normal'} onClick={e => SigiloPDF(unid_id, setMsg_imprimir)}>Sigilo</Button>
            </ButtonGroup>
            <ButtonGroup gap='4' mt={4}>
              <Button colorScheme="gray" w={'200px'} h={'70px'} whiteSpace={'normal'} onClick={e => DistratoPDF(unid_id, "basico", setMsg_imprimir)}>
                <Stack dir="row">
                  <Text>Distrato</Text>
                  <Text fontSize={'sm'} color={'gray.500'}>Básico</Text>
                </Stack>
              </Button>
              <Button colorScheme="gray" w={'200px'} h={'70px'} whiteSpace={'normal'} onClick={e => {setTipo_distrato('multa'); onDistratoOpen();}}>
                <Stack dir="row">
                  <Text>Distrato</Text>
                  <Text fontSize={'sm'} color={'gray.500'}>Multa</Text>
                </Stack>
              </Button>
              <Button colorScheme="gray" w={'200px'} h={'70px'} whiteSpace={'normal'} onClick={e => {setTipo_distrato('extorno'); onDistratoOpen();}}>
                <Stack dir="row">
                  <Text>Distrato</Text>
                  <Text fontSize={'sm'} color={'gray.500'}>Extorno</Text>
                </Stack>
              </Button>
            </ButtonGroup>
          </Stack>
        </Collapse>

        <Fase />

        <Grid
          mt={5}
          templateRows='repeat(1, 1fr)'
          templateColumns='repeat(12, 1fr)'
          gap={10}
        >
          <GridItem colSpan={5}>
            <SimpleGrid rows={4} spacing={5}>
              <Flex direction="column" background={'gray.200'} p={4}>
                <Heading as='h4' size='md' color={'gray.600'}>Imóvel</Heading>
                <Grid
                  templateRows='repeat(1, 1fr)'
                  templateColumns='repeat(12, 1fr)'
                  gap={10}
                >
                  <GridItem colSpan={7}>
                    <Text>Processo {unid_cod}</Text>
                    <Text color={reurb_objeto === "Objeto Não Informado" ? 'red' : 'black'}>{reurb_objeto}</Text>
                    <Text color={unid_areacomprovante === "Área Não Informada" ? 'red' : 'black'}>{unid_areacomprovante}</Text>
                    <Text display={reurb_matricula === "" ? 'block' : 'none'} color={'red'}>Matrícula Pendente</Text>
                    <Text display={reurb_matricula !== "" ? 'block' : 'none'}>{'Matrícula ' + reurb_matricula}</Text>
                  </GridItem>
                  <GridItem colSpan={5} textAlign={'right'} pr={2}>
                    <Button colorScheme="teal" variant='outline' mb={3} w={'150px'} onClick={onCodigoOpen}>Código<BarCodeIcon ml={2} /></Button>
                    <Button colorScheme="teal" variant='outline' w={'150px'} onClick={onMatriculaOpen}>Matrícula<MatriculaIcon ml={2} /></Button>
                  </GridItem>
                </Grid>
              </Flex>
              <Flex direction="column" background={'gray.200'} p={4}>
                <Heading as='h4' size='md' color={'gray.600'}>Localização</Heading>
                <Text color={localizacao === "Dados Incompletos" ? 'red' : 'black'} pr={'150px'}>{localizacao}</Text>
              </Flex>
              <Flex direction="column" background={'gray.200'} p={4}>
                <Heading as='h4' size='md' color={'gray.600'}>Reurb</Heading>
                <Grid
                  templateRows='repeat(1, 1fr)'
                  templateColumns='repeat(12, 1fr)'
                  gap={10}
                >
                  <GridItem colSpan={7}>
                    <Text display={reurb_situacao === "Situação Não Informada" ? 'block' : 'none'} color={'red'}>{reurb_situacao}</Text>
                    <Text display={reurb_situacao === "Situação Não Informada" ? 'none' : 'block'}>{'Situação ' + reurb_situacao}</Text>
                    <Text color={reurb_fase === "Fase Não Informada" ? 'red' : 'black'}>{reurb_fase}</Text>
                    <Text color={reurb_modalidade === "Sem Modalidade" ? 'red' : 'black'}>{reurb_modalidade}</Text>
                    <Text color={reurb_instrumento === "Instrumento Não Informado" ? 'red' : 'black'}>{reurb_instrumento}</Text>
                  </GridItem>
                  <GridItem colSpan={5} textAlign={'right'} pr={2}>
                    <Button colorScheme="teal" variant='outline' mb={3} w={'150px'} onClick={onSituacaoOpen}>Situação<NotAllowedIcon ml={2} /></Button>
                    <Button colorScheme="teal" variant='outline' w={'150px'} onClick={e => irParaModalidade()}>Modalidade<NavigateIcon ml={2} /></Button>
                  </GridItem>
                </Grid>
              </Flex>
              <Flex direction="column" background={'gray.200'} p={4}>
                <Heading as='h4' size='md' color={'gray.600'}>Núcleo</Heading>
                <Grid
                  templateRows='repeat(1, 1fr)'
                  templateColumns='repeat(12, 1fr)'
                  gap={10}
                >
                  <GridItem colSpan={7}>
                    <Text display={nui_cod === "Sem Núcleo" ? 'block' : 'none'} color={nui_cod === "Sem Núcleo" ? 'red' : 'black'}>{nui_cod}</Text>
                    <Text display={nui_cod === "Sem Núcleo" ? 'none' : 'blcok'}>{nui_nome + " (" + nui_cod + ")"}</Text>
                  </GridItem>
                  <GridItem colSpan={5} textAlign={'right'} pr={2}>
                    <Button colorScheme="teal" variant='outline' w={'150px'} onClick={e => irParaNucleo()}>Núcleo<NavigateIcon ml={2} /></Button>
                  </GridItem>
                </Grid>
              </Flex>
            </SimpleGrid>
          </GridItem>
          <GridItem colSpan={7}>
            <Heading as='h4' size='md' mb={2} color={'gray.600'}>Requerentes</Heading>
            <TableContainer mt={4} mb={4}>
              <Table size='sm'>
                <Thead>
                  <Tr>
                    <Th borderBottomColor={'gray.300'} textAlign={'center'}>NOME</Th>
                    <Th borderBottomColor={'gray.300'} textAlign={'center'}>CATEGORIA</Th>
                    <Th borderBottomColor={'gray.300'} textAlign={'center'}>BENEFICIÁRIO</Th>
                    <Th borderBottomColor={'gray.300'} textAlign={'center'}>TIPO</Th>
                    <Th borderBottomColor={'gray.300'} textAlign={'center'}>REPRESENTANTE</Th>
                    <Th borderBottomColor={'gray.300'} textAlign={'center'}>CONTATO</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {lista_requerentes.map((item, i) => {
                    return (
                      <Tr key={i} cursor='pointer' _hover={{ bg: 'gray.200' }} onClick={e => irParaRequerente(item.id)}>
                        <Td borderBottomColor={'gray.300'} whiteSpace={'pre-wrap'} maxW={'200px'}>{item.nome}</Td>
                        <Td borderBottomColor={'gray.300'} textAlign={'center'}>{item.categoria}</Td>
                        <Td borderBottomColor={'gray.300'} textAlign={'center'}>{item.beneficiario}</Td>
                        <Td borderBottomColor={'gray.300'} textAlign={'center'}>{item.tipo}</Td>
                        <Td borderBottomColor={'gray.300'} textAlign={'center'}>{item.representante}</Td>
                        <Td borderBottomColor={'gray.300'} textAlign={'center'}>{item.telefone}</Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </TableContainer>
            <Flex>
              <Spacer />
              <ButtonGroup gap='4'>
                <Button colorScheme="gray" variant='outline' onClick={e => novoRequerente('pj')}>Pessoa Jurídica<PlusSquareIcon ml={2} /></Button>
                <Button colorScheme="gray" variant='outline' onClick={e => novoRequerente('pf')}>Pessoa Física<PlusSquareIcon ml={2} /></Button>
              </ButtonGroup>
            </Flex>
            <Heading as='h4' size='md' mt={2} color={'gray.600'}>Ocupantes</Heading>
            <TableContainer mt={4} mb={4}>
              <Table size='sm'>
                <Thead>
                  <Tr>
                    <Th borderBottomColor={'gray.300'} textAlign={'center'}>NOME</Th>
                    <Th borderBottomColor={'gray.300'} textAlign={'center'}>PARENTESCO</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {lista_ocupantes.map((item, i) => {
                    return (
                      <Tr key={i} cursor='pointer' _hover={{ bg: 'gray.200' }} onClick={e => irParaOcupante(item.id)}>
                        <Td borderBottomColor={'gray.300'}>{item.nome}</Td>
                        <Td borderBottomColor={'gray.300'} textAlign={'center'}>{item.parentesco}</Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </TableContainer>
            <Flex>
              <Spacer />
              <Button colorScheme="gray" variant='outline' onClick={e => novoOcupante()}>Ocupante<PlusSquareIcon ml={2} /></Button>
            </Flex>
          </GridItem>
        </Grid>

        <Divider borderColor={'gray.400'} orientation='horizontal' mt={4} mb={4} />
        <Heading as='h4' size='md' color={'gray.600'}>Descrição</Heading>
        <Text>{especial}</Text>
        <Text>{unid_historico}</Text>
        <Text display={unid_historico === "" && especial === "" ? 'block' : 'none'}>Sem Informação</Text>
        <Divider borderColor={'gray.400'} orientation='horizontal' mt={4} mb={4} />
        <Heading as='h4' size='md' color={'gray.600'}>Qualificação</Heading>
        <Text color={quali_crf === "Dados Incompletos" ? 'red' : 'black'}>{quali_crf}</Text>
        <Divider borderColor={'gray.400'} orientation='horizontal' mt={4} mb={4} />

        <Comentarios painel="processo" />

      </Container>

      {/*MODAL ALTERAR SITUAÇÃO*/}
      <Modal isOpen={isSituacaoOpen} onClose={onSituacaoClose} isCentered size='2xl'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign='center'>ALTERAR SITUAÇÃO DO PROCESSO</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <SimpleGrid rows={3} spacing={10} m={5}>
              <Box>
                <Center>
                  <Stack spacing={6} direction='row'>
                    <Button colorScheme={novasit === "Ativo" ? 'green' : 'blackAlpha'} w={'150px'} h={'50px'} onClick={e => setNovasit("Ativo")}>Ativo</Button>
                    <Button colorScheme={novasit === "Pendente" ? 'green' : 'blackAlpha'} w={'150px'} h={'50px'} onClick={e => setNovasit("Pendente")}><Text>Pendente</Text></Button>
                    <Button colorScheme={novasit === "Cancelado" ? 'green' : 'blackAlpha'} w={'150px'} h={'50px'} onClick={e => setNovasit("Cancelado")}><Text>Cancelado</Text></Button>
                  </Stack>
                </Center>
              </Box>
              <Box>
                <Center>
                  <Text display={reurb_situacao === novasit ? 'block' : 'none'}>A situação do processo {unid_cod} está definida como {reurb_situacao}.</Text>
                  <Text display={reurb_situacao === novasit ? 'none' : 'block'}>Tem certeza que deseja alterar a situação do processo {unid_cod} para {novasit}?</Text>
                </Center>
              </Box>
              <Box>
                <Textarea display={reurb_situacao === novasit ? 'none' : 'block'} borderColor={'gray'} w="100%" minRows={3} placeholder={'Informe o motivo da alteração da situação (obrigatório)'} value={motivo_situacao} onChange={e => setMotivo_situacao(e.target.value)} />
              </Box>
            </SimpleGrid>
          </ModalBody>
          <ModalFooter>
            <Button display={reurb_situacao === novasit ? 'none' : 'block'} colorScheme="teal" variant='outline' onClick={e => alterarSituacao()}>Alterar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/*MODAL ALTERAR CÓDIGO*/}
      <Modal isOpen={isCodigoOpen} onClose={onCodigoClose} isCentered size='2xl'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign='center'>ALTERAR CÓDIGO DO PROCESSO</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={3} direction='column'>
              <Flex direction='row' justifyContent='center'>
                <SimpleGrid columns={2} spacing={10}>
                  <Stack spacing={1} direction='column'><Text>Código Atual</Text><Input borderColor={'gray'} textAlign={'center'} size='sm' value={unid_cod} readOnly /></Stack>
                  <Stack spacing={1} direction='column'><Text>Código Novo</Text><Input borderColor={'gray'} size='sm' value={novocod} onChange={e => setNovocod(e.target.value)} textAlign={'center'} /></Stack>
                </SimpleGrid>
              </Flex>
              <Flex direction='row' justifyContent='center'><Text color='red.500'>{msg}</Text></Flex>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="teal" variant='outline' onClick={e => alterarCodigo()}>Alterar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/*ALERT MENSAGEM IMPRIMIR*/}
      <Modal closeOnEsc={false} closeOnOverlayClick={false} isOpen={isMensagemOpen} onClose={onMensagemClose} isCentered size='2xl'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Dados Faltantes</ModalHeader>
          <ModalBody>
            <Text>{msg_imprimir}</Text>
          </ModalBody>
          <ModalFooter>
            <Button onClick={e => { setMsg_imprimir(''); onMensagemClose(); }}>
              OK
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/*MODAL DISTRATO*/}
      <Modal isOpen={isDistratoOpen} onClose={onDistratoClose} isCentered size='5xl'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign='center'>{tipo_distrato === 'multa'?'DISTRATO COM COBRANÇA DE MULTA':'DISTRATO COM EXTORNO DE VALOR'}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Grid
              h='100px'
              templateRows='repeat(2, 1fr)'
              templateColumns='repeat(12, 1fr)'
              gap={4}
              mt='20px'
              ml={10}
              mr={10}
            >
              <GridItem colSpan={4} >
                <Stack spacing={3} direction='row'><Text whiteSpace={'nowrap'}>Valor Global (R$)</Text><Input borderColor={'gray'} maxLength={10} size='sm' value={dis_valor} onChange={e => { setDis_valor(e.target.value); valorParcelaDistrato(e.target.value, dis_qtd_parcelas); }} /></Stack>
              </GridItem>
              <GridItem colSpan={4} >
                <Stack spacing={3} direction='row'><Text whiteSpace={'nowrap'}>Número de Parcelas</Text><Input borderColor={'gray'} maxLength={5} size='sm' value={dis_qtd_parcelas} onChange={e => { setDis_qtd_parcelas(e.target.value); valorParcelaDistrato(dis_valor, e.target.value); }} /></Stack>
              </GridItem>
              <GridItem colSpan={4} >
                <Stack spacing={3} direction='row'><Text whiteSpace={'nowrap'}>Valor da Parcela (R$)</Text><Input borderColor={'gray'} size='sm' value={dis_valor_parcela} onChange={e => setDis_valor_parcela(e.target.value)} readOnly /></Stack>
              </GridItem>

              <GridItem colSpan={4} >
                <Stack spacing={3} direction='row'><Text whiteSpace={'nowrap'}>Dia do Vencimento</Text><Input borderColor={'gray'} maxLength={3} size='sm' value={dis_dia} onChange={e => setDis_dia(e.target.value)} /></Stack>
              </GridItem>
              <GridItem colSpan={8} >
                <Stack spacing={3} direction='row'><Text whiteSpace={'nowrap'}>Comarca do Foro</Text><Input borderColor={'gray'} maxLength={100} size='sm' value={dis_comarca} onChange={e => setDis_comarca(e.target.value)} /></Stack>
              </GridItem>
            </Grid>            
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="teal" variant='outline' onClick={e => {salvarDistrato(); DistratoPDF(unid_id, tipo_distrato, setMsg_imprimir)}}>Concluir</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/*MODAL MATRÍCULA*/}
      <Modal isOpen={isMatriculaOpen} onClose={onMatriculaClose} isCentered size='2xl'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign='center'>MATRÍCULA EMITIDA</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Center m={5}>
              <Stack spacing={3} direction='row'><Text whiteSpace='nowrap' fontSize={'lg'} mt={2}>Número da Matrícula</Text><Input autoComplete="no" borderColor={'gray'} size='lg' maxW={'300px'} value={matricula_emitida} onChange={e => setMatricula_emitida(e.target.value)} /></Stack>
            </Center>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="teal" variant='outline' onClick={e => salvarMatricula()}>Concluir</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/*MODAL EXCLUIR*/}
      <AlertDialog isOpen={isExcluirOpen} leastDestructiveRef={cancelRef.current} onClose={onExcluirClose} isCentered>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Excluir Processo
            </AlertDialogHeader>

            <AlertDialogBody>
              Tem certeza que deseja excluir o processo {unid_cod}?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef.current} onClick={onExcluirClose}>
                Cancelar
              </Button>
              <Button colorScheme='red' onClick={() => { excluirProcesso(); onExcluirClose(); }} ml={3}>
                Excluir
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

    </div>
  );
}

export default ExibirProcesso;