import React from "react";
import { useContext, useState, useEffect } from "react";
import { Grid, GridItem, Text, Input, Button, Select, Stack, Checkbox, Box, Heading, Flex, Divider, useToast, Textarea, Container, Spacer, Center, ButtonGroup } from '@chakra-ui/react';
import { ChevronLeftIcon } from "@chakra-ui/icons";
import { SaveIcon } from "../../icons/saveicon";

import ReactInputMask from "react-input-mask";

import { api } from "../../../services/api";
import { AuthContext } from "../../contexts/auth";
import { ReurbContext } from "../../contexts/reurb";

const EditarProcesso = () => {

	//Contexto
	const { logout } = useContext(AuthContext);
	const { rem_id, unid_id, setEditar_processo } = useContext(ReurbContext);

	//Variáveis Chakra UI
	const toast = useToast();

	//Variáveis do processo
	const [unid_cod, setUnid_cod] = useState("");
	const [nui_cod, setNui_cod] = useState("");
	const [reurb_modalidade, setReurb_modalidade] = useState("");

	//Variáveis Reurb
	const [reurb_objeto, setReurb_objeto] = useState("");
	const [reurb_instrumento, setReurb_instrumento] = useState("");
	const [reurb_contrato, setReurb_contrato] = useState(0);
	const [reurb_procuracao, setReurb_procuracao] = useState(0);
	const [reurb_requerimento, setReurb_requerimento] = useState(0);
	const [reurb_responsabilidade, setReurb_responsabilidade] = useState(0);
	const [reurb_distrato, setReurb_distrato] = useState(0);

	//Variáveis Unidade
	const [unid_quitado, setUnid_quitado] = useState(0);
	const [unid_inventario, setUnid_inventario] = useState(0);
	const [unid_areapublica, setUnid_areapublica] = useState(0);
	const [unid_areacomprovante, setUnid_areacomprovante] = useState("");
	const [unid_possereq, setUnid_possereq] = useState(0);
	const [unid_posseconj, setUnid_posseconj] = useState(0);
	const [unid_possefalec, setUnid_possefalec] = useState(0);
	const [unid_posseoutro, setUnid_posseoutro] = useState(0);
	const [unid_aquisicao, setUnid_aquisicao] = useState("");
	const [unid_historico, setUnid_historico] = useState("");
	const [unid_logradouro, setUnid_logradouro] = useState("");
	const [unid_numero, setUnid_numero] = useState("");
	const [unid_complemento, setUnid_complemento] = useState("");
	const [unid_bairro, setUnid_bairro] = useState("");
	const [unid_localidade, setUnid_localidade] = useState("");
	const [unid_municipio, setUnid_municipio] = useState("");
	const [unid_uf, setUnid_uf] = useState("");
	const [unid_cep, setUnid_cep] = useState("");
	const [unid_compra, setUnid_compra] = useState(0);
	const [unid_doacao, setUnid_doacao] = useState(0);
	const [unid_energia, setUnid_energia] = useState(0);
	const [unid_agua, setUnid_agua] = useState(0);
	const [unid_iptu, setUnid_iptu] = useState(0);
	const [unid_decposse, setUnid_decposse] = useState(0);
	const [unid_outrodocposse, setUnid_outrodocposse] = useState(0);
	const [unid_anuencia, setUnid_anuencia] = useState(0);
	const [unid_areamedida, setUnid_areamedida] = useState("");

	//Ler dados processo
	useEffect(() => {
		const data = {
			rem_id,
			unid_id
		}
		//Dados do Processo
		api.post('/processo/LerEditarProcesso.php', JSON.stringify(data))
			.then(function (rp) {

				setUnid_cod(rp.data.unid_cod);
				setNui_cod(rp.data.nui_cod);
				setReurb_modalidade(rp.data.reurb_modalidade);

				setReurb_objeto(rp.data.reurb_objeto);
				setReurb_instrumento(rp.data.reurb_instrumento);

				setReurb_contrato(rp.data.reurb_contrato);
				setReurb_procuracao(rp.data.reurb_procuracao);
				setReurb_requerimento(rp.data.reurb_requerimento);
				setReurb_distrato(rp.data.reurb_distrato);
				setReurb_responsabilidade(rp.data.reurb_responsabilidade);

				setUnid_areacomprovante(rp.data.unid_areacomprovante);
				setUnid_areamedida(rp.data.unid_areamedida);
				setUnid_aquisicao(rp.data.unid_aquisicao);

				setUnid_compra(rp.data.unid_compra);
				setUnid_doacao(rp.data.unid_doacao);
				setUnid_energia(rp.data.unid_energia);
				setUnid_agua(rp.data.unid_agua);
				setUnid_iptu(rp.data.unid_iptu);
				setUnid_decposse(rp.data.unid_decposse);
				setUnid_outrodocposse(rp.data.unid_outrodocposse);
				setUnid_anuencia(rp.data.unid_anuencia);

				setUnid_possereq(rp.data.unid_possereq);
				setUnid_posseconj(rp.data.unid_posseconj);
				setUnid_possefalec(rp.data.unid_possefalec);
				setUnid_posseoutro(rp.data.unid_posseoutro);

				setUnid_quitado(rp.data.unid_quitado);
				setUnid_inventario(rp.data.unid_inventario);
				setUnid_areapublica(rp.data.unid_areapublica);

				setUnid_historico(rp.data.unid_historico);

				setUnid_logradouro(rp.data.unid_logradouro);
				setUnid_numero(rp.data.unid_numero);
				setUnid_complemento(rp.data.unid_complemento);
				setUnid_bairro(rp.data.unid_bairro);
				setUnid_localidade(rp.data.unid_localidade);
				setUnid_municipio(rp.data.unid_municipio);
				setUnid_uf(rp.data.unid_uf);
				setUnid_cep(rp.data.unid_cep);

			})
			.catch(function (error) {
				if (error.response.data === "EXPIRED") {
					logout();
				} else {
					console.log(error);
				}
			});
	}, []);

	//Salvar dados processo
	async function salvarProcesso() {
		const data = {

			rem_id,
			unid_id,

			//Dados Gerais            
			reurb_objeto,
			reurb_instrumento,

			reurb_contrato,
			reurb_procuracao,
			reurb_requerimento,
			reurb_distrato,
			reurb_responsabilidade,

			//Unidade
			unid_areacomprovante,
			unid_areamedida,
			unid_aquisicao,

			unid_compra,
			unid_doacao,
			unid_energia,
			unid_agua,
			unid_iptu,
			unid_decposse,
			unid_outrodocposse,
			unid_anuencia,

			unid_possereq,
			unid_posseconj,
			unid_possefalec,
			unid_posseoutro,

			unid_quitado,
			unid_inventario,
			unid_areapublica,

			unid_historico,

			//Dados de Localização
			unid_logradouro,
			unid_numero,
			unid_complemento,
			unid_bairro,
			unid_localidade,
			unid_municipio,
			unid_uf,
			unid_cep

		}
		api.post('/processo/SalvarProcesso.php', JSON.stringify(data))
			.then(function (rp) {
				return (toast({
					position: 'bottom-left', render: () => (<Box color='white' p={3} bg={rp.data.sucesso === true ? 'teal' : 'red'}>{rp.data.msg}</Box>),
				})
				);
			})
			.catch(function (error) {
				if (error.response.data === "EXPIRED") {
					logout();
				} else {
					console.log(error);
				}
			});
	}

	//Sair da edição
	function sairEditarProcesso() {
		setEditar_processo(0);
	}

	//Pegar endereço
	function pegarEndereco() {
		const data = {
			rem_id,
			unid_id
		}
		api.post('/processo/PegarEndereco.php', JSON.stringify(data))
			.then(function (rp) {
				setUnid_logradouro(rp.data.unid_logradouro);
				setUnid_numero(rp.data.unid_numero);
				setUnid_complemento(rp.data.unid_complemento);
				setUnid_bairro(rp.data.unid_bairro);
				setUnid_localidade(rp.data.unid_localidade);
				setUnid_municipio(rp.data.unid_municipio);
				setUnid_uf(rp.data.unid_uf);
				setUnid_cep(rp.data.unid_cep);
			})
			.catch(function (error) {
				if (error.response.data === "EXPIRED") {
					logout();
				} else {
					console.log(error);
				}
			});
	}

	return (
		<div id="editar_processo">
			<Container maxW='95%' bg='white'>

				<Flex direction='row' w={'100%'} h={'60px'} bg={'gray.200'}>
					<Center w={'100px'} h={'100%'} color={'gray.600'} cursor='pointer' _hover={{ color: 'teal' }} onClick={e => sairEditarProcesso()}>
						<ChevronLeftIcon boxSize={12} />
					</Center>
					<Center>
						<Heading size={'md'} color={'gray.700'} ml={3} noOfLines={1}>Processo {unid_cod} - {reurb_modalidade} - {nui_cod}</Heading>
					</Center>
					<Spacer />
					<Center>
						<ButtonGroup gap='4' mr={4}>
						</ButtonGroup>
					</Center>
				</Flex>

				<Heading as='h4' size='md' textAlign={'left'} mt={8}>
					Dados Gerais
				</Heading>
				<Divider borderColor={'gray.400'} orientation='horizontal' mt={4} mb={4} />
				<Grid
					h='100px'
					templateRows='repeat(2, 1fr)'
					templateColumns='repeat(12, 1fr)'
					gap={4}
				>
					<GridItem colSpan={5}>
						<Stack spacing={3} direction='row'><Text whiteSpace='nowrap'>Objeto da Regularização</Text>
							<Select borderColor={'gray'} size='sm' value={reurb_objeto} onChange={e => setReurb_objeto(e.target.value)}>
								<option></option>
								<option>Terreno</option>
								<option>{"Condomínio Simples (Divisão de Edificações)"}</option>
								<option>Condomínio de Lotes</option>
								<option>Condomínio Edilício</option>
								<option>{"Conjunto Habitacional (Terreno e Edificação)"}</option>
								<option>{"Laje (Infrapartição)"}</option>
								<option>{"Laje (Construção Base)"}</option>
								<option>{"Laje (Sobrelevação)"}</option>
							</Select>
						</Stack>
					</GridItem>
					<GridItem colSpan={1}></GridItem>
					<GridItem colSpan={6}>
						<Stack spacing={3} direction='row'><Text whiteSpace='nowrap'>Instrumento da Regularização</Text>
							<Select borderColor={'gray'} size='sm' value={reurb_instrumento} onChange={e => setReurb_instrumento(e.target.value)}>
								<option></option>
								<option>Legitimação Fundiária</option>
								<option>Legitimação de Posse</option>
								<option>Usucapião</option>
								<option>Desapropriação em Favor dos Possuidores</option>
								<option>Arrecadação de Bem Vago</option>
								<option>Consórcio Imobiliário</option>
								<option>Desapropriação por Interesse Social</option>
								<option>Direito de Preempção</option>
								<option>Transferência do Direito de Construir</option>
								<option>Requisição por Perigo Iminente</option>
								<option>Intervenção do Poder Público</option>
								<option>Alienação </option>
								<option>Concessão de Uso Especial</option>
								<option>Concessão de Direito Real de Uso</option>
								<option>Doação</option>
								<option>Compra e Venda</option>
								<option>Especialização</option>
							</Select>
						</Stack>
					</GridItem>
					<GridItem colSpan={12}>
						<Text>Documentos do Processo</Text>
						<Stack spacing={5} direction='row'>
							<Checkbox borderColor={'gray'} size='md' colorScheme='teal' value={reurb_contrato} onChange={e => setReurb_contrato(e.target.checked ? 1 : 0)} isChecked={reurb_contrato === 1}>Contrato REURB</Checkbox>
							<Checkbox borderColor={'gray'} size='md' colorScheme='teal' value={reurb_procuracao} onChange={e => setReurb_procuracao(e.target.checked ? 1 : 0)} isChecked={reurb_procuracao === 1}>Procuração REURB</Checkbox>
							<Checkbox borderColor={'gray'} size='md' colorScheme='teal' value={reurb_requerimento} onChange={e => setReurb_requerimento(e.target.checked ? 1 : 0)} isChecked={reurb_requerimento === 1}>Requerimento REURB</Checkbox>
							<Checkbox borderColor={'gray'} size='md' colorScheme='teal' value={reurb_responsabilidade} onChange={e => setReurb_responsabilidade(e.target.checked ? 1 : 0)} isChecked={reurb_responsabilidade === 1}>Termo de Responsabilidade</Checkbox>
							<Checkbox borderColor={'gray'} size='md' colorScheme='teal' value={reurb_distrato} onChange={e => setReurb_distrato(e.target.checked ? 1 : 0)} isChecked={reurb_distrato === 1}>Termo de Distrato e Sigilo</Checkbox>
						</Stack>
					</GridItem>
				</Grid>

				<Heading as='h4' size='md' textAlign={'left'} mt={6}>
					Dados de Posse
				</Heading>
				<Divider borderColor={'gray.400'} orientation='horizontal' mt={4} mb={4} />
				<Grid
					h='50px'
					templateRows='repeat(1, 1fr)'
					templateColumns='repeat(12, 1fr)'
					gap={4}
				>
					<GridItem colSpan={4}>
						<Stack spacing={3} direction='row'><Text whiteSpace='nowrap'>Área da Posse (m²)</Text><Input autoComplete="no" borderColor={'gray'} size='sm' maxW={'300px'} value={unid_areacomprovante} onChange={e => setUnid_areacomprovante(e.target.value)} /></Stack>
					</GridItem>
					<GridItem colSpan={4}>
						<Stack spacing={3} direction='row'><Text whiteSpace='nowrap'>Área Medida (m²)</Text><Input autoComplete="no" borderColor={'gray'} size='sm' maxW={'300px'} value={unid_areamedida} onChange={e => setUnid_areamedida(e.target.value)} /></Stack>
					</GridItem>
					<GridItem colSpan={4}>
						<Stack spacing={3} direction='row'><Text whiteSpace='nowrap'>Data de Aquisição</Text><Input autoComplete="no" borderColor={'gray'} type='date' size='sm' maxW={'300px'} value={unid_aquisicao} onChange={e => setUnid_aquisicao(e.target.value)} /></Stack>
					</GridItem>
				</Grid>

				<Text>Comprovante de Posse</Text>
				<Stack spacing={12} direction='row' mb={5}>
					<Stack spacing={2} direction='column'>
						<Checkbox borderColor={'gray'} size='md' colorScheme='teal' value={unid_compra} onChange={e => setUnid_compra(e.target.checked ? 1 : 0)} isChecked={unid_compra === 1}>Contrato de Compra e Venda</Checkbox>
						<Checkbox borderColor={'gray'} size='md' colorScheme='teal' value={unid_doacao} onChange={e => setUnid_doacao(e.target.checked ? 1 : 0)} isChecked={unid_doacao === 1}>Contrato de Doação</Checkbox>
					</Stack>
					<Stack spacing={2} direction='column'>
						<Checkbox borderColor={'gray'} size='md' colorScheme='teal' value={unid_agua} onChange={e => setUnid_agua(e.target.checked ? 1 : 0)} isChecked={unid_agua === 1}>Conta de Água</Checkbox>
						<Checkbox borderColor={'gray'} size='md' colorScheme='teal' value={unid_energia} onChange={e => setUnid_energia(e.target.checked ? 1 : 0)} isChecked={unid_energia === 1}>Conta de Energia</Checkbox>
					</Stack>
					<Stack spacing={2} direction='column'>
						<Checkbox borderColor={'gray'} size='md' colorScheme='teal' value={unid_iptu} onChange={e => setUnid_iptu(e.target.checked ? 1 : 0)} isChecked={unid_iptu === 1}>Conta de IPTU</Checkbox>
						<Checkbox borderColor={'gray'} size='md' colorScheme='teal' value={unid_decposse} onChange={e => setUnid_decposse(e.target.checked ? 1 : 0)} isChecked={unid_decposse === 1}>Declaração de Posse</Checkbox>
					</Stack>
					<Stack spacing={2} direction='column'>
						<Checkbox borderColor={'gray'} size='md' colorScheme='teal' value={unid_outrodocposse} onChange={e => setUnid_outrodocposse(e.target.checked ? 1 : 0)} isChecked={unid_outrodocposse === 1}>Outro Comprovante de Posse</Checkbox>
						<Checkbox borderColor={'gray'} size='md' colorScheme='teal' value={unid_anuencia} onChange={e => setUnid_anuencia(e.target.checked ? 1 : 0)} isChecked={unid_anuencia === 1}>Anuência</Checkbox>
					</Stack>
				</Stack>

				<Grid
					h='70px'
					templateRows='repeat(1, 1fr)'
					templateColumns='repeat(12, 1fr)'
					gap={4}
				>
					<GridItem colSpan={6}>
						<Text>Titular do Comprovante de Posse</Text>
						<Stack spacing={5} direction='row'>
							<Checkbox borderColor={'gray'} size='md' colorScheme='teal' value={unid_possereq} onChange={e => setUnid_possereq(e.target.checked ? 1 : 0)} isChecked={unid_possereq === 1}>Requerente</Checkbox>
							<Checkbox borderColor={'gray'} size='md' colorScheme='teal' value={unid_posseconj} onChange={e => setUnid_posseconj(e.target.checked ? 1 : 0)} isChecked={unid_posseconj === 1}>Cônjuge</Checkbox>
							<Checkbox borderColor={'gray'} size='md' colorScheme='teal' value={unid_possefalec} onChange={e => setUnid_possefalec(e.target.checked ? 1 : 0)} isChecked={unid_possefalec === 1}>Falecido(a)</Checkbox>
							<Checkbox borderColor={'gray'} size='md' colorScheme='teal' value={unid_posseoutro} onChange={e => setUnid_posseoutro(e.target.checked ? 1 : 0)} isChecked={unid_posseoutro === 1}>Outro</Checkbox>
						</Stack>
					</GridItem>
					<GridItem colSpan={6}>
						<Text>Situação Especial do Imóvel</Text>
						<Stack spacing={5} direction='row'>
							<Checkbox borderColor={'gray'} size='md' colorScheme='teal' value={unid_quitado} onChange={e => setUnid_quitado(e.target.checked ? 1 : 0)} isChecked={unid_quitado === 1}>Não Quitado</Checkbox>
							<Checkbox borderColor={'gray'} size='md' colorScheme='teal' value={unid_inventario} onChange={e => setUnid_inventario(e.target.checked ? 1 : 0)} isChecked={unid_inventario === 1}>Em Inventário</Checkbox>
							<Checkbox borderColor={'gray'} size='md' colorScheme='teal' value={unid_areapublica} onChange={e => setUnid_areapublica(e.target.checked ? 1 : 0)} isChecked={unid_areapublica === 1}>Em Área Pública</Checkbox>
						</Stack>
					</GridItem>
				</Grid>

				<Stack spacing={1} direction='column'>
					<Text>Histórico da Posse</Text>
					<Textarea autoComplete="no" borderColor={'gray'} minH='100px' placeholder='Descrever a história do imóvel e dos posseiros...' value={unid_historico} onChange={e => setUnid_historico(e.target.value)} />
				</Stack>

				<Heading as='h4' size='md' textAlign={'left'} mt={6}>
					Dados de Localização
				</Heading>
				<Divider borderColor={'gray.400'} orientation='horizontal' mt={4} mb={4} />
				<Grid
					h='120px'
					templateRows='repeat(5, 1fr)'
					templateColumns='repeat(12, 1fr)'
					gap={4}
				>
					<GridItem colSpan={5}>
						<Stack spacing={3} direction='row'><Text>Logradouro</Text><Input autoComplete="no" borderColor={'gray'} maxLength={250} placeholder='Rua, Avenida, Beco, Rodovia...' size='sm' value={unid_logradouro} onChange={e => setUnid_logradouro(e.target.value)} /></Stack>
					</GridItem>
					<GridItem colSpan={1}><Button colorScheme='black' variant='outline' size='sm' onClick={e => pegarEndereco()}>Pegar Endereço</Button></GridItem>
					<GridItem colSpan={2}>
						<Stack spacing={3} direction='row'><Text>Número</Text><Input autoComplete="no" borderColor={'gray'} maxLength={11} size='sm' value={unid_numero} onChange={e => setUnid_numero(e.target.value)} /></Stack>
					</GridItem>
					<GridItem colSpan={4}>
						<Stack spacing={3} direction='row'><Text>Complemento</Text><Input autoComplete="no" borderColor={'gray'} maxLength={250} placeholder='Casa, Apartamento...' size='sm' value={unid_complemento} onChange={e => setUnid_complemento(e.target.value)} /></Stack>
					</GridItem>

					<GridItem colSpan={6}>
						<Stack spacing={3} direction='row'><Text>Bairro</Text><Input autoComplete="no" borderColor={'gray'} maxLength={250} size='sm' value={unid_bairro} onChange={e => setUnid_bairro(e.target.value)} /></Stack>
					</GridItem>
					<GridItem colSpan={6}>
						<Stack spacing={3} direction='row'><Text>Localidade</Text><Input autoComplete="no" borderColor={'gray'} maxLength={250} size='sm' value={unid_localidade} onChange={e => setUnid_localidade(e.target.value)} /></Stack>
					</GridItem>

					<GridItem colSpan={7}>
						<Stack spacing={3} direction='row'><Text>Município</Text><Input autoComplete="no" borderColor={'gray'} maxLength={250} size='sm' value={unid_municipio} onChange={e => setUnid_municipio(e.target.value)} /></Stack>
					</GridItem>
					<GridItem colSpan={2}>
						<Stack spacing={3} direction='row'><Text>UF</Text><Input autoComplete="no" borderColor={'gray'} maxLength={2} size='sm' value={unid_uf} onChange={e => setUnid_uf(e.target.value)} /></Stack>
					</GridItem>
					<GridItem colSpan={3}>
						<Stack spacing={3} direction='row'><Text>CEP</Text><Input autoComplete="no" as={ReactInputMask} mask="99.999-999" maskChar={null} borderColor={'gray'} size='sm' value={unid_cep} onChange={e => setUnid_cep(e.target.value)} /></Stack>
					</GridItem>
				</Grid>

				<Flex direction='row' p={5} mt={4} mb={4}>
					<Spacer />
					<Button colorScheme="teal" variant={'outline'} onClick={e => salvarProcesso()}>Salvar Processo <SaveIcon ml={2} /></Button>
				</Flex>

			</Container>
		</div>
	);
}

export default EditarProcesso;