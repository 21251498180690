import React from "react";
import { useContext, useState, useEffect } from "react";
import { Button, Box, TableContainer, Table, Thead, Tr, Th, Td, Tbody, Divider, Heading, AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogBody, AlertDialogFooter, useDisclosure, Text, Flex, Center, Spacer, Container, Select } from '@chakra-ui/react';
import { ChevronLeftIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";

import { api } from "../../../services/api";

import NavSuperior from "../../components/NavBar/navsuperior";

import { AuthContext } from "../../contexts/auth";
import { ReurbContext } from "../../contexts/reurb";

const PesquisarPage = () => {
  const navigate = useNavigate();
  const { logout } = useContext(AuthContext);
  const { setRem_id, setUnid_id, setRem_municipio, setRem_remessa, pesquisar, setPesquisar, botao_pesquisar } = useContext(ReurbContext);


  //Variáveis Chakra UI
  const { isOpen: isPesquisarOpen, onOpen: onPesquisarOpen, onClose: onPesquisarClose } = useDisclosure();
  const cancelPesquisarRef = React.useRef();

  //Variáveis    
  const [lista_requerentes, setLista_requerentes] = useState([{ rem_id: 0, rem_municipio: '-', rem_remessa: '-', rem_uf: '-', unid_id: 0, unid_cod: '-', req_nome: '-', conj_nome: '-', nucleo: '-', reurb_situacao: '-' }]);
  const [lista_municipios, setLista_municipios] = useState([]);
  const [resultados_encontrados, setResultados_encontrados] = useState(0);
  const [texto_pesquisado, setTexto_pesquisado] = useState(pesquisar);

  //Filtros
  const [municipio_selecionado, setMunicipio_selecionado] = useState('');

  //Ler dados
  useEffect(() => {
    setPesquisar(pesquisar.trim());
    setTexto_pesquisado(pesquisar.trim());
    if (pesquisar.length > 2) {
      const data = {
        pesquisar,
        municipio_selecionado
      };
      api.post('/pesquisar/LerPesquisar.php', JSON.stringify(data))
        .then(function (rp) {
          setLista_requerentes(rp.data.lista_requerentes);
          setLista_municipios(rp.data.lista_municipios);
          setResultados_encontrados(rp.data.resultados_encontrados);
        })
        .catch(function (error) {
          if (error.response.data === "EXPIRED") {
            logout();
          } else {
            console.log(error);
          }
        });
    } else {
      onPesquisarOpen();
    }
  }, [botao_pesquisar, municipio_selecionado]);

  //Ir para processo
  function goToProcesso(id_remessa, id_processo, municipio, remessa) {
    setRem_id(id_remessa);
    setUnid_id(id_processo);
    setRem_municipio(municipio);
    setRem_remessa(remessa);
    if (id_remessa !== 0 && id_processo !== 0) {
      navigate('/processo');
    }
  }

  //Ir para remessa
  function goToRemessa() {
    navigate('/remessa');
  }

  return (
    <div id="pesquisar">
      <NavSuperior />
      <Divider borderColor={'gray.400'} orientation='horizontal' mt={2} mb={2} />
      <Heading as='h1' size='lg' color={'gray.600'} textAlign={'center'}>
        RESULTADO DA PESQUISA
      </Heading>
      <Divider borderColor={'gray.400'} orientation='horizontal' mt={2} mb={2} />
      <Container maxW='95%' bg='white'>
        <Flex direction='row' w={'100%'} h={'60px'} bg={'gray.200'}>
          <Center w={'100px'} h={'100%'} color={'gray.600'} cursor='pointer' _hover={{ color: 'teal' }} onClick={e => goToRemessa()}>
            <ChevronLeftIcon boxSize={12} />
          </Center>
          <Center>
            <Heading size={'md'} color={'gray.700'} ml={3}>Resultados Encontrados ({resultados_encontrados})</Heading>
          </Center>
          <Spacer />
          <Center>
            <Text mr={3}>Município</Text>
            <Select mr={4} borderColor={'teal'} size='sm' w='300px' value={municipio_selecionado} onChange={e => { setMunicipio_selecionado(e.target.value) }}>
              <option key={0} value={''}>{'-'}</option>
              {lista_municipios.map((item, i) => {
                return (<option key={i + 1} value={item}>{item}</option>);
              })}
            </Select>
          </Center>
        </Flex>
        <Box
          display={resultados_encontrados === 0 ? 'block' : 'none'}>
          <Center mt={20}>
            <Heading w={'50%'} size={'lg'} textAlign={'center'} color={'gray.500'}>
              Ops, nenhum requerente cadastrado com o nome "{texto_pesquisado}" no banco de dados...
            </Heading>
          </Center>
        </Box>
        <Box
          display={resultados_encontrados === 0 ? 'none' : 'block'}
          overflowX="auto"
          whiteSpace="nowrap"
          sx={{
            '&::-webkit-scrollbar': {
              width: '16px',
              borderRadius: '8px',
              backgroundColor: `rgba(0, 0, 0, 0.05)`,
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: `rgba(0, 0, 0, 0.05)`,
            },
          }}
          w='100%'
          height={(window.innerHeight - 260)}>
          <TableContainer m={5}>
            <Table size='sm'>
              <Thead>
                <Tr>
                  <Th textAlign={'center'}>PROCESSO</Th>
                  <Th textAlign={'center'}>REQUERENTE</Th>
                  <Th textAlign={'center'}>CÔNJUGE</Th>
                  <Th textAlign={'center'}>REMESSA</Th>
                  <Th textAlign={'center'}>UF</Th>
                  <Th textAlign={'center'}>NÚCLEO</Th>
                  <Th textAlign={'center'}>SITUAÇÃO</Th>
                </Tr>
              </Thead>
              <Tbody>
                {lista_requerentes.map((item, i) => {
                  return (
                    <Tr key={i} cursor='pointer' _hover={{ bg: 'gray.200' }} onClick={e => goToProcesso(item.rem_id, item.unid_id, item.rem_municipio, item.rem_remessa)} bg={item.reurb_situacao === 'Cancelado' ? 'red.100' : 'white'}>
                      <Td textAlign={'center'}>{item.unid_cod}</Td>
                      <Td maxW={'250px'} whiteSpace={'pre-wrap'}>{item.req_nome}</Td>
                      <Td maxW={'250px'} whiteSpace={'pre-wrap'}>{item.conj_nome}</Td>
                      <Td textAlign={'center'}>{item.rem_municipio + ' ' + item.rem_remessa}</Td>
                      <Td textAlign={'center'}>{item.rem_uf}</Td>
                      <Td textAlign={'center'} maxW={'150px'}><Text isTruncated>{item.nucleo}</Text></Td>
                      <Td textAlign={'center'}>{item.reurb_situacao}</Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>

      </Container>

      {/*Alert caracteres mínimos pesquisar*/}
      <AlertDialog
        isOpen={isPesquisarOpen}
        leastDestructiveRef={cancelPesquisarRef}
        onClose={onPesquisarClose}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Buscar Requerente
            </AlertDialogHeader>
            <AlertDialogBody>
              Digite três caracteres ou mais para realizar a pesquisa
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelPesquisarRef} onClick={onPesquisarClose}>
                OK
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </div>
  );
}

export default PesquisarPage;